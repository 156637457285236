import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { ListProps } from "@mui/material/List";
const ListSmall = ({...other}:ListProps) => {
	const StyledList = styled(List)(({ theme }) => ({
		"& .MuiListItemButton-root": {
			"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 16,
					right: 16,
					width: "1px",
					height: "100%",
					backgroundColor: grey[200],
				},
			"&:first-of-type":{
				"&::before": {
					content: '""',
					position: "absolute",
					top: "50%",
					left: 16,
					right: 16,
					width: "1px",
					height: "50%",
					backgroundColor: grey[200],
				}
			},
			"&:last-of-type":{
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 16,
					right: 16,
					width: "1px",
					height: "50%",
					backgroundColor: grey[200],
				}
			}
		}
	}));

	return <StyledList {...other}/>
}


export default ListSmall;
