import Grid from "@mui/material/Grid";
import { Suspense, useState } from "react";
import { useFetchProcessMaturities } from "../../../../../hooks/query/process-maturity/useFetchProcessMaturities";
import { ProcessMaturity } from "../../../../../services/types/process-maturity";
import ProcessMaturityDataTable from "../../components/datatable";
import SubHeader from "../../components/subheader";
import EditEvaluation from "./edit";
const EvaluationPage = () => {
    // STATES
    const [openEdit, setOpenEdit] = useState(false);
    const [selected, setSelected] = useState<ProcessMaturity | undefined>(undefined);
    const { data } = useFetchProcessMaturities();

    // FUNCTIONS
    const toggleEdit = (newOpen: boolean) => () => {
        setOpenEdit(newOpen);
    };

    const onEdit = (id: string) => {
        const user = data.find((d) => d.id === id);
        setSelected(user);
        setTimeout(() => {
            setOpenEdit(true);
        }, 100);
    };

    return (
        <Suspense fallback={<>Loading user data...</>}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <SubHeader title="Penilaian" />
                </Grid>
                <Grid item md={12}>
                    <ProcessMaturityDataTable data={data} onEdit={onEdit} />
                </Grid>
            </Grid>
            <EditEvaluation open={openEdit} toggle={toggleEdit} selected={selected} />
        </Suspense>
    );
};

export default EvaluationPage;
