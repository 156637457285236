import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { editUser } from "../../../services/user";
export const useEditUser = () => {
	return useMutation({
		mutationFn: (data: FormData) => {
			const id = data.get("id") as string;
			data.delete("id");
			return editUser(id, data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["users"],
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
