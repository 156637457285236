import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
export const ListLineSmall = styled(Box)(() => ({
	position: "relative",
	width: "16px",
	margin: "0 1rem 0 0",
	"&::before": {
		content: '""',
		position: "absolute",
		top: 0,
		left: 8,
		right: 8,
		width: "1px",
		height: "100%",
		backgroundColor: grey[200],
	},
}));
export const ListLineSmallRepeater = styled(Box)(() => ({
	position: "relative",
	width: "100%",
	margin: "0 1rem 0 0",
	display: "flex",
	"&::before": {
		content: '""',
		position: "absolute",
		top: 0,
		left: 8,
		right: 8,
		width: "1px",
		height: "100%",
		backgroundColor: grey[200],
	},
}));
export const ListLine = styled(Box)(() => ({
	position: "relative",
	width: "32px",
	margin: "0 .5rem",
	"&::before": {
		content: '""',
		position: "absolute",
		top: 0,
		left: 16,
		right: 16,
		width: "1px",
		height: "100%",
		// backgroundColor: "red",
		backgroundColor: grey[200],
	},
	"& .MuiButtonBase-root":{
		position: "absolute",
		width: "28px",
		height: "28px",
		left: 3,
		backgroundColor: grey[200],
		"&:hover": {
			backgroundColor: grey[300],
		},
		"&.top": {
			top: 0,
		},
		"&.bottom": {
			bottom: 0,
		}
	}
}));

export default ListLine;
