import { Suspense } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation, useParams } from "react-router-dom";
import Unauthenticated from "../../../../components/common/Redirect/Unauthenticated";
import Header from "../../../../components/navigations/header/Header";
import QuestionLevelPage from "../../route-element/pm/domain-process/question-level";
import Loading from "./loading";

const QuestionLevel = () => {
    const authUser = useAuthUser();
    const location = useLocation();
    const { domain = "", processId = "", processLevel = "" } = useParams<{ domain: string, processId: string, processLevel: string }>();
    const DOMAIN = domain.toUpperCase();
    const PROCESS_ID = processId.padStart(2, "0");
    if (!authUser) return <Unauthenticated />;

    const Page = () => {
        if (authUser.role === "pm") return <QuestionLevelPage domain={DOMAIN} processId={PROCESS_ID} level={processLevel} />;
        if (authUser.role === "admin") return <QuestionLevelPage domain={DOMAIN} processId={PROCESS_ID} level={processLevel} />;
        else return <>Forbidden</>;
    }

    return <>
        <Header
            title="Kuisioner"
            breadcrumbs={[
                { text: "Kuisioner", path: "#" },
                { text: DOMAIN, path: "#" },
                { text: `${DOMAIN}${PROCESS_ID}`, path: location.pathname.split("/").slice(0, 4).join("/") },
                { text: "Questions", path: "#" },
            ]}
        />
        <Suspense fallback={<Loading />}>
            <Page />
        </Suspense>
    </>;
};

export default QuestionLevel;
