import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams, gridClasses } from "@mui/x-data-grid";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { ActionButton } from "../../../../components/buttons/ActionButton";
import { Paper } from "../../../../components/display/paper/Paper";
import { DataTableProps } from "../props";
import { renderDescription, renderNotation } from "./utils";

let baseColumns = [
	{ field: "rating_scale", headerName: "Rating Scale", width: 150 },
	{ field: "notation", headerName: "Notation", width: 100, renderCell: renderNotation },
	{ field: "ordinal_rating", headerName: "Ordinal Rating", width: 150 },
	{ field: "description", headerName: "Description", flex: 3, renderCell: renderDescription },
];

const DataTable = ({ data, onEdit = () => { } }: DataTableProps) => {
	const authUser = useAuthUser();
	if (!authUser) return null;
	const columns: GridColDef[] = authUser.role === "assessor"
		? baseColumns
		: [
			...baseColumns,
			{
				field: "actions",
				type: "actions",
				width: 150,
				getActions: (params: GridRowParams) => [
					<ActionButton size="small" variant="outlined" startIcon={<EditIcon />} onClick={() => onEdit(params.row.id)}>
						Edit
					</ActionButton>,
				],
			},
		];

	return (
		<Paper>
			<Box width={"100%"} height={600}>
				<DataGrid
					rows={data}
					columns={columns}
					getRowHeight={() => "auto"}
					sx={{
						border: "none",
						[`& .${gridClasses.cell}`]: {
							py: 3,
						},
					}}
					disableRowSelectionOnClick
					initialState={{
						pagination: {
							paginationModel: { pageSize: 40 },
						},
						density: "comfortable",
						columns: {
							columnVisibilityModel: { type: false },
						},
					}}
					disableColumnSelector
				/>
			</Box>
		</Paper>
	);
};

export default DataTable;
