// DEFINITION
export type ResponseDashboard = {
	domain: string;
	process_id: string;
	process: string;
	process_group: string;
	agreed_target: number;
	level_0: number;
	level_1: number;
	level_2: number;
	level_3: number;
	level_4: number;
	level_5: number;
    accumulation: number;
	summary: number;
};

export const isKeyOfResponseDashboard = (key: string, obj: ResponseDashboard | undefined): key is keyof ResponseDashboard => {
	if(!obj) return false
	return key in obj; // Dummy object for type inference
  }

export const getResponseDashboardValue = (obj: ResponseDashboard | undefined, key: keyof ResponseDashboard) => {
	if(!obj) return null
	return obj[key];
}