import axios from "axios";
import { AuthenticateResponse } from "./types/auth";
export const fetchAuth = async (formData: FormData) => {
	try {
		const res = await axios.post<AuthenticateResponse>(`${process.env.REACT_APP_API_URL}/auth/login`, formData, {
			headers: {
				"Content-Type": "application/json",
			},
		});
		return res;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
