import { List } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { FastField, FastFieldProps } from "formik";
import { memo } from "react";
import ListBullet from "../../../../../components/List/Bullet";
import { ListItemQuestion } from "../../../../../components/List/Item";
import { ListLineSmallRepeater } from "../../../../../components/List/Line";
import { CommentsProps } from "../../../../../components/props";
const CommentList = ({ id, response }: CommentsProps) => {
	return (
		<List
			sx={{
				"& .MuiBox-root": {
					"&.comment-line": {
						pb: 2,
						"&:last-of-type": {
							pb: 0,
							"&::before": {
								width: 0,
							},
						},
					},
				},
			}}
		>
			{response[0] ?
				response[0].comments.length > 0 ?
					response[0].comments.map((comment, comment_id) => (
						<ListLineSmallRepeater className="comment-line" key={`comment-${comment.id}-${id}-${comment_id}`}>
							<ListItemQuestion
								alignItems="flex-start"
								sx={{
									"& .MuiListItemAvatar-root": { mt: 0 },
								}}
							>
								<ListBullet size="small" completed={true} text={(id + 1).toString()} />
								<Box width={"100%"}>
									<FastField name={`activities.${id}.response.${0}.comments.${comment_id}.comment`}>
										{({ field, meta }: FastFieldProps<string>) => (
											<>
												<TextField
													{...field}
													size="small"
													label={`Komentar ${comment_id + 1}`}
													multiline
													minRows={3}
													disabled
													variant="filled"
													fullWidth
													sx={{ mr: 1 }}
													error={meta.touched && Boolean(meta.error)}
													helperText={meta.touched && meta.error}
												/>
											</>
										)}
									</FastField>
								</Box>
							</ListItemQuestion>
						</ListLineSmallRepeater>
					)) : <Chip sx={{ mx: 0 }} size="small" label="Belum ada komentar" />
				: <Chip sx={{ mx: 0 }} size="small" label="Penilaian Kosong" color="secondary" />}
		</List>
	);
};

export default memo(CommentList);
