import { RouteObject } from "react-router-dom";
import Dashboard from "../../pages/dashboard/page";
import DashboardError from "./errors/dashboard.error-route";

const DashboardRoute: RouteObject[] = [
	{
		index: true,
		path: "/dashboard",
		errorElement: <DashboardError />,
		element: <Dashboard />,
	},
];

export default DashboardRoute;