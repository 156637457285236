import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { FastField, FieldArray, FieldProps, FormikState } from "formik";
import { DateTime } from "luxon";
import { memo, ReactNode } from "react";
import { CreateClient } from "../../../../../../services/types/client";
import { H4, H5 } from "../../../../../components/display/typography";
import ListBullet from "../../../../questionnaire/components/List/Bullet";
import { ListItemQuestion } from "../../../../questionnaire/components/List/Item";
import { ListLineSmallRepeater } from "../../../../questionnaire/components/List/Line";

const StepComponent = ({ step, context, children, isValid }: { step: number, context: FormikState<CreateClient>, children: ReactNode, isValid: boolean }) => {
    switch (step) {
        case 0:
            return (
                <>
                    <H4>Data Client</H4>
                    <Stack spacing={2}>
                        <FastField name={`client`}>
                            {({ field, meta }: FieldProps<string>) => (
                                <>
                                    <TextField
                                        {...field}
                                        label="Client"
                                        variant="outlined"
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                    />
                                </>
                            )}
                        </FastField>
                        <FastField name={`description`}>
                            {({ field, meta }: FieldProps<string>) => (
                                <>
                                    <TextField
                                        {...field}
                                        label="Description"
                                        variant="outlined"
                                        multiline
                                        minRows={3}
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                    />
                                </>
                            )}
                        </FastField>
                        <FastField name={`project_start_date`}>
                            {({ field, meta, form }: FieldProps<Date>) => (
                                <>
                                    <DatePicker
                                        label="Project Start Date"
                                        value={DateTime.fromJSDate(field.value)}
                                        onChange={(date) => form.setFieldValue("project_start_date", date?.toJSDate())}
                                        slotProps={{
                                            textField: {
                                                error: meta.touched && Boolean(meta.error),
                                                helperText: meta.touched && meta.error,
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </FastField>
                        <FastField name={`project_end_date`}>
                            {({ field, meta, form }: FieldProps<Date>) => (
                                <>
                                    <DatePicker
                                        label="Project End Date"
                                        value={DateTime.fromJSDate(field.value)}
                                        onChange={(date) => form.setFieldValue("project_end_date", date?.toJSDate())}
                                        slotProps={{
                                            textField: {
                                                error: meta.touched && Boolean(meta.error),
                                                helperText: meta.touched && meta.error,
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </FastField>
                    </Stack>
                    {children}
                </>
            )
        case 1:
            return (
                <>
                    <H4>Person In Charge</H4>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.pic-line": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        <FieldArray name="person_in_charges">
                            {({ push, remove }) => (
                                <>
                                    {context.values.person_in_charges.map((_, id) => (
                                        <ListLineSmallRepeater className="pic-line" key={id}>
                                            <ListItemQuestion
                                                alignItems="flex-start"
                                                sx={{
                                                    "& .MuiListItemAvatar-root": { mt: 0 },
                                                }}
                                            >
                                                <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                                <Stack spacing={2} width={"100%"}>
                                                    <FastField name={`person_in_charges.${id}.name`}>
                                                        {({ field, meta }: FieldProps<string>) => (
                                                            <>
                                                                <TextField
                                                                    autoFocus
                                                                    {...field}
                                                                    label="Name"
                                                                    variant="outlined"
                                                                    error={meta.touched && Boolean(meta.error)}
                                                                    helperText={meta.touched && meta.error}
                                                                />
                                                            </>
                                                        )}
                                                    </FastField>
                                                    <FastField name={`person_in_charges.${id}.description`}>
                                                        {({ field, meta }: FieldProps<string>) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    label="Description"
                                                                    variant="outlined"
                                                                    multiline
                                                                    minRows={2}
                                                                    error={meta.touched && Boolean(meta.error)}
                                                                    helperText={meta.touched && meta.error}
                                                                />
                                                            </>
                                                        )}
                                                    </FastField>
                                                    <FastField name={`person_in_charges.${id}.contact`}>
                                                        {({ field, meta }: FieldProps<string>) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    label="Contact"
                                                                    variant="outlined"
                                                                    error={meta.touched && Boolean(meta.error)}
                                                                    helperText={meta.touched && meta.error}
                                                                />
                                                            </>
                                                        )}
                                                    </FastField>
                                                    <Box display={"flex"}>
                                                        {id === context.values.person_in_charges.length - 1 && (
                                                            <Button
                                                                onClick={() => {
                                                                    push({
                                                                        id: crypto.randomUUID(),
                                                                        client_id: context.values.id,
                                                                        name: "",
                                                                        description: "",
                                                                        contact: "",
                                                                    });
                                                                }}
                                                                size="small"

                                                            >
                                                                Tambah
                                                            </Button>
                                                        )}
                                                        <Box flex={1} />
                                                        <Button
                                                            onClick={() => remove(id)}
                                                            size="small"
                                                            color="error"
                                                            disabled={context.values.person_in_charges.length === 1}

                                                        >
                                                            Hapus
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </ListItemQuestion>
                                        </ListLineSmallRepeater>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                    </List>
                    {children}
                </>
            )
        case 2:
            return (
                <>
                    <H4>Process Group</H4>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.process-group-line": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        <FieldArray name="process_groups">
                            {({ push, remove }) => (
                                <>
                                    {context.values.process_groups.map((_, id) => (
                                        <ListLineSmallRepeater className="process-group-line" key={id}>
                                            <ListItemQuestion
                                                alignItems="flex-start"
                                                sx={{
                                                    "& .MuiListItemAvatar-root": { mt: 0 },
                                                }}
                                            >
                                                <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                                <Stack spacing={2} width={"100%"}>
                                                    <FastField name={`process_groups.${id}.group`}>
                                                        {({ field, meta }: FieldProps<string>) => (
                                                            <>
                                                                <TextField
                                                                    autoFocus
                                                                    {...field}
                                                                    label="Group"
                                                                    variant="outlined"
                                                                    error={meta.touched && Boolean(meta.error)}
                                                                    helperText={meta.touched && meta.error}
                                                                />
                                                            </>
                                                        )}
                                                    </FastField>
                                                    <FastField name={`process_groups.${id}.note`}>
                                                        {({ field, meta }: FieldProps<string>) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    label="Note"
                                                                    variant="outlined"
                                                                    multiline
                                                                    minRows={2}
                                                                    error={meta.touched && Boolean(meta.error)}
                                                                    helperText={meta.touched && meta.error}
                                                                />
                                                            </>
                                                        )}
                                                    </FastField>
                                                    <Box display={"flex"}>
                                                        {id === context.values.process_groups.length - 1 && (
                                                            <Button
                                                                onClick={() => {
                                                                    push({
                                                                        id: crypto.randomUUID(),
                                                                        client_id: context.values.id,
                                                                        order: id + 1,
                                                                        group: "",
                                                                        note: "",
                                                                    },);
                                                                }}
                                                                size="small"

                                                            >
                                                                Tambah
                                                            </Button>
                                                        )}
                                                        <Box flex={1} />
                                                        <Button
                                                            onClick={() => remove(id)}
                                                            size="small"
                                                            color="error"
                                                            disabled={context.values.process_groups.length === 1}

                                                        >
                                                            Hapus
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </ListItemQuestion>
                                        </ListLineSmallRepeater>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                    </List>
                    {children}
                </>
            )
        case 3:
            return (
                <>
                    <H4>Agreed Capability (Target)</H4>
                    <H5>Evaluate, Direct and Monitor (EDM)</H5>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.agreed-capability-edm": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        {context.values.agreed_capabilities.filter((a) => a.domain === "edm").map((_, id) => (
                            <ListLineSmallRepeater className="agreed-capability-edm" key={id}>
                                <ListItemQuestion
                                    alignItems="flex-start"
                                    sx={{
                                        "& .MuiListItemAvatar-root": { mt: 0 },
                                    }}
                                >
                                    <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                    <Box display={"flex"} gap={2} width={"100%"}>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id}.previous_capability`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Previous Capability (Nullable)"
                                                            value={field.value ? field.value : null}
                                                            key={`previous_capability_edm${id}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id}.target`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Target"
                                                            value={field.value ? field.value : null}
                                                            key={`target_edm${id}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={2}>
                                            <FastField name={`agreed_capabilities.${id}.process_group_id`}>
                                                {({ field, meta }: FieldProps<string>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Process Group"
                                                            variant="outlined"
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        >
                                                            {context.values.process_groups.map((pg, id) => <MenuItem value={pg.id} key={id}>{pg.group}</MenuItem>)}
                                                        </TextField>
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                    </Box>
                                </ListItemQuestion>
                            </ListLineSmallRepeater>
                        ))}
                    </List>
                    {children}
                </>
            )
        case 4:
            return (
                <>
                    <H4>Agreed Capability (Target)</H4>
                    <H5>APO (Align, Plan and Organise)</H5>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.agreed-capability-apo": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        {context.values.agreed_capabilities.filter((a) => a.domain === "apo").map((_, id) => (
                            <ListLineSmallRepeater className="agreed-capability-apo" key={id}>
                                <ListItemQuestion
                                    alignItems="flex-start"
                                    sx={{
                                        "& .MuiListItemAvatar-root": { mt: 0 },
                                    }}
                                >
                                    <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                    <Box display={"flex"} gap={2} width={"100%"}>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 5}.previous_capability`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Previous Capability (Nullable)"
                                                            value={field.value ? field.value : null}
                                                            key={`previous_capability_apo${id + 5}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 5}.target`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Target"
                                                            value={field.value ? field.value : null}
                                                            key={`target_apo${id + 5}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={2}>
                                            <FastField name={`agreed_capabilities.${id + 5}.process_group_id`}>
                                                {({ field, meta }: FieldProps<string>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Process Group"
                                                            variant="outlined"
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        >
                                                            {context.values.process_groups.map((pg, id) => <MenuItem value={pg.id} key={id}>{pg.group}</MenuItem>)}
                                                        </TextField>
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                    </Box>
                                </ListItemQuestion>
                            </ListLineSmallRepeater>
                        ))}
                    </List>
                    {children}
                </>
            )
        case 5:
            return (
                <>
                    <H4>Agreed Capability (Target)</H4>
                    <H5>BAI (Build, Acquire and Implement)</H5>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.agreed-capability-bai": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        {context.values.agreed_capabilities.filter((a) => a.domain === "bai").map((_, id) => (
                            <ListLineSmallRepeater className="agreed-capability-bai" key={id}>
                                <ListItemQuestion
                                    alignItems="flex-start"
                                    sx={{
                                        "& .MuiListItemAvatar-root": { mt: 0 },
                                    }}
                                >
                                    <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                    <Box display={"flex"} gap={2} width={"100%"}>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 19}.previous_capability`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Previous Capability (Nullable)"
                                                            value={field.value ? field.value : null}
                                                            key={`previous_capability_bai${id + 19}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 19}.target`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Target"
                                                            value={field.value ? field.value : null}
                                                            key={`target_bai${id + 19}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={2}>
                                            <FastField name={`agreed_capabilities.${id + 19}.process_group_id`}>
                                                {({ field, meta }: FieldProps<string>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Process Group"
                                                            variant="outlined"
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        >
                                                            {context.values.process_groups.map((pg, id) => <MenuItem value={pg.id} key={id}>{pg.group}</MenuItem>)}
                                                        </TextField>
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                    </Box>
                                </ListItemQuestion>
                            </ListLineSmallRepeater>
                        ))}
                    </List>
                    {children}
                </>
            )
        case 6:
            return (
                <>
                    <H4>Agreed Capability (Target)</H4>
                    <H5>DSS (Deliver, Service and Support)</H5>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.agreed-capability-dss": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        {context.values.agreed_capabilities.filter((a) => a.domain === "dss").map((_, id) => (
                            <ListLineSmallRepeater className="agreed-capability-dss" key={id}>
                                <ListItemQuestion
                                    alignItems="flex-start"
                                    sx={{
                                        "& .MuiListItemAvatar-root": { mt: 0 },
                                    }}
                                >
                                    <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                    <Box display={"flex"} gap={2} width={"100%"}>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 30}.previous_capability`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Previous Capability (Nullable)"
                                                            value={field.value ? field.value : null}
                                                            key={`previous_capability_dss${id + 30}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 30}.target`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Target"
                                                            value={field.value ? field.value : null}
                                                            key={`target_dss${id + 30}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={2}>
                                            <FastField name={`agreed_capabilities.${id + 30}.process_group_id`}>
                                                {({ field, meta }: FieldProps<string>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Process Group"
                                                            variant="outlined"
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        >
                                                            {context.values.process_groups.map((pg, id) => <MenuItem value={pg.id} key={id}>{pg.group}</MenuItem>)}
                                                        </TextField>
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                    </Box>
                                </ListItemQuestion>
                            </ListLineSmallRepeater>
                        ))}
                    </List>
                    {children}
                </>
            )
        case 7:
            return (
                <>
                    <H4>Agreed Capability (Target)</H4>
                    <H5>MEA (Monitor, Evaluate and Assess)</H5>
                    <List
                        sx={{
                            "& .MuiBox-root": {
                                "&.agreed-capability-mea": {
                                    pb: 2,
                                    "&:last-of-type": {
                                        pb: 0,
                                        "&::before": {
                                            width: 0,
                                        },
                                    },
                                },
                            },
                        }}
                    >
                        {context.values.agreed_capabilities.filter((a) => a.domain === "mea").map((_, id) => (
                            <ListLineSmallRepeater className="agreed-capability-mea" key={id}>
                                <ListItemQuestion
                                    alignItems="flex-start"
                                    sx={{
                                        "& .MuiListItemAvatar-root": { mt: 0 },
                                    }}
                                >
                                    <ListBullet size="small" completed={true} text={(id + 1).toString()} />
                                    <Box display={"flex"} gap={2} width={"100%"}>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 36}.previous_capability`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Previous Capability (Nullable)"
                                                            value={field.value ? field.value : null}
                                                            key={`previous_capability_mea${id + 36}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={1}>
                                            <FastField name={`agreed_capabilities.${id + 36}.target`}>
                                                {({ field, meta }: FieldProps<number>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            label="Target"
                                                            value={field.value ? field.value : null}
                                                            key={`target_mea${id + 36}`}
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        />
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                        <Box flex={2}>
                                            <FastField name={`agreed_capabilities.${id + 36}.process_group_id`}>
                                                {({ field, meta }: FieldProps<string>) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Process Group"
                                                            variant="outlined"
                                                            fullWidth
                                                            error={meta.touched && Boolean(meta.error)}
                                                            helperText={meta.touched && meta.error}
                                                        >
                                                            {context.values.process_groups.map((pg, id) => <MenuItem value={pg.id} key={id}>{pg.group}</MenuItem>)}
                                                        </TextField>
                                                    </>
                                                )}
                                            </FastField>
                                        </Box>
                                    </Box>
                                </ListItemQuestion>
                            </ListLineSmallRepeater>
                        ))}
                    </List>
                    {children}
                </>
            )
        case 8:
            return (
                <>
                    {isValid ? <Alert severity="info">Pastikan data yang anda masukkan sudah benar</Alert> : <Alert severity="error">Terdapat data kosong, pastikan data yang anda masukkan sudah benar</Alert>}
                    {children}
                </>
            )
        default:
            return (
                <>
                    <p>Buat Client selesai, klik kirim untuk melanjutkan</p>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button disabled={!isValid} variant="contained" type='submit'>
                            Kirim
                        </Button>
                    </Box>
                </>
            )
    }
};

export default memo(StepComponent);