import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import PaperMUI, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FC, ReactElement } from "react";
import { grey } from "@mui/material/colors";

type CardProps = PaperProps & {
	type: "summary" | "data";
	text: string;
	value: number;
	targetValue?: number;
};

type ColorConfig = {
	color: string;
	bg: string;
	fontSize: string;
};

const summaryColor: ColorConfig = {
	color: "#1876D2",
	bg: "#E9F4FF",
	fontSize: "3.7rem",
};

const dataColor: ColorConfig = {
	color: "#8784D2",
	bg: "#F0EFFB",
	fontSize: "3rem",
};

interface StyledIconProps {
	icon: OverridableComponent<any>;
	style: ColorConfig;
}

const StyledIcon: FC<StyledIconProps> = ({ icon: IconComponent, style }): ReactElement => {
	return <IconComponent sx={{ ...style }} />;
};

const Card = ({ type, text, value, targetValue, ...other }: CardProps) => {
	const config = type === "summary" ? summaryColor : dataColor;
	const Paper = styled(PaperMUI)(() => ({
		padding: "1.875rem 1.25rem",
		borderRadius: "0.75rem",
		backgroundColor: config.bg,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	}));

	return (
		<Paper {...other}>
			<Box>
				<StyledIcon icon={type === "summary" ? FlagOutlinedIcon : LibraryBooksIcon} style={config} />
			</Box>
			<Box textAlign={"right"}>
				<Typography component={"small"} fontSize={"0.875rem"}>
					{text}
				</Typography>
				<Typography component={"h4"} fontSize={"1.375rem"} fontWeight={600} color={config.color}>
					{value.toFixed(2)}
				</Typography>
				{targetValue ? (
					<Chip label={targetValue !== -1 ? targetValue.toFixed(2):"Data tidak cukup"} size="small" sx={{ bgcolor: `${targetValue !== -1 ?"#2476D2":grey[500]}`, color: "#fff" }} />
				) : (
					<Typography component={"small"} color={config.bg}>
						-
					</Typography>
				)}
			</Box>
		</Paper>
	);
};

export default Card;
