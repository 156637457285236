import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
const ProjectLoading = () => {
  return(
    <Box display="flex" alignItems="center">
        <Box mr={2} display={"flex"} alignItems={"center"}>
            <Skeleton variant="text" sx={{ marginRight: "0.25rem", fontSize: "0.875rem", width: "11ch" }} />
            <Skeleton variant="text" sx={{ marginRight: "0.25rem", fontSize: "0.875rem", width: "13ch" }} />
        </Box>
        <Skeleton variant="rounded" sx={{ height: 40, width: 145 }} />
    </Box>
  )
}

export default ProjectLoading