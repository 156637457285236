import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactNode, SyntheticEvent, useContext, useMemo, useState } from "react";
import { ProjectContext } from "../../../../context/ProjectContext";
import { useFetchResponseDashboard } from "../../../../hooks/query/responses/useFetchResponseDashboard";
import { a11yProps } from "../../../../lib/utils/helper";
import ExportButton from "../../../components/common/Button/ExportButton";
import { Paper } from "../../../components/display/paper/Paper";
import { H2 } from "../../../components/display/typography";
import TabPanel from "../../../components/navigations/tabs/TabPanel";
import { Tab, Tabs } from "../../../components/navigations/tabs/Tabs";
import DomainTab from "../components/tabs/DomainTab";
import SummaryTab from "../components/tabs/SummaryTab";
const DashboardPage = () => {
    // CONTEXT
    const { state: { selectedProject } } = useContext(ProjectContext);
    // STATES
    const { data } = useFetchResponseDashboard(selectedProject);
    const [selectedTab, setSelectedTab] = useState(0);
    const [filterData, setFilterData] = useState("all");

    // get unique data.process_group
    const processGroups = useMemo((): string[] => {
        function onlyUnique(value: string, index: number, array: string[]) {
            return array.indexOf(value) === index;
        }
        if (!data) return [];
        const filter = data.map((d) => d.process_group);
        return filter.filter(onlyUnique);
    }, [data]);

    // FUNCTIONS
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    // VARIABLES
    const tabLabel = "dashboard-project-manager-tabpanel";
    const tabAriaLabel = "dashboard-project-manager-tab";
    const tabComponents: ReactNode[] = useMemo(
        () => [
            <SummaryTab data={data} filter={filterData} />,
            <DomainTab data={data} filter={filterData} domain="EDM" />,
            <DomainTab data={data} filter={filterData} domain="APO" />,
            <DomainTab data={data} filter={filterData} domain="BAI" />,
            <DomainTab data={data} filter={filterData} domain="DSS" />,
            <DomainTab data={data} filter={filterData} domain="MEA" />,
        ],
        [data, filterData]
    );

    // LOCAL COMPONENTS
    const SubHeader = () => {
        const tabNames = ["Summary", "EDM", "APO", "BAI", "DSS", "MEA"];
        return (
            <Paper sx={{ py: "1rem" }}>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <H2 style={{ margin: 0 }}>Assessment</H2>
                    </Grid>
                    <Grid item md>
                        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="dashboard tabs">
                            {tabNames.map((tabName, id) => (
                                <Tab key={id} label={tabName} {...a11yProps(id, `${tabLabel}-${id}`, `${tabAriaLabel}-${id}`)} />
                            ))}
                        </Tabs>
                    </Grid>
                    {data && data.length > 0 && (
                        <Grid item display={"flex"} alignItems={"center"} gap={2}>
                            <FormControl size="small">
                                <InputLabel id="filter-data-label">Berdasarkan</InputLabel>
                                <Select
                                    labelId="filter-data-label"
                                    id="filter-select"
                                    label="Berdasarkan"
                                    value={filterData}
                                    size="small"
                                    onChange={(e: SelectChangeEvent<string>) => setFilterData(e.target.value)}
                                >
                                    <MenuItem value={"all"}>Semua</MenuItem>
                                    {processGroups.map((processGroup, id) => (
                                        <MenuItem key={id} value={processGroup}>
                                            {processGroup}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <ExportButton client_id={selectedProject} />
                        </Grid>
                    )}

                </Grid>
            </Paper>
        );
    };

    // MAIN COMPONENT
    return (
        <Grid container spacing={2}>
            {selectedProject && data ? (
                <>
                    <Grid item md={12}>
                        <SubHeader />
                    </Grid>
                    <Grid item md={12}>
                        {tabComponents.map((tabComponent, id) => (
                            <TabPanel key={id} value={selectedTab} index={id} label={`${tabLabel}-${id}`} ariaLabel={`${tabAriaLabel}-${id}`}>
                                {tabComponent}
                            </TabPanel>
                        ))}
                    </Grid>
                </>
            ) : (
                <Grid item md={12}>
                    <Paper>
                        Tidak ada project.
                    </Paper>
                </Grid>
            )}

        </Grid>
    );
};

export default DashboardPage;
