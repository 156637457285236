import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { Paper } from "../../display/paper/Paper";
import Breadcrumbs, { Crumbles } from "../breadcrumbs/Breadcrumbs";
import ProjectLoading from "./ProjectLoading";
type HeaderLoadingProps = {
	titleWidth: number;
	breadcrumbs: Crumbles[];
};
const HeaderLoading = ({ titleWidth, breadcrumbs }: HeaderLoadingProps) => {
	return (
		<Paper>
			<Grid container spacing={"1rem"} alignItems={"center"}>
				<Grid item>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Skeleton variant="text" sx={{ marginRight: "0.25rem", fontSize: "1.625rem", width: `${titleWidth}ch`, mr: "1rem" }} />
						<Divider orientation="vertical" variant="middle" flexItem />
					</Box>
				</Grid>
				<Grid item md>
					<Breadcrumbs loading content={[{ text: "Home", path: "/" }, ...breadcrumbs]} />
				</Grid>
				<Grid item>
					<ProjectLoading />
				</Grid>
			</Grid>
		</Paper>
	);
};

export default HeaderLoading;
