import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectContext } from "../../../../../../../context/ProjectContext";
import { useFetchProcessLevelAssessment } from "../../../../../../../hooks/query/processes/useFetchProcessLevelAssessment";
import { a11yProps, AlphabeticId } from "../../../../../../../lib/utils/helper";
import { Context } from "../../../../../../../types/response";
import Legend from "../../../../../../components/common/Legend";
import { Paper } from "../../../../../../components/display/paper/Paper";
import { H2, Small } from "../../../../../../components/display/typography";
import TabPanel from "../../../../../../components/navigations/tabs/TabPanel";
import { TabsVertical, TabVertical } from "../../../../../../components/navigations/tabs/Tabs";
import { IconBullet } from "../../../../components/List/Bullet";
import { ReviewAssessmentPageProps } from "../../../../components/props";
import QuestionnairePracticeTab from "../../../pm/tabs/responses/QuestionnairePracticeTab";
const QuestionnaireAssessmentPage = ({ domain, processId, level, assessmentId }: ReviewAssessmentPageProps) => {
	// CONTEXT
	const { state: { selectedProject } } = useContext(ProjectContext);
	// STATE
	const { data } = useFetchProcessLevelAssessment(`${domain}${processId}`, level, assessmentId, selectedProject);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedTab, setSelectedTab] = useState(parseInt(searchParams.get("criteria") as string) || 0);

	// LOCAL STORAGE
	const assessmentStatus = JSON.parse(localStorage.getItem("open") || "{}")


	// EFFECTS
	useEffect(() => {
		const currentParams = searchParams.get("criteria");
		if (currentParams) {
			const isParamsExist = parseInt(currentParams) <= data.criterias.length
			if (!isParamsExist) {
				const currentPracticeParams = searchParams.get("practice");
				if (currentPracticeParams) {
					setSearchParams({ criteria: "0", practice: currentPracticeParams });
				}

			}
		}
	}, []);
	// FUNCTIONS
	const handleTabChange = (event: SyntheticEvent, newValue: number) => {
		const currentPracticeParams = searchParams.get("practice");
		if (currentPracticeParams) {
			setSearchParams({ criteria: newValue.toString(), practice: "0" });
		}
		setSelectedTab(newValue);
		scrollToTop();
	};

	const scrollToTop = () => {
		const firstElement = document.querySelector('#header');
		if (firstElement) {
			firstElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const pageContext = JSON.parse(localStorage.getItem("context") || "{}") as Context;

	// VARIABLES
	const tabLabel = "questionnaire-review-project-manager-tabpanel";
	const tabAriaLabel = "questionnaire-review-project-manager-tab";

	// Sub-Header Component
	const SubHeader = () => {
		return (
			<Paper sx={{ py: "1rem" }}>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item md={12}>
						<Box display={"flex"} alignItems="center">
							<Small style={{ marginRight: "1rem" }}>{`Level ${pageContext.level}`}</Small>
							<H2 style={{ margin: 0 }}>{data.assessment}</H2>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		);
	};

	// Main component
	return (
		<Grid container spacing={2}>
			{selectedProject && data ? (
				<>
					<Grid item md={12}>
						<SubHeader />
					</Grid>
					<Grid item md={12}>
						<Paper>
							{!assessmentStatus.open && <Alert sx={{ mb: 2 }} severity="error">
								Lembar kerja ditutup, hubungi Project Manager untuk membuka akses.
							</Alert>}
							{assessmentStatus.open && (
								<>
									<Legend />
									<Grid container spacing={2}>
										<Grid item md={3}>
											<Box position={"sticky"} top={0} bgcolor={"white"} zIndex={3} py={2}>
												<H2>Criteria <Chip label={data.criterias.length} size="small" color="error" sx={{ fontWeight: 400 }} /></H2>
												<TabsVertical orientation="vertical" value={selectedTab} onChange={handleTabChange} aria-label="review criteria tabs">
													{data.criterias.map((criteria, id) => (
														<TabVertical
															key={`criteria-tab-${id}`}
															className={criteria.filled ? "filled" : ""}
															icon={<IconBullet size="normal" completed={criteria.filled} text={AlphabeticId[id]} />}
															iconPosition="start"
															label={criteria.criteria ? criteria.criteria : `${pageContext.process}`}
															{...a11yProps(id, `${tabLabel}-criteria-${id}`, `${tabAriaLabel}-criteria-${id}`)}
														/>
													))}
												</TabsVertical>
											</Box>
										</Grid>
										<Grid item md={9}>
											{data.criterias.map((criteria, id) => (
												<TabPanel key={`criteria-tabpanel-${id}`} value={selectedTab} index={id} label={`${tabLabel}-criteria-${id}`} ariaLabel={`${tabAriaLabel}-criteria-${id}`}>
													<QuestionnairePracticeTab id={id} data={criteria} />
												</TabPanel>
											))}
										</Grid>
									</Grid>
								</>
							)}
						</Paper>
					</Grid>
				</>
			) : (
				<Grid item md={12}>
					<Paper>
						Tidak ada project.
					</Paper>
				</Grid>
			)}

		</Grid>
	);
};

export default QuestionnaireAssessmentPage;
