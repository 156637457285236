import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import { memo, SyntheticEvent, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectContext } from "../../../../../../../context/ProjectContext";
import { useFetchProcessLevel } from "../../../../../../../hooks/query/processes/useFetchProcessLevel";
import { a11yProps, AlphabeticId } from "../../../../../../../lib/utils/helper";
import Legend from "../../../../../../components/common/Legend";
import { Paper } from "../../../../../../components/display/paper/Paper";
import { H2, Small } from "../../../../../../components/display/typography";
import TabPanel from "../../../../../../components/navigations/tabs/TabPanel";
import { TabsVertical, TabVertical } from "../../../../../../components/navigations/tabs/Tabs";
import { IconBullet } from "../../../../components/List/Bullet";
import { QuestionLevelPageProps } from "../../../../components/props";
import CriteriaRepeater from "../../tabs/questions/repeater/CriteriaRepeater";

const paramsToTab = (tab_params: string) => {
	const tab = parseInt(tab_params);
	if (tab > 1 || isNaN(tab)) {
		return 0;
	}
	return tab;
}
const QuestionLevelPage = ({ domain, processId, level }: QuestionLevelPageProps) => {
	// CONTEXT
	const { state: { selectedProject } } = useContext(ProjectContext);
	// STATE
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedTab, setSelectedTab] = useState(paramsToTab(searchParams.get("assessment") as string));
	const { data } = useFetchProcessLevel(`${domain}${processId}`, level, selectedProject);

	// FUNCTIONS
	const handleTabChange = (event: SyntheticEvent, newValue: number) => {
		setSearchParams({ assessment: newValue.toString() });
		setSelectedTab(newValue);
		scrollToTop();
	};

	const scrollToTop = () => {
		const firstElement = document.querySelector('#header');
		if (firstElement) {
			firstElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const processLevelName = (level: number) => {
		switch (level) {
			case 0:
				return "Incomplete";
			case 1:
				return "Performed";
			case 2:
				return "Managed";
			case 3:
				return "Estabilished";
			case 4:
				return "Kuantitatif";
			case 5:
				return "Optimizing";
		}
	};

	// VARIABLES
	const tabLabel = "questionnaire-question-pm-tabpanel";
	const tabAriaLabel = "questionnaire-question-pm-tab";

	// Local component
	const SubHeader = () => {
		return (
			<Paper sx={{ py: "1rem" }}>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item>
						<Box display={"flex"} alignItems={"center"} gap={2}>
							<Small>{domain}{processId}</Small>
							<H2 style={{ margin: 0 }}>
								Level {data.level} <span style={{ fontWeight: 400 }}>{processLevelName(data.level)}</span>
							</H2>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		);
	};

	return (
		<Grid container spacing={2}>
			{selectedProject && data ? (
				<>
					<Grid item md={12}>
						<SubHeader />
					</Grid>
					<Grid item md={12}>
						<Paper>
							<Legend />
							<Grid container spacing={2}>
								<Grid item md={3}>
									<Box position={"sticky"} top={0} bgcolor={"white"} zIndex={3} py={2}>
										<H2>Assessments <Chip label={`${data.assessments.length}`} color="error" size="small" sx={{ fontWeight: 400 }} /></H2>
										<TabsVertical orientation="vertical" value={selectedTab} onChange={handleTabChange} aria-label="question assessment tabs">
											{data.assessments.map((assessment, id) => (
												<TabVertical
													key={`assessments-${id}`}
													className={assessment.filled ? "filled" : ""}
													icon={<IconBullet size="normal" completed={assessment.filled} text={AlphabeticId[id]} />}
													iconPosition="start"
													label={assessment.assessment}
													{...a11yProps(id, `${tabLabel}-assessments-${id}`, `${tabAriaLabel}-assessments-${id}`)}
												/>
											))}
										</TabsVertical>
									</Box>
								</Grid>
								<Grid item md={9}>
									{data.assessments.map((assessment, id) => (
										<TabPanel key={id} value={selectedTab} index={id} label={`${tabLabel}-criteria-${id}`} ariaLabel={`${tabAriaLabel}-criteria-${id}`}>
											<Box position={"sticky"} top={0} bgcolor={"white"} zIndex={3} p={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
												<H2>Criteria <Chip label={`${assessment.criterias.length}`} color="error" size="small" sx={{ fontWeight: 400 }} /></H2>
											</Box>
											<List dense>
												<CriteriaRepeater data={assessment.criterias} assessmentID={assessment.ID} />
											</List>
										</TabPanel>
									))}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</>
			) : (
				<Grid item md={12}>
					<Paper>
						Tidak ada project.
					</Paper>
				</Grid>
			)}
		</Grid>
	);
};

export default memo(QuestionLevelPage);
