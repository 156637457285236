import LogoutIconMUI from "@mui/icons-material/Logout";
import AvatarMUI from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import colors from "../../../../../lib/themes/colors";

export const Avatar = styled(AvatarMUI)(() => ({
	marginRight: "0.625rem",
}));

export const LogoutIcon = styled(LogoutIconMUI)(() => ({
	fontSize: "1rem",
	color: colors.secondary[1],
}));
