import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';

interface ToggleButtonGroupFieldProps extends ToggleButtonGroupProps {
  name: string;
  exclusive?: boolean;
  options: { value: boolean | number; label: string }[];
}

const ToggleButtonGroupField: React.FC<ToggleButtonGroupFieldProps> = ({
  name,
  options,
  ...rest
}) => {
  const [field, , helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string | string[] | null
  ) => {
    if(newValue !== null) {
      helpers.setValue(newValue);
      setFieldValue(name, newValue);
    }
  };

  return (
    <ToggleButtonGroup
      {...rest}
      value={field.value}
      onChange={handleChange}
    >
      {options.map((option) => (
        <ToggleButton key={`${option.value}`} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonGroupField;