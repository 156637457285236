import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useEditProcessMaturity } from "../../../../../hooks/query/process-maturity/useEditProcessMaturity";
import ProcessMaturityFormik from "../../../../../lib/formik/process-maturity";
import { H3 } from "../../../../components/display/typography";
import { EditProcessMaturityProps } from "../../components/props";

const EditEvaluation = ({ selected, open, toggle }: EditProcessMaturityProps) => {
	const { mutate: editProcessMaturity } = useEditProcessMaturity();

	const handleClose = () => {
		toggle(false)();
		formik.resetForm();
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...ProcessMaturityFormik.edit.initialValues,
			id: selected?.id as string,
			rating_scale: selected?.rating_scale as string,
			notation: selected?.notation as string,
			ordinal_rating: selected?.ordinal_rating as number,
			description: selected?.description as string
		},
		validationSchema: ProcessMaturityFormik.edit.validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values) => {

			// call mutation
			editProcessMaturity(values, {
				onError: (error) => {
					formik.setSubmitting(false);
					console.error(error);
				},
				onSuccess: () => {
					handleClose()
				}
			});
		}
	});

	return (
		<Drawer open={open} onClose={() => handleClose()} anchor="right">
			<Box sx={{ minWidth: 400 }} flexDirection={"column"} display={"flex"} justifyContent={"space-between"}>
				<Box px={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<H3>Edit Evaluation</H3>
					<IconButton aria-label="close-drawer" onClick={() => handleClose()}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box component={"form"} onSubmit={formik.handleSubmit} px={2}>
					<Stack spacing={2}>
						<TextField
							autoFocus
							autoComplete="off"
							id="rating_scale"
							name="rating_scale"
							type="rating_scale"
							label="Rating Scale"
							variant="outlined"
							value={formik.values.rating_scale}
							onChange={formik.handleChange}
							error={Boolean(formik.errors.rating_scale)}
							helperText={formik.errors.rating_scale}
						/>
						<TextField
							id="notation"
							name="notation"
							type="text"
							label="Notation"
							variant="outlined"
							disabled
							value={formik.values.notation}
							onChange={formik.handleChange}
							error={Boolean(formik.errors.notation)}
							helperText={formik.errors.notation}
						/>
						<TextField
							id="ordinal_rating"
							name="ordinal_rating"
							type="number"
							label="Ordinal Rating"
							variant="outlined"
							disabled
							value={formik.values.ordinal_rating}
							onChange={formik.handleChange}
							error={Boolean(formik.errors.ordinal_rating)}
							helperText={formik.errors.ordinal_rating}
						/>
						<TextField
							id="description"
							name="description"
							multiline
							minRows={4}
							label="Description"
							variant="outlined"
							value={formik.values.description}
							onChange={formik.handleChange}
							error={Boolean(formik.errors.description)}
							helperText={formik.errors.description}
						/>
					</Stack>
					<Box display={"flex"} justifyContent={"flex-end"}>
						<Button variant="contained" type="submit" sx={{ textTransform: "none", mt: 4 }} disabled={formik.isSubmitting}>
							Simpan
						</Button>
					</Box>
				</Box>
			</Box>
		</Drawer>
	);
};

export default EditEvaluation;
