import { List } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { memo } from "react";
import ListBullet from "../../../../../components/List/Bullet";
import { ListItemQuestion } from "../../../../../components/List/Item";
import { ListLineSmallRepeater } from "../../../../../components/List/Line";
import { EvidencesProps } from "../../../../../components/props";
const EvidenceList = ({ id, response }: EvidencesProps) => {
	return (
		<List
			sx={{
				"& .MuiBox-root": {
					"&.evidence-line": {
						pb: 2,
						"&:last-of-type": {
							pb: 0,
							"&::before": {
								width: 0,
							},
						},
					},
				},
			}}
		>
			{response[0] ?
				response[0].evidences.length > 0 ?
					response[0].evidences.map((evidence, evidence_id) => (
						<ListLineSmallRepeater className="evidence-line" key={`evidence-${evidence.id}-${id}-${evidence_id}`}>
							<ListItemQuestion
								alignItems="flex-start"
								sx={{
									"& .MuiListItemAvatar-root": { mt: 0 },
								}}
							>
								<ListBullet size="small" completed={false} text={(evidence_id + 1).toString()} />
								<Stack spacing={2} width={"100%"}>
									<TextField
										size="small"
										label={`Referensi ${evidence_id + 1}`}
										value={evidence.reference}
										multiline
										rows={6}
										variant="filled"
										fullWidth
										sx={{ mb: 1 }}
										disabled
									/>
									<TextField
										size="small"
										label={`Deskripsi ${evidence_id + 1}`}
										value={evidence.description}
										multiline
										rows={3}
										variant="filled"
										fullWidth
										disabled
									/>
								</Stack>
							</ListItemQuestion>
						</ListLineSmallRepeater>
					)) :
					<Chip sx={{ mx: 0 }} size="small" label="Tidak ada evidence" />
				: <Chip sx={{ mx: 0 }} size="small" label="Penilaian Kosong" />
			}
		</List>
	);
};

export default memo(EvidenceList);
