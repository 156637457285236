import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { createUser } from "../../../services/user";
export const useCreateUser = () => {
    return useMutation({
        mutationFn: createUser,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["users"],
            });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};