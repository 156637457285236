import { array, object, Schema, string } from "yup";
import Role from "../../constants/role";
import { CreateUser, EditUser } from "../../services/types/user";

const createInitialValues: CreateUser = {
	name: "",
	email: "",
	password: "3rg9M55W+XRy",
	role: "assessor" as Role,
	description: "",
	assigned_projects: [],
};

const editInitialValues: EditUser = {
	id: "",
	name: "",
	email: "",
	role: "assessor" as Role,
	description: "",
	assigned_projects: [],
};

const createValidationSchema: Schema<CreateUser> = object().shape({
	name: string().required("Nama harus diisi"),
	email: string().email("Email tidak valid").required("Email harus diisi"),
	password: string().min(8, "Password minimal 8 karakter").required("Password harus diisi"),
	role: string().oneOf(["assessor", "pm", "admin"]).default("assessor"),
	description: string().required("Deskripsi harus diisi"),
	assigned_projects: array()
		.of(
			object().shape({
				client: string().required("Client harus diisi"),
				id: string().required("ID harus diisi"),
			})
		)
		.default([]),
});

const editValidationSchema: Schema<EditUser> = object().shape({
	id: string().required("ID harus diisi"),
	name: string().required("Nama harus diisi"),
	email: string().email("Email tidak valid").required("Email harus diisi"),
	role: string().oneOf(["assessor", "pm", "admin"]).default("assessor"),
	description: string().required("Deskripsi harus diisi"),
	assigned_projects: array()
		.of(
			object().shape({
                client: string().required("Client harus diisi"),
				id: string().required("ID harus diisi"),
			})
		)
		.default([]),
});

export const isKeyOfCreateUser = (key: string, obj: CreateUser | undefined): key is keyof CreateUser => {
	if (!obj) return false;
	return key in obj;
};

export const isKeyOfEditUser = (key: string, obj: EditUser | undefined): key is keyof EditUser => {
	if (!obj) return false;
	return key in obj;
};

const userFormik = {
	create: {
		initialValues: createInitialValues,
		validationSchema: createValidationSchema,
        isKeyOf: isKeyOfCreateUser,
	},
	edit: {
		initialValues: editInitialValues,
		validationSchema: editValidationSchema,
        isKeyOf: isKeyOfEditUser,
	}
};

export default userFormik;
