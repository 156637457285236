import { Suspense } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation } from "react-router-dom";
import Unauthenticated from "../../../../components/common/Redirect/Unauthenticated";
import Header from "../../../../components/navigations/header/Header";
import CreateClientPage from "../../route-element/admin/create";

const CreateClient = () => {
	const authUser = useAuthUser();
	const location = useLocation();
	if (!authUser) return <Unauthenticated />;

	const Page = () => {
		if (authUser.role === "admin") return <CreateClientPage />;
		else return <>Forbidden</>;
	}

	return <>
		<Header
			title="IT Maturity Based on COBIT 5"
			breadcrumbs={[
				{ text: "Clients", path: `${location.pathname}` },
				{ text: "Create", path: `${location.pathname}` },
			]}
		/>
		<Suspense fallback={<>Loading client data...</>}>
			<Page />
		</Suspense>
	</>;
};

export default CreateClient;
