import { AssessmentData } from "../types/assessments";

// Data
export const data: AssessmentData[] = [
	{
		id: 1,
		domain: "EDM",
		process_id: "EDM01",
		type: "design_factor",
		process_name: "Ensured Governance Framework Setting and Maintenance",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 2,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 97.91666666666667,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.225,
	},
	{
		id: 2,
		domain: "EDM",
		process_id: "EDM02",
		type: "related_regulations",
		process_name: "Ensured Benefits Delivery",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 91.66666666666666,
		level_1: 91.66666666666666,
		level_2: 100,
		level_3: 76.11111111111111,
		level_4: 33.33333333333333,
		level_5: 33.33333333333333,
		summary: 3.55,
	},
	{
		id: 3,
		domain: "EDM",
		process_id: "EDM03",
		type: "design_factor",
		process_name: "Ensured Risk Optimisation",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 73.51851851851852,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 4.5,
	},
	{
		id: 4,
		domain: "EDM",
		process_id: "EDM04",
		type: "related_regulations",
		process_name: "Ensured Resource Optimisation",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.241666666666666,
	},
	{
		id: 5,
		domain: "EDM",
		process_id: "EDM05",
		type: "design_factor",
		process_name: "Ensured Stakeholder Engagement",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 2,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 66.66666666666666,
		level_1: 66.66666666666666,
		level_2: 89.99999999999999,
		level_3: 50,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 3.3833333333333333,
	},
	{
		id: 6,
		domain: "APO",
		process_id: "APO01",
		type: "design_factor",
		process_name: "Managed IT management framework",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 98.05194805194807,
		level_1: 96.96969696969697,
		level_2: 97.91666666666667,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.183333333333334,
	},
	{
		id: 7,
		domain: "APO",
		process_id: "APO02",
		type: "design_factor",
		process_name: "Managed Strategy",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 100,
		summary: 4.8,
	},
	{
		id: 8,
		domain: "APO",
		process_id: "APO03",
		type: "design_factor",
		process_name: "Managed Enterprise Architecture",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 89.25925925925927,
		level_1: 84.44444444444444,
		level_2: 97.91666666666667,
		level_3: 100,
		level_4: 66.66666666666666,
		level_5: 83.33333333333333,
		summary: 4.341666666666667,
	},
	{
		id: 9,
		domain: "APO",
		process_id: "APO04",
		type: "design_factor",
		process_name: "Managed Innovation",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 97.22222222222221,
		level_1: 100,
		level_2: 100,
		level_3: 83.33333333333333,
		level_4: 66.66666666666666,
		level_5: 33.33333333333333,
		summary: 4,
	},
	{
		id: 10,
		domain: "APO",
		process_id: "APO05",
		type: "design_factor",
		process_name: "Managed Portfolio",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 95.83333333333333,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 100,
		summary: 4.758333333333334,
	},
	{
		id: 11,
		domain: "APO",
		process_id: "APO06",
		type: "related_regulations",
		process_name: "Managed Budget and Costs",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 94.07407407407408,
		level_1: 100,
		level_2: 100,
		level_3: 100,
		level_4: 66.66666666666666,
		level_5: 83.33333333333333,
		summary: 4.533333333333333,
	},
	{
		id: 12,
		domain: "APO",
		process_id: "APO07",
		type: "design_factor",
		process_name: "Managed Human Resources",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 93.63425925925927,
		level_1: 98.41269841269842,
		level_2: 100,
		level_3: 100,
		level_4: 33.33333333333333,
		level_5: 66.66666666666666,
		summary: 4.1,
	},
	{
		id: 13,
		domain: "APO",
		process_id: "APO08",
		type: "design_factor",
		process_name: "Managed Relationships",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 97.77777777777779,
		level_1: 100,
		level_2: 98.61111111111111,
		level_3: 83.33333333333333,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.275,
	},
	{
		id: 14,
		domain: "APO",
		process_id: "APO09",
		type: "related_regulations",
		process_name: "Managed Service Agreements",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 93.33333333333333,
		level_1: 100,
		level_2: 97.91666666666667,
		level_3: 90.91666667,
		level_4: 66.66666666666666,
		level_5: 100,
		summary: 4.566666667,
	},
	{
		id: 15,
		domain: "APO",
		process_id: "APO10",
		type: "design_factor",
		process_name: "Managed Vendors",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 96.66666666666667,
		level_1: 94.11764705882354,
		level_2: 100,
		level_3: 33.33333333333333,
		level_4: 66.66666666666666,
		level_5: 33.33333333333333,
		summary: 3.533333333333333,
	},
	{
		id: 16,
		domain: "APO",
		process_id: "APO11",
		type: "design_factor",
		process_name: "Managed Quality",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 98.76543209876544,
		level_1: 100,
		level_2: 100,
		level_3: 83.33333333333333,
		level_4: 33.33333333333333,
		level_5: 83.33333333333333,
		summary: 4.15,
	},
	{
		id: 17,
		domain: "APO",
		process_id: "APO12",
		type: "design_factor",
		process_name: "Managed Risk",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 4,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 100,
		level_4: 83.33333333333333,
		level_5: 66.66666666666666,
		summary: 4.583333333333333,
	},
	{
		id: 18,
		domain: "APO",
		process_id: "APO13",
		type: "design_factor",
		process_name: "Managed Security",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 83.33333333333333,
		level_4: 100,
		level_5: 100,
		summary: 4.858333333333333,
	},
	{
		id: 19,
		domain: "APO",
		process_id: "APO14",
		type: "design_factor",
		process_name: "Managed Data",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 77.24867724867725,
		level_1: 33.33333333333333,
		level_2: 66.66666666666666,
		level_3: 100,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 3.691666666666667,
	},
	{
		id: 20,
		domain: "BAI",
		process_id: "BAI01",
		type: "design_factor",
		process_name: "Managed Programs",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 100,
		level_4: 50,
		level_5: 66.66666666666666,
		summary: 4.3,
	},
	{
		id: 21,
		domain: "BAI",
		process_id: "BAI02",
		type: "design_factor",
		process_name: "Managed Requirements Definition",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 83.33333333333333,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 4.583333333333333,
	},
	{
		id: 22,
		domain: "BAI",
		process_id: "BAI03",
		type: "design_factor",
		process_name: "Managed Solutions Identification and Build",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 99.30555555555554,
		level_1: 98.48484848484848,
		level_2: 98.14814814814814,
		level_3: 75.47355047355046,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 4.483333333333333,
	},
	{
		id: 23,
		domain: "BAI",
		process_id: "BAI04",
		type: "design_factor",
		process_name: "Managed Availability and Capacity",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 99.38616071428572,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 4.516666666666667,
	},
	{
		id: 24,
		domain: "BAI",
		process_id: "BAI05",
		type: "design_factor",
		process_name: "Managed Organisational Change",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 77.08333333333333,
		level_3: 50,
		level_4: 33.33333333333333,
		level_5: 33.33333333333333,
		summary: 3.275,
	},
	{
		id: 25,
		domain: "BAI",
		process_id: "BAI06",
		type: "design_factor",
		process_name: "Managed IT Changes",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 4,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 91.74107142857142,
		level_3: 75.55555555555556,
		level_4: 66.66666666666666,
		level_5: 33.33333333333333,
		summary: 3.8916666666666666,
	},
	{
		id: 26,
		domain: "BAI",
		process_id: "BAI07",
		type: "design_factor",
		process_name: "Managed Change Acceptance and Transitioning",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 99.21875,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.233333333333333,
	},
	{
		id: 27,
		domain: "BAI",
		process_id: "BAI08",
		type: "design_factor",
		process_name: "Managed Knowledge",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 92.70833333333333,
		level_3: 76.11111111111111,
		level_4: 33.33333333333333,
		level_5: 66.66666666666666,
		summary: 3.9,
	},
	{
		id: 28,
		domain: "BAI",
		process_id: "BAI09",
		type: "design_factor",
		process_name: "Managed Assets",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 62.59259259259259,
		level_1: 52.77777777777778,
		level_2: 72.91666666666666,
		level_3: 49.88888888888889,
		level_4: 33.33333333333333,
		level_5: 33.33333333333333,
		summary: 2.533333333333333,
	},
	{
		id: 29,
		domain: "BAI",
		process_id: "BAI10",
		type: "design_factor",
		process_name: "Managed Configuration",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 56.25,
		level_3: 76.11111111111111,
		level_4: 33.33333333333333,
		level_5: 66.66666666666666,
		summary: 3.6,
	},
	{
		id: 30,
		domain: "BAI",
		process_id: "BAI11",
		type: "design_factor",
		process_name: "Managed Projects",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 3,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 99.53703703703704,
		level_1: 96.29629629629629,
		level_2: 98.98478835978837,
		level_3: 83.33333333333333,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 4.533333333333333,
	},
	{
		id: 31,
		domain: "DSS",
		process_id: "DSS01",
		type: "design_factor",
		process_name: "Managed Operations",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 93.61111111111113,
		level_1: 93.33333333333333,
		level_2: 100,
		level_3: 75.6888888888889,
		level_4: 66.66666666666666,
		level_5: 66.66666666666666,
		summary: 4.125,
	},
	{
		id: 32,
		domain: "DSS",
		process_id: "DSS02",
		type: "design_factor",
		process_name: "Managed Service Requests and Incidents",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 93.33333333333334,
		level_1: 85.71428571428572,
		level_2: 100,
		level_3: 74.83333333,
		level_4: 100,
		level_5: 66.66666666666666,
		summary: 4.333333333,
	},
	{
		id: 33,
		domain: "DSS",
		process_id: "DSS03",
		type: "design_factor",
		process_name: "Managed Problems",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 100,
		level_1: 100,
		level_2: 89.58333333333333,
		level_3: 76.11111111111111,
		level_4: 33.33333333333333,
		level_5: 66.66666666666666,
		summary: 3.875,
	},
	{
		id: 34,
		domain: "DSS",
		process_id: "DSS04",
		type: "design_factor",
		process_name: "Managed Continuity",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 2,
		capability_max_brins: 5,
		target_capability_maturity_level: 3,
		level_0: 95.23809523809524,
		level_1: 95.23809523809524,
		level_2: 94.73684210526316,
		level_3: 76.18233618233619,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.116666666666666,
	},
	{
		id: 35,
		domain: "DSS",
		process_id: "DSS05",
		type: "design_factor",
		process_name: "Managed Security Services",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 4,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 85.25132275132276,
		level_1: 83.33333333333334,
		level_2: 95.83333333333333,
		level_3: 76.11111111111111,
		level_4: 100,
		level_5: 100,
		summary: 4.5,
	},
	{
		id: 36,
		domain: "DSS",
		process_id: "DSS06",
		type: "bumn",
		process_name: "Managed Business Process Controls",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 3,
		level_0: 95.18518518518519,
		level_1: 94.44444444444446,
		level_2: 100,
		level_3: 50,
		level_4: 33.33333333333333,
		level_5: 33.33333333333333,
		summary: 3.383333333,
	},
	{
		id: 37,
		domain: "MEA",
		process_id: "MEA01",
		type: "bumn",
		process_name: "Managed Performance and Conformance Monitoring",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 5,
		target_capability_maturity_level: 4,
		level_0: 100,
		level_1: 100,
		level_2: 50,
		level_3: 83.33333333,
		level_4: 33.33333333,
		level_5: 33.33333333,
		summary: 3.333333333,
	},
	{
		id: 38,
		domain: "MEA",
		process_id: "MEA02",
		type: "related_regulations",
		process_name: "Managed System of Internal Control",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 1,
		capability_max_brins: 4,
		target_capability_maturity_level: 4,
		level_0: 95.23809523809524,
		level_1: 100,
		level_2: 100,
		level_3: 66.66666666666666,
		level_4: 83.33333333333333,
		level_5: 100,
		summary: 4.541666666666667,
	},
	{
		id: 39,
		domain: "MEA",
		process_id: "MEA03",
		type: "design_factor",
		process_name: "Managed Compliance With External Requirements",
		cobit_2019_max_capability: 5,
		agreed_target_capability_level: 4,
		capability_max_brins: 5,
		target_capability_maturity_level: 4,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 50,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.025,
	},
	{
		id: 40,
		domain: "MEA",
		process_id: "MEA04",
		type: "design_factor",
		process_name: "Managed Assurance",
		cobit_2019_max_capability: 4,
		agreed_target_capability_level: 3,
		capability_max_brins: 3,
		target_capability_maturity_level: 4,
		level_0: 100,
		level_1: 100,
		level_2: 100,
		level_3: 100,
		level_4: 100,
		level_5: 33.33333333333333,
		summary: 4.441666666666666,
	},
];
