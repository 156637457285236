import { useMutation } from "@tanstack/react-query";
import { editActivityAlternatives } from "../../../services/activity";
import { EditActivityAlternative } from "../../../services/types/process";
export const useEditActivityAlternatives = (processId: string, clientID: string) => {
	return useMutation({
		mutationFn: (data: EditActivityAlternative[]) => {
			return editActivityAlternatives(data, processId, clientID);
		},
		onSuccess: () => {
			// 
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
