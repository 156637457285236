import MockAdapter from "axios-mock-adapter";
import { apo, bai, dss, edm, getQuestionnaireByMockProcessId, mea } from "./data";
const getDomainData = (domain: string) => {
	switch (domain) {
		case "edm":
			return edm;
		case "apo":
			return apo;
		case "bai":
			return bai;
		case "dss":
			return dss;
		case "mea":
			return mea;
		default:
			return undefined;
	}
};

const questionnaires = (mock: MockAdapter) => {
	const discussion = /\/api\/questionnaires\/([a-zA-Z0-9_-]+)\/(\d+)\/(\d+)\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)$/;
	mock.onGet(discussion).reply((config) => {
		if (config && config.url) {
			// cache
			if (localStorage.getItem(config.url)) {
				const data = localStorage.getItem(config.url);
				return [200, data];
			}

			// fetch
			const match = config.url.match(discussion);
			if (match) {
				const [_, domainName, processId, levelId, assessmentId, performanceGoalId] = match;
				if (performanceGoalId && assessmentId && levelId && processId && domainName) {
					const domainData = getDomainData(domainName);
					if (domainData) {
						const processIdData = getQuestionnaireByMockProcessId(domainData, processId);
						if (processIdData) {
							const levelData = processIdData.levels.find((level) => level.level === parseInt(levelId));
							if (levelData) {
								const assessmentData = levelData.assessments.find((assessment) => assessment.id === assessmentId);
								if (assessmentData) {
									const performanceGoalData = assessmentData.performance_goals.find((performance_goal) => performance_goal.id === performanceGoalId);
									if (performanceGoalData) {
										localStorage.setItem(
											config.url,
											JSON.stringify({
												domain: domainName.toUpperCase(),
												processId,
												level: parseInt(levelId),
												assessment: assessmentId,
												performance_goal: performanceGoalData,
											})
										);
										return [
											200,
											{
												domain: domainName.toUpperCase(),
												processId,
												level: parseInt(levelId),
												assessment: assessmentId,
												performance_goal: performanceGoalData,
											},
										];
									}
								}
							}
						}
					}
				}
			}
		}
		return [404, { message: "5 not found" }];
	});

	const review = /\/api\/questionnaires\/([a-zA-Z0-9_-]+)\/(\d+)\/(\d+)\/([a-zA-Z0-9-]+)$/;
	mock.onGet(review).reply((config) => {
		if (config && config.url) {
			// cache
			if (localStorage.getItem(config.url)) {
				const data = localStorage.getItem(config.url);
				return [200, data];
			}

			// fetch
			const match = config.url.match(review);
			if (match) {
				const [_, domainName, processId, levelId, assessmentId] = match;
				if (assessmentId && levelId && processId && domainName) {
					const domainData = getDomainData(domainName);
					if (domainData) {
						const processIdData = getQuestionnaireByMockProcessId(domainData, processId);
						if (processIdData) {
							const levelData = processIdData.levels.find((level) => level.level === parseInt(levelId));
							if (levelData) {
								const assessmentData = levelData.assessments.find((assessment) => assessment.id === assessmentId);
								if (assessmentData) {
									localStorage.setItem(
										config.url,
										JSON.stringify({
											domain: domainName.toUpperCase(),
											processId,
											level: parseInt(levelId),
											assessment: assessmentData,
										})
									);
									return [
										200,
										{
											domain: domainName.toUpperCase(),
											processId,
											level: parseInt(levelId),
											assessment: assessmentData,
										},
									];
								}
							}
						}
					}
				}
			}
		}
		return [404, { message: "4 not found" }];
	});

	const question = /\/api\/questionnaires\/([a-zA-Z0-9_-]+)\/(\d+)\/(\d+)$/;
	mock.onGet(question).reply((config) => {
		if (config && config.url) {
			// cache
			if (localStorage.getItem(config.url)) {
				const data = localStorage.getItem(config.url);
				return [200, data];
			}

			// fetch
			const match = config.url.match(question);
			if (match) {
				const [_, domainName, processId, levelId] = match;
				if (levelId && processId && domainName) {
					const domainData = getDomainData(domainName);
					if (domainData) {
						const processIdData = getQuestionnaireByMockProcessId(domainData, processId);
						if (processIdData) {
							const levelData = processIdData.levels.find((level) => level.level === parseInt(levelId));
							if (levelData) {
								localStorage.setItem(
									config.url,
									JSON.stringify({
										domain: domainName.toUpperCase(),
										processId,
										level: parseInt(levelId),
										assessments: levelData.assessments,
									})
								);
								return [
									200,
									{
										domain: domainName.toUpperCase(),
										processId,
										level: parseInt(levelId),
										assessments: levelData.assessments,
									},
								];
							}
						}
					}
				}
			}
		}
		return [404, { message: "3 not found" }];
	});

	const domainAndProcess = /\/api\/questionnaires\/([a-zA-Z0-9_-]+)\/(\d+)$/;
	mock.onGet(domainAndProcess).reply((config) => {
		if (config && config.url) {
			// cache
			if (localStorage.getItem(config.url)) {
				const data = localStorage.getItem(config.url);
				return [200, data];
			}

			// fetch
			const match = config.url.match(domainAndProcess);
			if (match) {
				const [_, domainName, processId] = match;
				if (processId && domainName) {
					const domainData = getDomainData(domainName);
					if (domainData) {
						const processIdData = getQuestionnaireByMockProcessId(domainData, processId);
						if (processIdData) {
							localStorage.setItem(config.url, JSON.stringify({ domain: domainName.toUpperCase(), processId: processIdData }));
							return [200, { domain: domainName.toUpperCase(), processId: processIdData }];
						}
					}
				}
			}
		}
		return [404, { message: "2 not found" }];
	});

	return mock;
};

export default questionnaires;
