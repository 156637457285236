import EditIcon from "@mui/icons-material/Edit";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { AlphabeticId, renderLevelChip } from "../../../../../lib/utils/helper";
import { Discussion } from "../../../../../services/types/process";
import { ActionButton } from "../../../../components/buttons/ActionButton";
import { DomainProcessTabListLevelsProps } from "../props";
import ListBullet from "./Bullet";
import ListItem from "./Item";
import ListLine from "./Line";
import ListSmall from "./Small";
type OpenState = {
	[key: number]: boolean;
};
const ListLevel = ({ levels, type = "responses", open = true }: DomainProcessTabListLevelsProps) => {
	// STATES
	const [listOpen, setListOpen] = useState<OpenState>({
		0: true,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
	});

	// FUNCTIONS
	const handleOpen = (event: MouseEvent<HTMLElement>, level: number) => {
		setListOpen((prevOpen: OpenState) => ({
			...prevOpen,
			[level]: !prevOpen[level],
		}));
	};

	const renderLevelName = (level: number) => {
		switch (level) {
			case 0:
				return (
					<>
						<strong>Level {level}</strong> Incomplete
					</>
				);
			case 1:
				return (
					<>
						<strong>Level {level}</strong> Performed
					</>
				);
			case 2:
				return (
					<>
						<strong>Level {level}</strong> Managed
					</>
				);
			case 3:
				return (
					<>
						<strong>Level {level}</strong> Estabilished
					</>
				);
			case 4:
				return (
					<>
						<strong>Level {level}</strong> Kuantitatif
					</>
				);
			case 5:
				return (
					<>
						<strong>Level {level}</strong> Optimizing
					</>
				);
			default:
				return <>Undefined Level</>;
		}
	};

	const renderCriteriaName = (criteria: string, description: string) => {
		return (
			<>
				<strong>{criteria}</strong> {description}
			</>
		);
	};

	const calculateDiscussions = (discussion_references: Discussion[]) => {
		return discussion_references.reduce((prev, curr) => curr.activities.length + prev, 0);
	};

	if (type === "questions") {
		return (
			<List dense>
				{/* Assessment */}
				{levels.map((level, id) => (
					<Box key={id}>
						<ListItem onClick={(e) => handleOpen(e, level.level)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<Box display={"inline-flex"} alignItems={"center"}>
								<ListBullet size="normal" completed={level.filled} text={level.level.toString()} />
								<ListItemText primary={renderLevelName(level.level)} disableTypography />
								{/* {renderLevelChip(4, 1, `${level.assessments.length} Assessment, ${calculateCriterias(level.assessments)} Performance Goal`)} */}
								<ActionButton
									sx={{ mx: 1 }}
									variant="outlined"
									startIcon={<EditIcon />}
									{...({ LinkComponent: Link, to: `question/${level.id}` })}
								>
									Kelola
								</ActionButton>
							</Box>
							{listOpen[level.level] ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={listOpen[level.level]} timeout="auto" unmountOnExit>
							{/* Performance Goal */}
							<Box display={"flex"}>
								<ListLine />
								<Box flex={1}>
									{level.assessments.map((assessment, id) => (
										<List component="div" disablePadding dense key={id}>
											<ListItem>
												<Box display={"inline-flex"} alignItems={"center"}>
													<ListItemText primary={<>{assessment.assessment}</>} disableTypography />
												</Box>
											</ListItem>
											{assessment.criterias.map((criteria, id) => {
												if (criteria.criteria !== null) {
													return (
														<Box key={id}>
															<ListItem>
																<Box display={"inline-flex"} alignItems={"center"}>
																	<ListBullet size="normal" completed={criteria.filled} text={AlphabeticId[id]} />
																	<ListItemText primary={<>{criteria.criteria}</>} disableTypography />
																	{/* {renderLevelChip(4, 1, `${criteria.practices} Criteria`)} */}
																</Box>
															</ListItem>
															{/* Criteria */}
															<Box display={"flex"}>
																<ListLine />
																<Box flex={1}>
																	<ListSmall component="div" disablePadding dense>
																		{criteria.practices.map((practice, id) => {
																			if (practice.practice_id !== null) {
																				return (
																					<ListItem key={id}>
																						<Box display={"inline-flex"} alignItems={"center"}>
																							<ListBullet size="small" completed={practice.filled} text=" " />
																							<ListItemText primary={renderCriteriaName(practice.practice_id, practice.description)} disableTypography />
																							{renderLevelChip(
																								4,
																								1,
																								`${practice.discussions.length} Discussions, ${calculateDiscussions(
																									practice.discussions
																								)} Activity`
																							)}
																						</Box>
																					</ListItem>
																				)
																			}
																			return null
																		}
																		)}
																	</ListSmall>
																</Box>
															</Box>
														</Box>
													)
												} else {
													return (
														<Box key={id}>
															<Box display={"flex"}>
																<ListLine />
																<Box flex={1}>
																	<ListSmall component="div" disablePadding dense>
																		{criteria.practices.map((practice, id) => (
																			<ListItem key={id}>
																				<Box display={"inline-flex"} alignItems={"center"}>
																					<ListBullet size="small" completed={practice.filled} text=" " />
																					<ListItemText primary={renderCriteriaName(practice.practice_id, practice.description)} disableTypography />
																					{renderLevelChip(
																						4,
																						1,
																						`${practice.discussions.length} Discussions, ${calculateDiscussions(
																							practice.discussions
																						)} Activity`
																					)}
																				</Box>
																			</ListItem>
																		))}
																	</ListSmall>
																</Box>
															</Box>
														</Box>
													)
												}
											})}
										</List>
									))}
								</Box>
							</Box>
						</Collapse>
					</Box>
				))}
			</List>
		);
	} else if (type === "responses") {
		return (
			<List dense>
				{/* Assessment */}
				{levels?.map((level, id) => (
					<Box key={id}>
						<ListItem onClick={(e) => handleOpen(e, level.level)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<Box display={"inline-flex"} alignItems={"center"}>
								<ListBullet size="normal" completed={level.filled} text={level.level.toString()} />
								<ListItemText primary={renderLevelName(level.level)} disableTypography />
								{/* {renderLevelChip(level.level)} */}
							</Box>
							{listOpen[level.level] ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={listOpen[level.level]} timeout="auto" unmountOnExit>
							{/* Performance Goal */}
							<Box display={"flex"}>
								<ListLine />
								<Box flex={1}>
									{level.assessments.map((assessment, id) => (
										<List component="div" disablePadding dense key={id}>
											<ListItem>
												<Box display={"inline-flex"} alignItems={"center"}>
													<ListItemText primary={<>{assessment.assessment}</>} disableTypography />
													<ActionButton
														sx={{ mx: 1 }}
														variant="outlined"
														disabled={false}
														startIcon={<ReviewsOutlinedIcon />}
														{...({ LinkComponent: Link, to: `review/${level.id}/${assessment.ID}` })}
													// onClick={() => navigate(`${level.level}/${assessment.ID}/review`)}
													>
														Tinjau
													</ActionButton>
												</Box>
											</ListItem>
											{assessment.criterias.map((criteria, id) => {
												if (criteria.criteria !== null) {
													return (
														<Box key={id}>
															<ListItem>
																<ListBullet size="normal" completed={criteria.filled} text={AlphabeticId[id]} />
																<ListItemText primary={<>{criteria.criteria}</>} disableTypography />
															</ListItem>
															{/* Criteria */}
															<Box display={"flex"}>
																<ListLine />
																<Box flex={1}>
																	<ListSmall component="div" disablePadding dense>
																		{criteria.practices.map((practice, id) => {
																			if (practice.practice_id !== null) {
																				return (
																					<ListItem key={id}>
																						<ListBullet size="small" completed={practice.filled} />
																						<ListItemText primary={renderCriteriaName(practice.practice_id, practice.description)} disableTypography />
																					</ListItem>
																				)
																			}
																			return null
																		})}
																	</ListSmall>
																</Box>
															</Box>
														</Box>
													)
												} else {
													return (
														<Box key={id}>
															<Box display={"flex"}>
																<ListLine />
																<Box flex={1}>
																	<ListSmall component="div" disablePadding dense>
																		{criteria.practices.map((practice, id) => (
																			<ListItem key={id}>
																				<ListBullet size="small" completed={practice.filled} />
																				<ListItemText primary={renderCriteriaName(practice.practice_id, practice.description)} disableTypography />
																			</ListItem>
																		))}
																	</ListSmall>
																</Box>
															</Box>
														</Box>
													)
												}
											})}
										</List>
									))}
								</Box>
							</Box>
						</Collapse>
					</Box>
				))}
			</List>
		);
	};
	// MAIN COMPONENT
	return (
		<List dense>
			{/* Assessment */}
			{levels?.map((level, id) => (
				<Box key={id}>
					<ListItem onClick={(e) => handleOpen(e, level.level)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Box display={"inline-flex"} alignItems={"center"}>
							<ListBullet size="normal" completed={level.filled} text={level.level.toString()} />
							<ListItemText primary={renderLevelName(level.level)} disableTypography />
							{/* {renderLevelChip(level.level)} */}
						</Box>
						{listOpen[level.level] ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={listOpen[level.level]} timeout="auto" unmountOnExit>
						{/* Performance Goal */}
						<Box display={"flex"}>
							<ListLine />
							<Box flex={1}>
								{level.assessments.map((assessment, id) => (
									<List component="div" disablePadding dense key={id}>
										<ListItem>
											<Box display={"inline-flex"} alignItems={"center"}>
												<ListItemText primary={<>{assessment.assessment}</>} disableTypography />
												<ActionButton
													sx={{ mx: 1 }}
													variant="outlined"
													disabled={!open}
													startIcon={<EditNoteOutlinedIcon />}
													{...({ LinkComponent: Link, to: open ? `questionnaire/${level.id}/${assessment.ID}`:'' })}
												>
													Jawab
												</ActionButton>
											</Box>
										</ListItem>
										{assessment.criterias.map((criteria, id) => {
											if (criteria.criteria !== null) {
												return (
													<Box key={id}>
														<ListItem>
															<ListBullet size="normal" completed={criteria.filled} text={AlphabeticId[id]} />
															<ListItemText primary={<>{criteria.criteria}</>} disableTypography />
														</ListItem>
														{/* Criteria */}
														<Box display={"flex"}>
															<ListLine />
															<Box flex={1}>
																<ListSmall component="div" disablePadding dense>
																	{criteria.practices.map((practice, id) => {
																		if (practice.practice_id !== null) {
																			return (
																				<ListItem key={id}>
																					<ListBullet size="small" completed={practice.filled} />
																					<ListItemText primary={renderCriteriaName(practice.practice_id, practice.description)} disableTypography />
																				</ListItem>
																			)
																		}
																		return null
																	})}
																</ListSmall>
															</Box>
														</Box>
													</Box>
												)
											} else {
												return (
													<Box key={id}>
														<Box display={"flex"}>
															<ListLine />
															<Box flex={1}>
																<ListSmall component="div" disablePadding dense>
																	{criteria.practices.map((practice, id) => (
																		<ListItem key={id}>
																			<ListBullet size="small" completed={practice.filled} />
																			<ListItemText primary={renderCriteriaName(practice.practice_id, practice.description)} disableTypography />
																		</ListItem>
																	))}
																</ListSmall>
															</Box>
														</Box>
													</Box>
												)
											}
										})}
									</List>
								))}
							</Box>
						</Box>
					</Collapse>
				</Box>
			))}
		</List>
	);
}
	export default ListLevel;
