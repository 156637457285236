import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { Link } from "react-router-dom";
import queryClient from "../../../../lib/react-query/client";
import colors from "../../../../lib/themes/colors";
import { Avatar, LogoutIcon } from "./styled";

const User = () => {
	const user = useAuthUser();
	const getStringRole = (role: string | undefined) => {
		switch (role) {
			case "pm":
				return "Project Manager";
			case "assessor":
				return "Assessor";
			case "admin":
				return "Admin";
			default:
				return "UNDEFINED";
		}
	};

	const LogoutButton = () => {
		const signOut = useSignOut();
		return (
			<Link to={"#"} style={{ display: "flex", alignItems: "center" }} onClick={() => {
				localStorage.removeItem("selectedMenu");
				localStorage.removeItem("open");
				localStorage.removeItem("selectedProject");
				localStorage.removeItem("context");
				queryClient.clear();
				signOut();
			}}>
				<LogoutIcon />
			</Link>
		);
	}

	return (
		<Box id="app-user" display={"flex"} sx={{ my: "1.875rem" }} alignItems={"center"} justifyContent={"space-between"}>
			<Box display={"flex"} alignItems={"center"}>
				<Avatar id="app-user-avatar">{user?.name[0]}</Avatar>
				<Box mt={0.5}>
					<Typography id="app-user-name" component={"p"} m={0} fontSize={"1rem"} lineHeight={1.2} width={"10ch"}>
						{user?.name}
					</Typography>
					<Typography id="app-user-role" component={"small"} fontSize={"0.75rem"} color={colors.secondary[1]}>
						{getStringRole(user?.role)}
					</Typography>
				</Box>
			</Box>
			<LogoutButton />
		</Box>
	);
};

export default User;
