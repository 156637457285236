import { RouteObject } from "react-router-dom";
import User from "../../pages/users/route/page";

const UserRoutes: RouteObject[] = [
	{
		path: "users",
		element: <User />,
	},
];

export default UserRoutes;
