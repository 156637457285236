import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FastField, FastFieldProps } from "formik";
const LoginForm = ({ onSubmit, isSubmitting = false }: { onSubmit: (e: React.FormEvent<HTMLFormElement>) => void, isSubmitting?: boolean }) => {
    return (
        <Box component={"form"} onSubmit={onSubmit} flexDirection={"column"} gap={2}>
            <FastField name={`email`}>
                {({ field, meta }: FastFieldProps<string>) => (
                    <>
                        <TextField
                            {...field}
                            type="text"
                            label="Email"
                            variant="standard"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                        />
                    </>
                )}
            </FastField>
            <FastField name={`password`}>
                {({ field, meta }: FastFieldProps<string>) => (
                    <>
                        <TextField
                            {...field}
                            type="password"
                            label="Password"
                            variant="standard"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LockOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                        />
                    </>
                )}
            </FastField>
            <Box>
                <Button fullWidth variant="contained" sx={{ mt: 8 }} disabled={isSubmitting} type="submit">
                    Masuk
                </Button>
            </Box>
        </Box>
    )
}

export default LoginForm