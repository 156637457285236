import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart as RechartsRadar, ResponsiveContainer } from "recharts";
import { Small } from "../../../../components/display/typography";
import { RadarChartData, RadarKey } from "../../../../utils/types/dashboard";
import { chartConfig } from "./data";
type RadarChartProps = {
	data: RadarChartData[] | undefined;
	radarKey: RadarKey[];
};
const RadarChart = ({ data, radarKey }: RadarChartProps) => {
	return data && data.length > 2 ? (
		<ResponsiveContainer {...chartConfig.responsiveContainer}>
			<RechartsRadar outerRadius={90} data={data}>
				<PolarGrid />
				<PolarAngleAxis dataKey="subject" />
				<PolarRadiusAxis {...chartConfig.polarRadiusAxis} />
				{radarKey.map((r, id) => (
					<Radar name={r.name} dataKey={r.dataKey} stroke={r.stroke} fill={r.fill} fillOpacity={r.fillOpacity} key={id} />
				))}
				<Legend />
			</RechartsRadar>
		</ResponsiveContainer>
	) : (
		<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column", bgcolor: grey[100], borderRadius: 3 }}>
			<div>Radar Chart</div>
			<div><Small>Data tidak cukup</Small></div>
		</Box>
	);
};

export default RadarChart;
