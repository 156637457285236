import { Outlet, RouteObject } from "react-router-dom";
import DateTimeLuxonAdapter from "../../components/common/Wrapper/DateTimeLuxonAdapter";
import Client from "../../pages/clients/route/page";
import CreateClient from "../../pages/clients/route/create/page";
import EditClient from "../../pages/clients/route/edit/page";
const ClientRotues: RouteObject[] = [
	{
		path: "clients",
		element: (
			<DateTimeLuxonAdapter>
				<Outlet />
			</DateTimeLuxonAdapter>
		),
		children: [
			{
				path: "/clients",
				element: <Client />,
			},
			{
				path: "/clients/create",
				element: <CreateClient />,
			},
			{
				path: "/clients/edit/:id",
				element: <EditClient />,
			},
		],
	},
];

export default ClientRotues;
