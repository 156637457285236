import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import ListItem from "@mui/material/ListItem";
import { useContext } from "react";
import { ProjectContext } from "../../../../../../../context/ProjectContext";
import { AlphabeticId } from "../../../../../../../lib/utils/helper";
import Legend from "../../../../../../components/common/Legend";
import { Paper } from "../../../../../../components/display/paper/Paper";
import { H2, H3 } from "../../../../../../components/display/typography";
import ListBullet from "../../../../components/List/Bullet";
import ListLine from "../../../../components/List/Line";
import { QuestionPracticeDiscussionTabProps } from "../../../../components/props";
import ActivityRepeater from "./repeater/ActivityRepeater";
// import QuestionRepeater from "./QuestionRepeater";
const PracticeTab = ({ data, process_id, levelId, assessmentId, criteriaId }: QuestionPracticeDiscussionTabProps) => {
	const { state: { selectedProject: clientId } } = useContext(ProjectContext);
	return (
		<Paper sx={{ minHeight: "600px" }}>
			<H2>Discussions <Chip label={data.discussions.length} size="small" color="error" sx={{ fontWeight: 400 }} /></H2>
			<Legend />
			{data.discussions.map((discussion, id) => (
				<Box mb={2} key={`discussion-${id}`}>
					<ListItem
						sx={{
							borderRadius: "1rem",
							padding: "1rem .5rem",
							"& .MuiListItemAvatar-root": {
								minWidth: 0,
								marginRight: "1rem",
							},
						}}
					>
						<ListBullet size="normal" completed={true} text={AlphabeticId[id].toLowerCase()} />
						<H3>{discussion.discussion}</H3>
					</ListItem>
					<Box display={"flex"}>
						<ListLine sx={{ mr: 2 }} />
						<Box p={2} borderRadius={2} border={`1px solid ${grey[200]}`} width={"100%"}>
							<ActivityRepeater 
								data={discussion.activities} 
								process_id={process_id}
								levelId={levelId}
								assessmentId={assessmentId}
								criteriaId={criteriaId} 
								clientId={clientId}
							/>
						</Box>
					</Box>
				</Box>
			))}
		</Paper>
	);
};

export default PracticeTab;
