import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import colors from "../../../../../lib/themes/colors";

export const BrandTitle = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
	fontSize: "1.5rem",
	lineHeight: "1rem",
	color: theme.palette.sv.main,
}));

export const BrandSubTitle = styled(Typography)(() => ({
	color: colors.secondary[3],
}));
