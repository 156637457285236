import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { FastField, FastFieldProps } from "formik";
import { memo, useMemo } from "react";
import { renderLevelChip } from "../../../../../../../../lib/utils/helper";
import ToggleButtonGroupField from "../../../../../../../components/common/Form/ToggleButtonGroupField";
import { H5, P, Small } from "../../../../../../../components/display/typography";
import ListBullet from "../../../../../components/List/Bullet";
import { ListItemQuestion } from "../../../../../components/List/Item";
import { ListLineSmallRepeater } from "../../../../../components/List/Line";
import { ReviewActivityItemProps } from "../../../../../components/props";
import CommentList from "./CommentList2";
import EvidenceList from "./EvidenceList2";
import ReviewedAssessment from "./ReviewedAssessment";
const ActivityItem = ({ id, data, client_id = "", process_id = "" }: ReviewActivityItemProps) => {
	const current_activity = useMemo(() => data, [data])
	const activity = useMemo(() => {
		const alternatives = current_activity.activity_alternatives;
		if (alternatives.length && alternatives[0]) {
			return alternatives[0].use_default ? current_activity.activity : alternatives[0].activity;
		} else {
			return current_activity.activity;
		}
	}, [current_activity])
	const isResponded = useMemo(() => current_activity.response[0] ? true : false, [current_activity])
	return (
		<ListLineSmallRepeater className="question-line" flexWrap={"wrap"}>
			<ListItemQuestion
				alignItems="flex-start"
				sx={{
					"& .MuiListItemAvatar-root": { mt: 0 },
					width: "100%"
				}}
			>
				<ListBullet size="small" completed={current_activity.filled} text={(id + 1).toString()} />
				<ListItemText sx={{ m: 0 }} primary={
					<P style={{ margin: "0" }}>
						{activity}
					</P>
				} />
			</ListItemQuestion>
			<Box flexGrow={1} ml={"32px"}>
				<Grid container spacing={2} py={2}>
					<Grid item md={6}>
						<Stack spacing={2}>
							<Box>{renderLevelChip(isResponded ? current_activity.response[0].response : undefined, 0)}</Box>
							<FastField name={`activities.${id}.previous_capability_level_reference`}>
								{({ field }: FastFieldProps<number>) => (
									<>
										<TextField
											{...field}
											autoComplete="off"
											type="number"
											label="Previous Capability Level Reference"
											value={field.value ? field.value : ""}
											fullWidth
											variant="filled"
											disabled
										/>
									</>
								)}
							</FastField>
							<FastField name={`activities.${id}.target_capability_level_reference`}>
								{({ field }: FastFieldProps<number>) => (
									<>
										<TextField
											{...field}
											autoComplete="off"
											type="number"
											label="Target Capability Level References"
											value={field.value ? field.value : ""}
											fullWidth
											variant="filled"
											disabled
										/>
									</>
								)}
							</FastField>
							<Box>
								<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Criteria are met?</Small>
								{isResponded ? (
									<ToggleButtonGroupField
										name={`activities.${id}.response.${0}.criteria_are_met`}
										options={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' }
										]}
										exclusive
										disabled
									/>
								) : <Chip sx={{ mx: 0 }} size="small" label="Penilaian Kosong" />}
							</Box>
						</Stack>
					</Grid>
					<Grid item md={6}>
						<Box>
							<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Evidence dari User</Small>
							<EvidenceList id={id} response={current_activity.response} />
						</Box>
					</Grid>
					<Grid item md={12}>
						<Box sx={{ border: `1px solid ${grey[300]}`, borderRadius: 2, p: 2 }}>
							<Grid container spacing={3}>
								<Grid item md={12}>
									<H5 style={{ margin: 0 }}>Reviewed Assessment</H5>
								</Grid>
								<Grid item md={6}>
									<ReviewedAssessment id={id} process_id={process_id} client_id={client_id} activity_id={current_activity.id} reviewed_assessment={current_activity.reviewed_assessment} />
								</Grid>
								<Grid item md={6}>
									<Box>
										<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Komentar dari PM</Small>
										<CommentList id={id} response={current_activity.response} />
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</ListLineSmallRepeater>
	);
};

export default memo(ActivityItem);

