import MockAdapter from "axios-mock-adapter";
import { users as data, getUser } from "./data";

const users = (mock: MockAdapter) => {
	mock.onGet("/api/users").reply(200, data);
	mock.onGet("/api/users/cb8002d3-0519-4e1a-bff4-22d9b4ff00b0").reply(200, getUser('cb8002d3-0519-4e1a-bff4-22d9b4ff00b0'));
	return mock;
};

export default users;
