import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "../pages/login/route/page";
import AuthLayout from "../components/layouts/AuthLayout";
import ErrorPage from "./error-pages";
import ProtectedRoute from "./ProtectedRoutes";
import ClientRotues from "./routes/client.routes";
import DashboardRoute from "./routes/dashboard.routes";
import EvaluationRoutes from "./routes/evaluation.routes";
import QuestionnaireRoutes from "./routes/questionnaire.routes";
import UserRoutes from "./routes/user.routes";
// Providers
import { AxiosResponse } from "axios";
import { UserProvider } from "../../context/UserContext";
import { ProjectProvider } from "../../context/ProjectContext";
// Types
export type Defer = {
	promise: Promise<AxiosResponse>;
};

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<UserProvider>
				<ProjectProvider>
					<ProtectedRoute />
				</ProjectProvider>
			</UserProvider>
		),
		errorElement: <ErrorPage />,
		children: [
			{path: "/", element: <Navigate to="/dashboard" replace />},
			...DashboardRoute, ...EvaluationRoutes, ...QuestionnaireRoutes, ...UserRoutes, ...ClientRotues
		],
	},
	{
		path: "login",
		element: <AuthLayout />,
		children: [
			{
				index: true,
				element: <Login />,
			},
		],
	},
]);

export default router;
