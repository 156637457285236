import MockAdapter from "axios-mock-adapter";
import { data, getEvaluationById } from "./data";

const evaluations = (mock: MockAdapter) => {
	mock.onGet("/api/evaluations").reply(200, data);
	mock.onGet(/\/api\/evaluations\/\d+/).reply(config => {
		if(config && config.url){
			// cache
			if (localStorage.getItem(config.url)) {
				const localData = localStorage.getItem(config.url);
				return [200, localData];
			}

			// fetch
			const evaluation_id = config.url.split('/').pop();
			if(evaluation_id){
				localStorage.setItem(
					config.url,
					JSON.stringify(getEvaluationById(evaluation_id))
				);
				return [200, getEvaluationById(evaluation_id)];
			}else{
				return [404, { message: 'Evaluation not found' }];
			}
		}
		return [200, {}];
	});
	return mock;
};

export default evaluations;
