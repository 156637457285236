import { AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";
import api from "../lib/axios/axios";
import { AuthenticateResponse, ReactAuthKitData } from "../services/types/auth";
export const processAuth = (res: AxiosResponse<AuthenticateResponse>): ReactAuthKitData => {
	// Decode the token
	const userState = jwtDecode<DecodedToken>(res.data.token);
	// Set the user state and token for react-auth-kit
	const data = {
		auth: {
			token: res.data.token,
			type: "Bearer",
		},
		userState,
	};
	// Set the token in the axios default header
	api.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
	return data;
};
