import styled from 'styled-components'

export const P = styled.p`
    font-size: 1rem;
    color: #000;
    letter-spacing: .4px;
`

export const Small = styled.small`
    font-size: 0.875rem;
    color: #999999;
    letter-spacing: .4px;
`
export const H1 = styled.h1`
    font-size: 1.625rem;
    color: #000;
    letter-spacing: .4px;
`
export const H2 = styled.h2`
    font-size: 1.375rem;
    color: #000;
    letter-spacing: .4px;
`
export const H3 = styled.h3`
    font-size: 1.25rem;
    color: #000;
    letter-spacing: .4px;
`
export const H4 = styled.h4`
    font-size: 1.125rem;
    color: #000;
    letter-spacing: .4px;
`
export const H5 = styled.h5`
    font-size: 1rem;
    color: #000;
    letter-spacing: .4px;
`