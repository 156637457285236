import api from "../lib/axios/axios";
import { UserResponse } from "./types/user";
import { Response } from "../types/response";
import { UserProject } from "./types/client";
export const fetchUsers = async (): Promise<UserResponse[]> => {
	const res = await api.get<Response<UserResponse[]>>(`/users`);
	return res.data.data;
};

export const fetchUserProject = async (userId: string): Promise<UserProject[]> => {
	const res = await api.get<Response<UserProject[]>>(`/users/${userId}/projects`);
	return res.data.data;
};

export const createUser = async (data: FormData): Promise<UserResponse> => {
	const res = await api.post<Response<UserResponse>>(`/users`, data);
	return res.data.data;
};

export const editUser = async (id: string, data: FormData): Promise<UserResponse> => {
	const res = await api.put<Response<UserResponse>>(`/users/${id}`, data);
	return res.data.data;
};