import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "@mui/material";
import BreadcrumbsMUI from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import colors from "../../../../lib/themes/colors";
export type Crumbles = {
	text: string;
	path: string;
};
type BreadcrumbsProps = {
	content: Crumbles[];
	loading?: boolean;
};
const Breadcrumbs = ({ content, loading = false }: BreadcrumbsProps) => {
	const crumbles = content.map((crumb, _) => {
		if(loading) return <Skeleton key={crumb.text} variant="text" sx={{ marginRight: "0.25rem", fontSize: "0.875rem", width: `${crumb.text.length}ch` }} />
		if (crumb.path === "/" || crumb.path === "#") {
			return (
				<Link key={crumb.text} underline="none" color={colors.secondary[0]} fontSize={"0.875rem"}>
					{crumb.text}
				</Link>
			);
		} else {
			return (
				<Link key={crumb.text} underline="none" component={NavLink} to={crumb.path} fontSize={"0.875rem"}>
					{crumb.text}
				</Link>
			);
		}
	});
	return (
		<BreadcrumbsMUI separator={<NavigateNextIcon sx={{ fontSize: "0.875rem", marginTop: ".2rem" }} />} aria-label="breadcrumb">
			{crumbles}
		</BreadcrumbsMUI>
	);
};

export default Breadcrumbs;
