import { List } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import { Form, Formik } from "formik";
import { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useEditActivityComments } from "../../../../../../../../hooks/query/activities/useEditActivityComments";
import activityFormik from "../../../../../../../../lib/formik/activity";
import { AlphabeticId } from "../../../../../../../../lib/utils/helper";
import { EditActivities } from "../../../../../../../../services/types/process";
import { Context } from "../../../../../../../../types/response";
import { H4 } from "../../../../../../../components/display/typography";
import { IconBullet } from "../../../../../components/List/Bullet";
import { ReviewDiscussionActivityProps } from "../../../../../components/props";
import ActivityItem from "./ActivityItem";
const ActivityList = ({ data, discussion_order, discussion, criteria_id, client_id }: ReviewDiscussionActivityProps) => {
	const { domain = "", processId = "", processLevel = "", assessmentId = "" } = useParams<{ domain: string, processId: string, processLevel: string, assessmentId: string }>();
	const context = JSON.parse(localStorage.getItem("context") || "{}") as Context;
	const { mutate: editActivityComments } = useEditActivityComments(client_id, {
		process_id: `${domain.toUpperCase()}${processId.padStart(2, "0")}`,
		levelID: processLevel,
		assessmentID: assessmentId,
	});
	const initialValues = useMemo(() => ({
		...activityFormik.fullEdit.initialValues,
		activities: data.activities.map((activity) => ({
			...activity,
			previous_capability_level_reference: activity.previous_capability_level_reference ?
				activity.previous_capability_level_reference :
				context.process_previous_target,
			target_capability_level_reference: activity.target_capability_level_reference ?
				activity.target_capability_level_reference :
				context.process_target,
		})),
	}), [data.activities, context]);
	const handleSubmit = useCallback((values: EditActivities, setSubmitting: (a: boolean) => void) => {
		const modifiedValues = values.activities.map(activity => activity);
		editActivityComments(modifiedValues, {
			onSuccess: () => {
				setSubmitting(false);
			},
		}
		);
	}, []);
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={activityFormik.fullEdit.validationSchema}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
			enableReinitialize={true}
		>
			{({ values, dirty, isValid }) => (
				<Form style={{ marginBottom: "64px", paddingBottom: "32px", borderBottom: `1px solid ${grey[300]}` }}>
					<Box display={"flex"} alignItems={"center"} gap={2}>
						<IconBullet size="normal" completed={data.filled} text={AlphabeticId[discussion_order].toLowerCase()} />
						<H4>{discussion}</H4>
						{dirty ? !isValid ? <Chip label="Validation Error" size="small" color="error" /> : <Chip label="Unsaved" size="small" color="warning" /> : <Chip label="Saved" size="small" />}
					</Box>
					<List
						sx={{
							"& .MuiBox-root": {
								"&.question-line": {
									pb: 2,
									"&:last-of-type": {
										pb: 0,
										"&::before": {
											width: 0,
										},
									},
								},
							},
						}}
					>
						{values.activities.map((activity, id) => (
							<ActivityItem key={`activity-${id}`} id={id} data={activity} process_id={context.process_id}
								client_id={client_id} />
						))}
					</List>
					<Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} gap={2}>
						{dirty ? !isValid ? <Chip label="Validation Error" size="small" color="error" /> : <Chip label="Unsaved" size="small" color="warning" /> : <Chip label="Saved" size="small" />}
						<Button variant="contained" type="submit" disabled={!dirty}>
							Simpan
						</Button>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default memo(ActivityList);
