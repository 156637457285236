import Chip from '@mui/material/Chip';
import { LevelObjectData, RadarAggregatedSummary, RadarDataArray } from "../../app/utils/types/dashboard";
import { AgreedCapability } from "../../services/types/client";
import { isKeyOfResponseDashboard, ResponseDashboard } from "../../services/types/response";

export function a11yProps(index: number | string, label: string, ariaLabel: string) {
	return {
		id: `${label}-${index}`,
		"aria-controls": `${ariaLabel}-${index}`,
	};
}

export const parsePrecision = (float: number): number => {
	return parseFloat(float.toFixed(2));
};

export const calculateAverage = (obj: Record<string, number>): number => {
	const keys = Object.keys(obj).filter((key) => !key.startsWith("subject"));
	const sum = keys.reduce((total, key) => total + obj[key], 0);
	return parsePrecision(sum / keys.length);
};

export const generateLevelData = (originalData: ResponseDashboard[]): LevelObjectData => {
	const keys = originalData.map((d) => d.process_id.toLowerCase());
	const levelData = [];
	const processLevelData = [];
	const levelKeys = ["level_0", "level_1", "level_2", "level_3", "level_4", "level_5"];

	for (let i = 0; i < levelKeys.length; i++) {
		const levelKey = levelKeys[i];
		const levelItem: any = {
			subject: levelKey.replace("level_", "Level "),
		};

		for (const process of keys) {
			const value = originalData.find((item) => item.process_id === process.toUpperCase());
			if (isKeyOfResponseDashboard(levelKey, value)) {
				if (value) {
					levelItem[process] = parsePrecision(value[levelKey] as number);
				}
			}
		}
		levelItem.average = calculateAverage(levelItem);
		if (!isNaN(levelItem.average)) {
		  levelData.push(levelItem);
		}
	}

	for (let i = 0; i < keys.length; i++) {
		const process = keys[i].toUpperCase();
		const processItem: any = {
			subject: process,
		};

		for (const level of levelKeys) {
			const value = originalData[i];
			if (isKeyOfResponseDashboard(level, value)) {
				if (value) {
					processItem[level] = parsePrecision(value[level] as number);
				}
			}
		}
		processItem.average = calculateAverage(processItem);
		processLevelData.push(processItem);
	}
	return {
		summary: levelData,
		process: processLevelData,
	};
};

export const generateSummaryData = (data: ResponseDashboard[], identifiers: string[], process: boolean = true) => {
	const sum: Record<string, RadarDataArray> = {};
	const summ: RadarAggregatedSummary = {};

	identifiers.forEach((identifier) => {
		sum[identifier] = { maturity: [], agreed: [] };
		summ[identifier] = { maturity: 0, agreed: 0 };
	});

	data.forEach((d) => {
		const { domain, process_id, agreed_target, summary } = d;
		if (process) {
			if (sum[domain]) {
				sum[domain].maturity.push(summary);
				sum[domain].agreed.push(agreed_target);
			}
		} else {
			if (sum[process_id]) {
				sum[process_id].maturity.push(summary);
				sum[process_id].agreed.push(agreed_target);
			}
		}
	});

	[summ].forEach((result) => {
		identifiers.forEach((identifier) => {
			const { maturity, agreed } = sum[identifier];
			result[identifier] = {
				maturity: maturity.reduce((prev, curr) => prev + curr, 0) / maturity.length,
				agreed: agreed.reduce((prev, curr) => prev + curr, 0) / agreed.length,
			};
		});
	});

	return identifiers.map((identifier) => ({ subject: identifier, ...summ[identifier] }));
};

export const getDomainDescription = (domain: string) => {
	switch (domain) {
		case "EDM":
			return "(Evaluate, Direct and Monitor)";
		case "APO":
			return "(Align, Plan and Organise)";
		case "BAI":
			return "(Build, Acquire and Implement)";
		case "DSS":
			return "(Deliver, Service and Support)";
		case "MEA":
			return "(Monitor, Evaluate and Assess)";
		default:
			return "UNDEFINED";
	}
};

export const AlphabeticId: { [key: number]: string } = {
	0: "A",
	1: "B",
	2: "C",
	3: "D",
	4: "E",
	5: "F",
	6: "G",
	7: "H",
	8: "I",
	9: "J",
	10: "K",
	11: "L",
	12: "M",
	13: "N",
	14: "O",
	15: "P",
	16: "Q",
	17: "R",
	18: "S",
	19: "T",
	20: "U",
	21: "V",
	22: "W",
	23: "X",
	24: "Y",
	25: "Z",
};

export const renderLevelChip = (evaluation: number | undefined, spacing: number = 1, text: string = "") => {
	switch (evaluation) {
		case 0:
			return <Chip sx={{ mx: spacing }} size="small" label="Not Achieved" />;
		case 1:
			return <Chip sx={{ mx: spacing, bgcolor: "#C4BD97" }} size="small" label="Partially Achieved" />;
		case 2:
			return <Chip sx={{ mx: spacing, bgcolor: "#C6D9F0" }} size="small" label="Largely Achieved" />;
		case 3:
			return <Chip sx={{ mx: spacing, bgcolor: "#6FA0DB" }} size="small" label="Fully Achieved" />;
		case undefined:
			return <Chip sx={{ mx: spacing }} size="small" label="Penilaian Kosong" />;
		default:
			return <Chip sx={{ mx: spacing }} size="small" label={text} />;
	}
};

// Function to extract the numeric part from the process field
const getProcessNumber = (process: string) => {
	const match = process.match(/\d+/); // Extract the number part from the process string
	return match ? parseInt(match[0], 10) : 0; // Convert to integer
};

export const sortAgreedCapabilities = (data: AgreedCapability[]) => {
	const domainOrder = ["edm", "apo", "bai", "dss", "mea"];
	// Sorting function
	return data.sort((a, b) => {
		// Compare domains based on priority
		const domainComparison = domainOrder.indexOf(a.domain) - domainOrder.indexOf(b.domain);

		if (domainComparison !== 0) {
			return domainComparison;
		}

		// If domains are the same, compare processes based on their numeric value
		return getProcessNumber(a.process) - getProcessNumber(b.process);
	});
}