import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Small } from "../../../../components/display/typography";
import { LevelChartData, LevelKey, LevelProcessData } from "../../../../utils/types/dashboard";
import { chartConfig } from "./data";
type LevelChartProps = {
	data: LevelChartData[] | LevelProcessData[] | undefined;
	levelKey: LevelKey[];
	xAxis: string;
};
const BreakdownChart = ({ data, levelKey, xAxis = "name" }: LevelChartProps) => {
	return data && data.length > 0 ? (
		<ResponsiveContainer {...chartConfig.responsiveContainer}>
			<ComposedChart data={data}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={xAxis} />
				<YAxis />
				<Tooltip />
				<Legend />
				{levelKey.map((g, id) => (
					<Bar key={id} label={{ fill: "#000", fontSize: 12, angle: 90 }} {...g} />
				))}
				<Line name="Average" type="monotone" dataKey="average" stroke="#000" strokeWidth={3} />
			</ComposedChart>

		</ResponsiveContainer>
	) : (
		<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, flexDirection: "column", bgcolor: grey[100], borderRadius: 3 }}>
			<div>Breakdown Level Chart</div>
			<div><Small>Data tidak cukup</Small></div>
		</Box>
	);
};

export default BreakdownChart;
