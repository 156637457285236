import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchProcessLevelAssessment } from "../../../services/process";
import { Assessment } from "../../../services/types/process";
export const useFetchProcessLevelAssessment = (process_id: string, levelID:string, assessmentID:string, clientID:string) => {
	return useSuspenseQuery({ queryKey: ["process/level/assessment", process_id, levelID, assessmentID, clientID], queryFn: () => clientID ? fetchProcessLevelAssessment(process_id, levelID ,assessmentID, clientID):Promise.resolve({
		ID: "",
		order: NaN,
		assessment: "",
		description: "",
		note: "",
		filled: false,
		criterias: [],
	} as Assessment) });
};
