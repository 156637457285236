import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useCreateClient } from "../../../../../../hooks/query/clients/useCreateClient";
import clientFormik from "../../../../../../lib/formik/client";
import { Paper } from "../../../../../components/display/paper/Paper";
import SubHeader from "../../../components/subheader";
import StepComponent from './step';

const steps = ['Data Client', 'Person In Charge', 'Process Group', 'Target EDM', 'Target APO', 'Target BAI', 'Target DSS', 'Target MEA', 'Validasi'];
const CreateClientPage = () => {
    const navigate = useNavigate();
    const { mutate: createClient } = useCreateClient();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const formik = useFormik({
        initialValues: clientFormik.create.initialValues(),
        validationSchema: clientFormik.create.validationSchema,
        onSubmit: (values) => {
            createClient(values, {
                onError: (error) => {
                    console.error(error);
                },
                onSuccess: () => {
                    navigate("/clients");
                }
            });
        }
    });

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <SubHeader title="Tambah Client" />
            </Grid>
            <Grid item md={12}>
                <Paper>
                    <FormikProvider value={formik}>
                        <Box component={"form"} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            const labelProps: {
                                                optional?: React.ReactNode;
                                            } = {};
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Grid>
                                <Grid item md={12}>
                                    <StepComponent step={activeStep} context={formik} isValid={formik.isValid} >
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 8 }}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                                type='button'
                                            >
                                                Kembali
                                            </Button>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleNext} disabled={activeStep === 8 && !formik.isValid}>
                                                Selanjutnya
                                            </Button>
                                        </Box>
                                    </StepComponent>
                                </Grid>
                            </Grid>
                        </Box>
                    </FormikProvider>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CreateClientPage;
