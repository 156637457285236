import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { editActivityResponses } from "../../../services/activity";
import { EditActivityWithoutResponse } from "../../../services/types/process";
export const useEditActivityResponses = (clientID: string, queryContext: { process_id: string; levelID: string; assessmentID: string }) => {
	return useMutation({
		mutationFn: (data: EditActivityWithoutResponse[]) => {
			return editActivityResponses(data, clientID);
		},
		onSuccess: () => Promise.all([
			queryClient.invalidateQueries({
				queryKey: [
					"process/level/assessment", 
					queryContext.process_id, 
					queryContext.levelID, 
					queryContext.assessmentID, 
					clientID],
			}),
			queryClient.invalidateQueries({
				queryKey: [
					"process", 
					queryContext.process_id, 
					queryContext.levelID
				],
			}),
			queryClient.invalidateQueries({
				queryKey: [
					"dashboard", 
					clientID
				],
			})
		]),
		onError: (error) => {
			console.error(error);
		},
	});
};
