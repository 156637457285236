import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { Paper } from "../../components/display/paper/Paper";
const Loading = () => {
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Paper sx={{ py: "1rem" }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item>
                            <Skeleton sx={{ fontSize: "1.375rem" }} variant="rounded" width="15ch" />
                        </Grid>
                        <Grid item md>
                            <Skeleton sx={{ borderRadius: "1rem" }} variant="rounded" width={600} height={48} />
                        </Grid>
                        <Grid item display={"flex"} alignItems={"center"}>
                            <Skeleton variant="rounded" sx={{ height: 40, width: 97 }} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={12}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Paper sx={{ height: 600 }}>
                            <Box sx={{ minHeight: "120px" }}>
                                <Skeleton sx={{ fontSize: "1.375rem", my: "0.83em" }} variant="text" width="30ch" />
                                <Skeleton sx={{ fontSize: "1rem" }} variant="text" width="60ch" />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={12}>
                        <Paper sx={{ height: 600 }}>
                            <Skeleton sx={{ fontSize: "1.375rem", my: "0.83em" }} variant="text" width="30ch" />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Loading;