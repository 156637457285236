import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import assessments from "../mock/assessments";
import users from "../mock/users";
import evaluations from "../mock/evaluations";
import clients from "../mock/clients";
import questionnaires from "../mock/questionnaires";
import { AxiosError } from "axios";

// Create an axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

// Add a request interceptor
api.interceptors.request.use(config => {
    const token = localStorage.getItem('_auth');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error: AxiosError) => {
    return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Create a custom error to include the message
        const errorMessage = error.response?.data?.message || 'An error occurred';
        return Promise.reject(new Error(errorMessage));
    }
);

export const mockApi = axios.create({
    baseURL: "http://localhost:3000",
    headers: {
        "Content-Type": "application/json",
    }
});
const mock = new MockAdapter(mockApi, { delayResponse: 1000 });
assessments(mock)
users(mock)
clients(mock)
evaluations(mock)
questionnaires(mock)

export default api