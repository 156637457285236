import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { SxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import { CSSProperties, ReactNode } from "react";
import { AvatarProps } from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
type ListBulletProps = {
	size: "small" | "normal";
	completed: boolean;
	text?: string;
	withLine?: boolean;
} & SxProps;
type BulletProps = {
	size: "small" | "normal";
	completed: boolean;
	text?: string;
} & AvatarProps;
const Bullet = ({ size, completed, children, ...other }: BulletProps) => {
	let bulletStyle: CSSProperties = {
		backgroundColor: "auto",
		fontSize: "1rem",
		width: "2rem",
		height: "2rem",
	};
	const StyledAvatar = styled(Avatar)(({ theme }) => {
		if (completed) {
			bulletStyle.backgroundColor = theme.palette.primary.main;
		}

		if (size === "small") {
			bulletStyle.fontSize = "0.75rem";
			bulletStyle.width = "1rem";
			bulletStyle.height = "1rem";
		}

		return {
			...bulletStyle,
		};
	});
	return <StyledAvatar {...other}>{children}</StyledAvatar>;
};

export const IconBullet = ({ size = "normal", completed = false, text, ...other }: ListBulletProps) => {
	const renderContent = (): ReactNode => {
		if (completed && text) {
			return <>{text}</>;
		} else if (completed) {
			return <CheckIcon sx={{ fontSize: "inherit" }} />;
		} else {
			return <>{text}</>;
		}
	};
	return (
		<Bullet size={size} completed={completed}>
			{renderContent()}
		</Bullet>
	);
};

const ListBullet = ({ size = "normal", completed = false, withLine = false, text, ...other }: ListBulletProps) => {
	// const completedStyle:SxProps = completed ? {}
	const renderContent = (): ReactNode => {
		if (completed && text) {
			return <>{text}</>;
		} else if (completed) {
			return <CheckIcon sx={{ fontSize: "inherit" }} />;
		} else {
			return <>{text}</>;
		}
	};

	return (
		<ListItemAvatar>
			<Bullet size={size} completed={completed}>
				{renderContent()}
			</Bullet>
		</ListItemAvatar>
	);
};

export default ListBullet;
