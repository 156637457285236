import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { Suspense } from "react";
import Role, { ROLE } from "../../../../../constants/role";
import { useFetchClients } from "../../../../../hooks/query/clients/useFetchClients";
import { useEditUser } from "../../../../../hooks/query/users/useEditUser";
import userFormik from "../../../../../lib/formik/user";
import { H3, H4 } from "../../../../components/display/typography";
import { EditUserProps } from "../../components/props";

const EditUser = ({ selected, open, toggle }: EditUserProps) => {
	const { mutate: editUser } = useEditUser();
	const { data: projects } = useFetchClients();

	const handleClose = () => {
		toggle(false)();
		formik.resetForm();
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...userFormik.edit.initialValues,
			id: selected?.id as string,
			email: selected?.email as string,
			name: selected?.name as string,
			role: selected?.role as Role,
			description: selected?.description as string,
			assigned_projects: selected?.assigned_projects.map((project) => ({ client: project.client, id: project.id })) as AutocompleteOption[],
		},
		validationSchema: userFormik.edit.validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values) => {
			const data = new FormData();
			Object.keys(values).forEach((key) => {
				if (userFormik.edit.isKeyOf(key, values)) {
					if (key === "assigned_projects") {
						if (values[key].length === 0) return;
						values[key].forEach((project, id) => {
							data.append(`[${key}]${id}[client]`, project.client);
							data.append(`[${key}]${id}[id]`, project.id);
						});
					} else {
						data.append(key, values[key]);
					}
				}
			});

			// call mutation
			editUser(data, {
				onError: (error) => {
					formik.setSubmitting(false);
					console.error(error);
				},
				onSuccess: () => {
					handleClose()
				}
			});
		}
	});

	type AutocompleteOption = {
		client: string;
		id: string;
	}

	const options: AutocompleteOption[] = projects.map((project) => ({
		client: project.client,
		id: project.id
	})) || [];

	return (
		<Suspense fallback={<>Loading...</>}>
			<Drawer open={open} onClose={() => handleClose()} anchor="right">
				<Box sx={{ minWidth: 400 }} flexDirection={"column"} display={"flex"} justifyContent={"space-between"}>
					<Box px={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
						<H3>Edit User</H3>
						<IconButton aria-label="close-drawer" onClick={() => handleClose()}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box component={"form"} onSubmit={formik.handleSubmit} px={2}>
						<Stack spacing={2}>
							<H4>Kredensial User</H4>
							<TextField
								autoFocus
								autoComplete="off"
								id="email"
								name="email"
								type="email"
								label="Email"
								variant="outlined"
								value={formik.values.email}
								onChange={formik.handleChange}
								error={Boolean(formik.errors.email)}
								helperText={formik.errors.email}
							/>
							<TextField
								id="name"
								name="name"
								type="text"
								label="Name"
								variant="outlined"
								value={formik.values.name}
								onChange={formik.handleChange}
								error={Boolean(formik.errors.name)}
								helperText={formik.errors.name}
							/>
							<TextField
								id="role"
								name="role"
								select
								label="Role"
								variant="outlined"
								value={formik.values.role}
								onChange={formik.handleChange}
								error={Boolean(formik.errors.role)}
								helperText={formik.errors.role}
							>
								{ROLE.map((role, id) => (
									<MenuItem value={role} key={id}>
										{role}
									</MenuItem>
								))}
							</TextField>
							<TextField
								id="description"
								name="description"
								type="text"
								label="Role Description"
								variant="outlined"
								value={formik.values.description}
								onChange={formik.handleChange}
								error={Boolean(formik.errors.description)}
								helperText={formik.errors.description}
							/>
							<H4>Project yang Ditugaskan</H4>
							<Autocomplete
								disablePortal
								options={options}
								value={formik.values.assigned_projects}
								multiple
								onChange={(_, value) => formik.setFieldValue("assigned_projects", value)}
								getOptionLabel={(option: AutocompleteOption) => option.client}
								// getOptionSelected={(option, value) => option.id === value.id}
								renderInput={(params) => (
									<TextField
										{...params}
										id="assigned_projects"
										name="assigned_projects"
										type="text"
										label="Assigned Projects"
										variant="outlined"
									/>
								)}
							/>
						</Stack>
						<Box display={"flex"} justifyContent={"flex-end"}>
							<Button variant="contained" type="submit" sx={{ textTransform: "none", mt: 4 }} disabled={formik.isSubmitting}>
								Simpan
							</Button>
						</Box>
					</Box>
				</Box>
			</Drawer>
		</Suspense>
	);
};

export default EditUser;
