export const size = {
	width: "100%",
	height: 400,
};

export const chartConfig = {
	responsiveContainer: {
		width: "100%",
		height: 450,
	},
	polarRadiusAxis: {
		// angle: -10,
		domain: [1.0, 5.0],
	},
};