import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import menu from "../../../lib/navigations/menu";
import Sidebar from "../navigations/menu/Sidebar";
import { useFetchUserProject } from "../../../hooks/query/users/useFetchUserProject";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { ProjectContext } from "../../../context/ProjectContext";
const drawerWidth = 240;

interface Props {
	window?: () => Window;
}

type InitialIsNavCollapsed = {
	[key: string]: boolean;
};
const initialIsNavCollapsed: InitialIsNavCollapsed = {};
menu.map((item, _) => {
	if (item.child) {
		initialIsNavCollapsed[item.path] = false;
		item.child.map((childItem, _) => {
			if (childItem.child) {
				initialIsNavCollapsed[childItem.path] = false;
			}
			return null;
		});
	}
	return null;
});

export default function AppLayout({ window }: Props) {
	// CONTEXT
	const { state: {selectedProject}, dispatch } = useContext(ProjectContext);
	// HOOKS
	const user = useAuthUser();

	// QUERY
	const { data, isSuccess } = useFetchUserProject(user?.id || "");
	if (isSuccess) {
		if(data.length){
			if(!selectedProject){
				dispatch({ type: "set_project", payload: data[0].id });
			}else{
				const isUserAssignedToProject = data.find((project) => project.id === selectedProject);
				if(!isUserAssignedToProject){
					dispatch({ type: "set_project", payload: data[0].id });
				}
			}
		}
	}
	// STATES
	const [mobileOpen, setMobileOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);

	// FUNCTIONS
	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="navigation menu">
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onTransitionEnd={handleDrawerTransitionEnd}
					onClose={handleDrawerClose}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
				>
					<Sidebar />
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
					open
				>
					<Sidebar />
				</Drawer>
			</Box>
			<Box component="main" sx={{ flexGrow: 1, p: "1rem", width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
				<IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
					<MenuIcon />
				</IconButton>
				{/* {selectedProject ? <Outlet />:null} */}
				<Outlet />
			</Box>
		</Box>
	);
}
