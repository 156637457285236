import { Suspense } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation } from "react-router-dom";
import Unauthenticated from "../../../components/common/Redirect/Unauthenticated";
import Header from "../../../components/navigations/header/Header";
import EvaluationPage from "../route-element/assessor";
import PMEvaluationPage from "../route-element/pm";
import Loading from "./loading";

const Evaluation = () => {
	const authUser = useAuthUser();
	const location = useLocation();
	if (!authUser) return <Unauthenticated />;

	const Page = () => {
		if (authUser.role === "assessor") return <EvaluationPage />;
		if (authUser.role === "pm") return <PMEvaluationPage />;
		if (authUser.role === "admin") return <PMEvaluationPage />;
		else return <>Forbidden</>;
	}

	return <>
		<Header
			title="IT Maturity Based on COBIT 5"
			breadcrumbs={[
				{ text: "Penilaian", path: `${location.pathname}` },
			]}
		/>
		<Suspense fallback={<Loading/>}>
			<Page />
		</Suspense>
	</>;
};

export default Evaluation;
