import Grid from "@mui/material/Grid";
import { Suspense } from "react";
import { useFetchProcessMaturities } from "../../../../../hooks/query/process-maturity/useFetchProcessMaturities";
import ProcessMaturityDataTable from "../../components/datatable";
import SubHeader from "../../components/subheader";
const EvaluationPage = () => {
    // STATES
    const { data } = useFetchProcessMaturities();
    return (
        <Suspense fallback={<>Loading user data...</>}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <SubHeader title="Penilaian" />
                </Grid>
                <Grid item md={12}>
                    <ProcessMaturityDataTable data={data} />
                </Grid>
            </Grid>
        </Suspense>
    );
};

export default EvaluationPage;
