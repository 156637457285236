import api from "../lib/axios/axios";
import { Response } from "../types/response";
import { Activity, EditActivityWithoutResponse, EditActivityAlternative } from "./types/process";

export const editActivityQuestions = async (data: EditActivityWithoutResponse[], clientID: string): Promise<Activity> => {
    const res = await api.put<Response<Activity>>(`/activities/questions/${clientID}`, data);
    return res.data.data;
};

export const editActivityComments = async (data: EditActivityWithoutResponse[], clientID: string): Promise<Activity> => {
    const res = await api.put<Response<Activity>>(`/activities/comments/${clientID}`, data);
    return res.data.data;
};

export const editActivityResponses = async (data: EditActivityWithoutResponse[], clientID: string): Promise<Activity> => {
    const res = await api.put<Response<Activity>>(`/activities/responses/${clientID}`, data);
    return res.data.data;
};

export const editActivityAlternatives = async (data: EditActivityAlternative[], processId:string, clientID: string): Promise<Response> => {
    const res = await api.put<Response>(`/activities/alternative/${processId}/client/${clientID}`, data);
    return res.data.data;
};
