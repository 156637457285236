import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import NavLink from "../../common/Nav/NavLink";
import menu from "../../../../lib/navigations/menu";
import NavChildren from "./NavChildren";
import { ExpandLessIcon, ExpandMoreIcon, ListItemButton, ListItemText } from "./styled";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
type InitialIsNavCollapsed = {
	[key: string]: boolean;
};

// Initiate collapsed state
const initialIsNavCollapsed: InitialIsNavCollapsed = {};
menu.map((item, _) => {
	if (item.child) {
		initialIsNavCollapsed[item.path] = false;
		item.child.map((childItem, _) => {
			if (childItem.child) {
				initialIsNavCollapsed[childItem.path] = false;
			}
			return null;
		});
	}
	return null;
});

const NavMenu = () => {
	const auth = useAuthUser();
	const [isNavCollapsed, setIsNavCollapsed] = useState(initialIsNavCollapsed);
	const selected = localStorage.getItem("selectedMenu");

	useEffect(() => {
		if (selected) {
			const selectedParts = JSON.parse(selected).split("/");
			selectedParts.shift();
			if (selectedParts && selectedParts.length === 2) {
				setIsNavCollapsed(
					produce((draft: InitialIsNavCollapsed) => {
						selectedParts.map((part: string) => {
							if (draft.hasOwnProperty(`/${part}`)) {
								draft[`/${part}`] = true;
							}
						});
					})
				);
			}
		}
	}, [selected]);

	const handleNavCollapsed = (key: string) => {
		setIsNavCollapsed((prevIsNavCollapsed) => ({
			...prevIsNavCollapsed,
			[key]: !prevIsNavCollapsed[key],
		}));
	};
	return (
		<List id="sidebar-nav" component={"nav"} sx={{ py: 0 }}>
			{menu.map((item, _) => {
				if (item.roles?.includes(auth?.role || "user")) {
					return (
						<Box id={`nav-${item.title.toLowerCase()}`} key={`nav-${item.title.toLowerCase()}`}>
							<ListItemButton {...(!item.child ? { component: NavLink, to: item.path } : { onClick: () => handleNavCollapsed(item.path) })} dense>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText primary={item.title} disableTypography />
								{item.child ? isNavCollapsed[item.path] ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
							</ListItemButton>
							<NavChildren item={item} openState={isNavCollapsed} onOpen={handleNavCollapsed} level={1} />
						</Box>
					);
				}
				return null;
			})}
		</List>
	);
};

export default NavMenu;
