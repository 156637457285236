import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { editProcessMaturity } from "../../../services/process-maturity";
import { ProcessMaturity } from "../../../services/types/process-maturity";
export const useEditProcessMaturity = () => {
	return useMutation({
		mutationFn: (data: ProcessMaturity) => {
			return editProcessMaturity(data.id, data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["process-maturities"],
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
