import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import HeaderLoading from "./HeaderLoading";
// import Paper from "@mui/material/Paper";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import { Suspense, useContext, useMemo } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from "../../../../context/ProjectContext";
import queryClient from "../../../../lib/react-query/client";
import { UserProject } from "../../../../services/types/client";
import { Paper } from "../../display/paper/Paper";
import { H1, Small } from "../../display/typography";
import Breadcrumbs, { Crumbles } from "../breadcrumbs/Breadcrumbs";
type HeaderProps = {
	title: string;
	breadcrumbs: Crumbles[];
};

const Header = ({ title, breadcrumbs }: HeaderProps) => {
	// CONTEXTS
	const {
		state: {
			selectedProject
		},
		dispatch
	} = useContext(ProjectContext);

	// HOOKS
	const user = useAuthUser() as DecodedToken;
	const navigate = useNavigate();
	// QUERY
	const projects = useMemo(() => {
		return queryClient.getQueryData<UserProject[]>(["user/projects", user.id]);
	}, [user.id, selectedProject]);

	// FUNCTIONS
	const handleBack = () => {
		navigate(-1);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		dispatch({ type: "set_project", payload: value });
	};

	// VARIABLES
	const assginedProject = useMemo(() => {
		return projects?.find((project) => project.id === selectedProject) || undefined
	}, [projects, selectedProject]);
	const assignedProjects = useMemo(() => {
		return projects?.map((project) => project)
	}, [projects]);

	return (
		<Suspense fallback={<HeaderLoading titleWidth={title.length} breadcrumbs={breadcrumbs} />}>
			<Paper sx={{ mb: "1rem" }} id="header">
				<Grid container spacing={"1rem"} alignItems={"center"}>
					<Grid item>
						<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
							<IconButton aria-label="delete" title="Kembali" onClick={handleBack}>
								<ArrowBackOutlinedIcon />
							</IconButton>
							<H1 style={{ margin: 0 }}>{title}</H1>
							<Divider orientation="vertical" variant="middle" flexItem />
						</Box>
					</Grid>
					<Grid item md>
						<Breadcrumbs content={[{ text: "Home", path: "/" }, ...breadcrumbs]} />
					</Grid>
					<Grid item>
						<Box display="flex" alignItems="center">
							{assginedProject ? (
								<>
									<Box mr={2} display={"flex"} alignItems={"center"}>
										<Small style={{ marginRight: ".25rem" }}>Project date:</Small>
										<Small style={{ color: "#000" }}>
											{DateTime.fromISO(assginedProject.project_start_date.toString()).toFormat("LLL d")} -{" "}
											{DateTime.fromISO(assginedProject.project_end_date.toString()).toFormat("LLL d")}
										</Small>
									</Box>
									<TextField
										id="project"
										name="project"
										label="Project"
										select
										size="small"
										value={selectedProject}
										onChange={handleChange}
									>
										{assignedProjects?.map((project) => (
											<MenuItem value={project.id} key={project.id}>
												{project.client}
											</MenuItem>
										))}
									</TextField>
								</>
							) : (
								<>Tidak ada project.</>
							)}
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Suspense>
	);
};

export default Header;
