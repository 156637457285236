import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import { ProjectContext } from "../../../../../../context/ProjectContext";
import { useFetchProcess } from "../../../../../../hooks/query/processes/useFetchProcess";
import { getDomainDescription } from "../../../../../../lib/utils/helper";
import Legend from "../../../../../components/common/Legend";
import { Paper } from "../../../../../components/display/paper/Paper";
import { H2, P, Small } from "../../../../../components/display/typography";
import ListLevel from "../../../components/List/ListLevel";
import { DomainProcessPageProps } from "../../../components/props";

const DomainProcessPage = ({ domain, processId }: DomainProcessPageProps) => {
	// CONTEXT
	const { state: { selectedProject } } = useContext(ProjectContext);
	const { data } = useFetchProcess(`${domain}${processId}`, selectedProject);
	// Sub-Header Component
	const SubHeader = () => {
		return (
			<Paper sx={{ py: "1rem" }}>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item>
						<Box display={"flex"} alignItems="center">
							<Small style={{ marginRight: "1rem" }}>{`${domain} ${getDomainDescription(domain)}`}</Small>
							<H2 style={{ margin: 0 }}>{`${domain}${processId}`}</H2>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		);
	};

	// Main Component
	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<SubHeader />
			</Grid>
			<Grid item md={12}>
				<Paper>
					{selectedProject && data ? (
						<>
							<H2>{data.process}</H2>
							<P>{data.purpose}</P>
							{!data.open && <Alert sx={{ mb: 2 }} severity="error">
								Lembar kerja ditutup, hubungi Project Manager untuk membuka akses.
							</Alert>}
							<Legend />
							<ListLevel levels={data.levels} type={"questionaires"} open={data.open} />
						</>
					):"Tidak ada project."}
					
				</Paper>
			</Grid>
		</Grid>
	);
};

export default DomainProcessPage;
