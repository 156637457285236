import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams, gridClasses } from "@mui/x-data-grid";
import { ActionButton } from "../../../../components/buttons/ActionButton";
import { Paper } from "../../../../components/display/paper/Paper";
import { DataTableProps } from "../props";
import { renderAssignedProjects, renderUserData, renderUserRole } from "./utils";

const DataTable = ({ data, onEdit }: DataTableProps) => {
	const columns: GridColDef[] = [
		{ field: "name", headerName: "Name", flex: 2, renderCell: renderUserData },
		{ field: "role", headerName: "Role", flex: 1, renderCell: renderUserRole },
		{ field: "assigned_projects", headerName: "Project", flex: 2, renderCell: renderAssignedProjects },
		{
			field: "actions",
			type: "actions",
			width: 150,
			getActions: (params: GridRowParams) => [
				<ActionButton size="small" variant="outlined" startIcon={<EditIcon />} onClick={() => onEdit(params.row.id)}>
					Edit
				</ActionButton>,
			],
		},
	];

	return (
		<Paper>
			<Box width={"100%"} height={600}>
				<DataGrid
					columns={columns}
					rows={data}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 40 },
						},
						density: "comfortable",
						columns: {
							columnVisibilityModel: { type: false },
						},
					}}
					getRowHeight={() => "auto"}
					sx={{
						border: "none",
						[`& .${gridClasses.cell}`]: {
							py: 3,
						},
					}}
					pageSizeOptions={[40]}
					disableRowSelectionOnClick
					disableColumnSelector
				/>
			</Box>
		</Paper>
	);
};

export default DataTable;
