import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import Brand from "../brand/Brand";
import User from "../user/User";
import UserLoading from "../user/UserLoading";
import NavMenu from "./NavMenu";
import NavMenuLoading from "./NavMenuLoading";

const Sidebar = () => {
	const {
		state: { isLoading },
	} = useContext(UserContext);
	return isLoading ? (
		<Box p={"1rem"}>
			<Brand />
			<Divider />
			<UserLoading />
			<NavMenuLoading />
		</Box>
	) : (
		<Box p={"1rem"}>
			<Brand />
			<Divider />
			<User />
			<NavMenu />
		</Box>
	);
};

export default Sidebar;
