import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";
import { AlphabeticId } from "../../../../../../../../lib/utils/helper";
import { ActionButton } from "../../../../../../../components/buttons/ActionButton";
import { H3 } from "../../../../../../../components/display/typography";
import ListBullet from "../../../../../components/List/Bullet";
import ListLine from "../../../../../components/List/Line";
import { LevelCriteriaRepeaterProps } from "../../../../../components/props";
const CriteriaRepeater = ({ data, assessmentID }: LevelCriteriaRepeaterProps) => {
	return (
		<>
			{data.map((criteria, id) => {
				if (criteria.criteria) {
					return (
						<Box mb={2} key={criteria.id}>
							<ListItem
								sx={{
									borderRadius: "1rem",
									padding: "1rem .5rem",
									"& .MuiListItemAvatar-root": {
										minWidth: 0,
										marginRight: "1rem",
									},
								}}
							>
								<ListBullet size="normal" completed={criteria.filled} text={AlphabeticId[id]} />
								<H3>{criteria.criteria}</H3>
							</ListItem>
							<Box display={"flex"}>
								<ListLine sx={{ mr: 2 }} />
								<ActionButton
									sx={{ mx: 1 }}
									variant="outlined"
									startIcon={<EditIcon />}
									{...({ LinkComponent: Link, to: `${assessmentID}/${criteria.id}?criteria=0` })}
								>
									Kelola Pertanyaan
								</ActionButton>
							</Box>
						</Box>
					)
				} else {
					return (
						<Box mb={2} key={criteria.id}>
							<ListItem
								sx={{
									borderRadius: "1rem",
									padding: "1rem .5rem",
									"& .MuiListItemAvatar-root": {
										minWidth: 0,
										marginRight: "1rem",
									},
								}}
							>
								<ListBullet size="normal" completed={criteria.filled} text={AlphabeticId[id]} />
								<ActionButton
									sx={{ mx: 1 }}
									variant="outlined"
									startIcon={<EditIcon />}
									{...({ LinkComponent: Link, to: `${assessmentID}/${criteria.id}` })}
								>
									Kelola Pertanyaan
								</ActionButton>
							</ListItem>
						</Box>
					)
				}
			})}
		</>
	);
};

export default CriteriaRepeater;
