import { useRouteError, useParams, useNavigate } from "react-router-dom";
import { Paper } from "../../../components/display/paper/Paper";
import Button from "@mui/material/Button";
import { H2, P } from "../../../components/display/typography";
import NotFound from "./not-found";
export default function QuestionnaireError() {
  const error:any = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>Questionnaire</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

type ProcessErrorParams = {
  domain: string;
	processId: string;
}
export const ProcessError = () => {
  const params = useParams<ProcessErrorParams>()
  const navigate = useNavigate()
  const {domain = "edm", processId = "1"} = params
  const DOMAIN = domain.toUpperCase()
  const PROCESS_ID = processId.padStart(2, "0")
  return(
    <Paper sx={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
      <H2>404 Not Found</H2>
      <NotFound/>
      <P>Domain <strong>{DOMAIN}</strong> atau Process Id <strong>{PROCESS_ID}</strong> tidak dapat ditemukan.</P>
      <Button onClick={() => navigate("/", {replace: true})}>Kembali</Button>
    </Paper>
  )
}

type AssessmentErrorParams = {
  domain: string;
	processId: string;
	processLevel: string;
	assessmentId: string;
}
export const AssessmentError = () => {
  const params = useParams<AssessmentErrorParams>()
  const navigate = useNavigate()
  const {domain = "edm", processId = "1", processLevel = "0", assessmentId="undefined"} = params
  const DOMAIN = domain.toUpperCase()
  const PROCESS_ID = processId.padStart(2, "0")
  return(
    <Paper sx={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
      <H2>404 Not Found</H2>
      <NotFound/>
      <P>Domain <strong>{DOMAIN}</strong>, Process Id <strong>{PROCESS_ID}</strong>, Process Level <strong>{processLevel}</strong> atau Assessment <strong>{assessmentId}</strong> tidak dapat ditemukan.</P>
      <Button onClick={() => navigate("/", {replace: true})}>Kembali</Button>
    </Paper>
  )
}
