import { Suspense } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation, useParams } from "react-router-dom";
import Unauthenticated from "../../../../../components/common/Redirect/Unauthenticated";
import Header from "../../../../../components/navigations/header/Header";
import QuestionPracticeDiscussionPage from "../../../route-element/pm/domain-process/question-level/question-practice-discussion";
import Loading from "./loading";

const QuestionPracticeDiscussion = () => {
    const authUser = useAuthUser();
    const location = useLocation();
    const { domain = "", processId = "", processLevel = "", assessmentId = "", criteriaId = "" } = useParams<{ domain: string, processId: string, processLevel: string, assessmentId: string, criteriaId: string }>();
    const DOMAIN = domain.toUpperCase();
    const PROCESS_ID = processId.padStart(2, "0");

    if (!authUser) return <Unauthenticated />;

    const Page = () => {
        if (authUser.role === "pm") return <QuestionPracticeDiscussionPage domain={DOMAIN} processId={PROCESS_ID} level={processLevel} assessmentId={assessmentId} criteriaId={criteriaId} />;
        if (authUser.role === "admin") return <QuestionPracticeDiscussionPage domain={DOMAIN} processId={PROCESS_ID} level={processLevel} assessmentId={assessmentId} criteriaId={criteriaId} />;
        else return <>Forbidden</>;
    }

    return <>
        <Header
            title="Kuisioner"
            breadcrumbs={[
                { text: "Kuisioner", path: "#" },
                { text: DOMAIN, path: "#" },
                { text: `${DOMAIN}${PROCESS_ID}`, path: location.pathname.split("/").slice(0, 4).join("/") },
                { text: "Question", path: "#" },
                { text: "Discussions", path: "#" },
            ]}
        />
        <Suspense fallback={<Loading />}>
            <Page />
        </Suspense>
    </>;
};

export default QuestionPracticeDiscussion;
