import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchProcessLevel } from "../../../services/process";
import { Level } from "../../../services/types/process";
export const useFetchProcessLevel = (process_id: string, levelID:string, clientID:string) => {
	return useSuspenseQuery({ queryKey: ["process/level", process_id, levelID, clientID], queryFn: () => clientID ? fetchProcessLevel(process_id, levelID, clientID):Promise.resolve({
		id: "",
		level: NaN,
		level_name: "",
		filled: false,
		assessments: [],
	} as Level) });
};
