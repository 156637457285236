import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { memo, useCallback } from "react";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Small } from "../../../../components/display/typography";
import { GapChartData, GapKey } from "../../../../utils/types/dashboard";
import { chartConfig } from "./data";
type GapChartProps = {
	data: GapChartData[] | undefined;
	gapKey: GapKey[];
	xAxis: string
};
const GapChart = ({ data, gapKey, xAxis = "name" }: GapChartProps) => {

	const getLabel = useCallback((value: number, props: any) => {
		const x = props.x + (props.width / 2);
		const y = props.y + (props.height / 2)
		const prefixValue = value < 0 ? "" : "+";
		const textContent = `${prefixValue}${value.toFixed(2)}`

		return (
			<text fontSize={12} x={x} y={y} textAnchor="middle" dominantBaseline={"middle"} fill={"#000"} transform={`rotate(${90}, ${x}, ${y})`}>
				{textContent}
			</text>
		);
	}, []);


	return data && data.length > 0 ? (
		<ResponsiveContainer {...chartConfig.responsiveContainer}>
			<BarChart data={data}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={xAxis} />
				<YAxis domain={[0, 5]} />
				<Tooltip />
				<Legend />
				{gapKey.map((g, id) => {
					if (g.dataKey === "gap") {
						return <Bar
							key={id}
							label={(props) => getLabel(data[props.index].gap[1] - data[props.index].gap[0], props)}
							{...g}
						>
							{data.map((d, id) => <Cell key={id} fill={d.gap[1] - d.gap[0] > 0 ? g.fill : "#C94026"} />)}
						</Bar>
					} else {
						return <Bar
							key={id}
							label={{ fill: "#000", fontSize: 12, angle: 90 }} {...g} />
					}
				})}
			</BarChart>
		</ResponsiveContainer>
	) : (
		<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, flexDirection: "column", bgcolor: grey[100], borderRadius: 3 }}>
			<div>Gap Chart</div>
			<div><Small>Data tidak cukup</Small></div>
		</Box>
	);
};

export default memo(GapChart);
