import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import api from "../../../../lib/axios/axios";

const DownloadTemplateButton = ({ process_id }: { process_id: string }) => {
    const [isDownloading, setIsDownloading] = useState(false)
    const handleExport = async () => {
        setIsDownloading(true)
        const blob = await api.get(`/activities/download/template/alternative/${process_id}`, { responseType: 'blob' })
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `template_${process_id}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove()
        setIsDownloading(false)
        // link.parentNode.removeChild(link);
    };
    return (
        <LoadingButton size="small" color="primary" startIcon={<FileDownloadOutlinedIcon />} onClick={handleExport} loading={isDownloading}>
            Download Template
        </LoadingButton>
    )
}

export default DownloadTemplateButton;