import { List } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { FastField, FastFieldProps, FieldArray } from "formik";
import { memo } from "react";
import ListBullet from "../../../../../components/List/Bullet";
import { ListItemQuestion } from "../../../../../components/List/Item";
import { ListLineSmallRepeater } from "../../../../../components/List/Line";
import { CommentsProps } from "../../../../../components/props";
const CommentList = ({ id, response }: CommentsProps) => {
	return (
		<List
			sx={{
				"& .MuiBox-root": {
					"&.comment-line": {
						pb: 2,
						"&:last-of-type": {
							pb: 0,
							"&::before": {
								width: 0,
							},
						},
					},
				},
			}}
		>
			<FieldArray name={`activities.${id}.response.${0}.comments`}>
				{({ push, remove }) => (
					<>
						{response[0] ?
							response[0].comments.length > 0 ?
								response[0].comments.map((comment, comment_id) => (
									<ListLineSmallRepeater className="comment-line" key={`comment-${comment.id}-${id}-${comment_id}`}>
										<ListItemQuestion
											alignItems="flex-start"
											sx={{
												"& .MuiListItemAvatar-root": { mt: 0 },
											}}
										>
											<ListBullet size="small" completed={true} text={(id + 1).toString()} />
											<Box width={"100%"}>

												<FastField name={`activities.${id}.response.${0}.comments.${comment_id}.comment`}>
													{({ field, meta }: FastFieldProps<string>) => (
														<>
															<TextField
																{...field}
																size="small"
																label={`Komentar ${comment_id + 1}`}
																multiline
																minRows={3}
																fullWidth
																sx={{ mr: 1 }}
																error={meta.touched && Boolean(meta.error)}
																helperText={meta.touched && meta.error}
															/>
														</>
													)}
												</FastField>
												<Box display="flex" alignItems={"center"} justifyContent="space-between" sx={{ mt: 1 }}>
													<Button
														sx={{ mr: 1 }}
														size="small"
														onClick={() => {
															push({
																id: crypto.randomUUID(),
																response_id: response[0].id,
																comment: "",
															});
														}}
													>
														Tambah
													</Button>
													<Button
														sx={{ mr: 1 }}
														size="small"
														color="error"
														onClick={() => {
															remove(comment_id);
														}}
													>
														Hapus
													</Button>
												</Box>
											</Box>
										</ListItemQuestion>
									</ListLineSmallRepeater>
								)) :
								(
									<>
										<Chip sx={{ mx: 0 }} size="small" label="Belum ada komentar" />
										<Box display="flex" alignItems={"center"} justifyContent="flex-start" sx={{ mt: 1 }}>
											<Button
												sx={{ mr: 1 }}
												size="small"
												onClick={() => {
													push({
														id: crypto.randomUUID(),
														response_id: response[0].id,
														comment: "",
													});
												}}
											>
												Tambah
											</Button>
										</Box>
									</>
								)
							: <Chip sx={{ mx: 0 }} size="small" label="Penilaian Kosong" />}
					</>
				)}
			</FieldArray>
		</List>
	);
};

export default memo(CommentList);
