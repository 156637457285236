import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ReactElement } from "react";
export const renderProcessId = (params: GridRenderCellParams): ReactElement => {
	const {
		row: { type },
		value,
	} = params;
	let sx = {
		backgroundColor: "inherit",
		color: "inherit",
	};
	let title = "";
	switch (type) {
		case "design_factor":
			sx.backgroundColor = "#00B050";
			sx.color = "#ffffff";
			title = "Berdasarkan Design Factor";
			break;
		case "related_regulations":
			sx.backgroundColor = "#00B0F0";
			sx.color = "#ffffff";
			title = "Berdasarkan Regulasi Terkait ";
			break;
		case "bumn":
			sx.backgroundColor = "#ED7D31";
			sx.color = "#ffffff";
			title = "Berdasarkan Permen BUMN";
			break;
		default:
			break;
	}
	return <Chip title={title} size="small" label={value} sx={sx} />;
};

export const renderLevelPercentage = (params: GridRenderCellParams): ReactElement => {
	const { value } = params;
	let sx = {
		backgroundColor: "inherit",
		color: "inherit",
	};
	let title = "Not Achieved";
	let text = "N";
	if (value >= 0 && value < 15) {
	} else if (value >= 15 && value < 50) {
		sx.backgroundColor = "#C4BD97";
		title = "Partially Achieved";
		text = "P";
	} else if (value >= 50 && value < 85) {
		sx.backgroundColor = "#C6D9F0";
		title = "Largely Achieved";
		text = "LF";
	} else if (value >= 85 && value <= 100) {
		sx.backgroundColor = "#6FA0DB";
		title = "Fully Achieved";
		text = "F";
	}
	return (
		<Box title={title} component={"div"}>
			<Chip size="small" label={text} sx={sx} /> {(value as number).toFixed(2)}
		</Box>
	);
};

export const getColorForValue = (value: number, max: number) => {
	const normalizedValue = (value - max) / (2 - max);
	const hue = 120 * (1 - normalizedValue);

	const saturation = 50; // Percent
	const lightness = 60; // Percent

	return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const renderSummary = (params: GridRenderCellParams, max: number): ReactElement => {
	const { value } = params;
	return <Chip size="small" label={(value as number).toFixed(2)} sx={{ bgcolor: getColorForValue(value, max), fontWeight: 600 }} />;
};
