import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Form, Formik } from "formik";
import { useCallback, useMemo } from "react";
import { useEditActivityQuestions } from "../../../../../../../../hooks/query/activities/useEditActivityQuestions";
import activityFormik from "../../../../../../../../lib/formik/activity";
import { EditActivitiesWithoutResponse } from "../../../../../../../../services/types/process";
import { Context } from "../../../../../../../../types/response";
import { H4 } from "../../../../../../../components/display/typography";
import { QuestionPracticeDiscussionActivityProps } from "../../../../../components/props";
import ActivityItem from "./activity/ActivityItem";
const ActivityRepeater = ({ data, process_id, levelId, assessmentId, criteriaId, clientId }: QuestionPracticeDiscussionActivityProps) => {
	const { mutate: editActivity } = useEditActivityQuestions(process_id, levelId, assessmentId, criteriaId, clientId);
	const context = JSON.parse(localStorage.getItem("context") || "{}") as Context;
	const initialValues = useMemo(() => ({
		...activityFormik.edit.initialValues,
		activities: data.map((activity) => (
			{
				...activity,
				activity_alternatives: activity.activity_alternatives.length ?
					activity.activity_alternatives :
					[
						{
							id: crypto.randomUUID(),
							use_default: true,
							activity_id: activity.id,
							activity: "Activity Alternatif",
							client_id: clientId
						}
					],
				target_capability_level_reference: activity.target_capability_level_reference ?
					activity.target_capability_level_reference :
					context.process_target,
				previous_capability_level_reference: activity.previous_capability_level_reference ?
					activity.previous_capability_level_reference :
					context.process_previous_target,
			})),
	}), [data, clientId, context]);

	const activityLength = useMemo(() => {
		return data.length;
	}, [data]);

	const handleSubmit = useCallback((values: EditActivitiesWithoutResponse, setSubmitting: (a: boolean) => void, resetForm: (a: any) => void) => {
		const modifiedValues = values.activities.map(activity => {
			if (activity.activity_alternatives[0].activity === "Activity Alternatif") {
				return { ...activity, activity_alternatives: [] };
			}
			return activity;
		});
		editActivity(modifiedValues, {
			onSuccess: () => {
				setSubmitting(false);
				resetForm({ values });
			},
		}
		);
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={activityFormik.edit.validationSchema}
			enableReinitialize
			onSubmit={(values, { setSubmitting, resetForm }) => handleSubmit(values, setSubmitting, resetForm)}
		>
			{({ values: { activities }, dirty, isSubmitting }) => (
				<Form>
					<Box display={"flex"} gap={1} alignItems={"center"}>
						<H4>Activities</H4>
						<Chip label={activityLength} size="small" color="error" sx={{ fontWeight: 400 }} />
						<Chip label={dirty ? "Not Saved" : "Saved"} color={dirty ? "warning" : "default"} size="small" sx={{ fontWeight: 400 }} />
					</Box>
					{activities.map((activity, id) => (
						<ActivityItem key={`activity-${activity.id}`} activity={activity} id={id} />
					))}
					<Box display={"flex"} justifyContent={"flex-end"} mt={4} gap={2} alignItems={"center"}>
						<Chip label={dirty ? "Not Saved" : "Saved"} color={dirty ? "warning" : "default"} size="small" sx={{ fontWeight: 400 }} />
						<Button type="submit" disabled={isSubmitting || !dirty} sx={{ textTransform: "none" }} size="large" variant="contained">
							Simpan
						</Button>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default ActivityRepeater;

