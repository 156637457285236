import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ReactNode } from 'react';

const DateTimeLuxonAdapter = ({children}:{children:ReactNode}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            {children}
        </LocalizationProvider>
    );
}

export default DateTimeLuxonAdapter;