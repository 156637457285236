"use server";
import { AxiosError } from "axios";
import { processAuth } from "../../domain/processAuth";
import { fetchAuth } from "../../services/auth";
import { ReactAuthKitData } from "../../services/types/auth";
import { Response } from "../../types/response";

export const authenticate = async (prevState: Response<ReactAuthKitData> | undefined, formData: FormData): Promise<Response<ReactAuthKitData>> => {
	try {
		const res = await fetchAuth(formData);
		const data = processAuth(res);
		return {
			status: res.status,
			message: res.data.message,
			success: true,
			data,
		};
	} catch (error) {
		console.error(error)
		if (error instanceof AxiosError) {
			console.error(error.response);
			throw new Error(
				JSON.stringify({
					status: error.response?.status,
					message: error.response?.data.message,
					success: false,
				})
			);
		}
		throw error;
	}
};
