import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { generateLevelData, generateSummaryData, getDomainDescription, parsePrecision } from "../../../../../lib/utils/helper";
import { ResponseDashboard } from "../../../../../services/types/response";
import { Paper } from "../../../../components/display/paper/Paper";
import { H2, H3 } from "../../../../components/display/typography";
import BreakdownChart from "../charts/Breakdown";
import GapChart from "../charts/Gap";
import RadarChart from "../charts/Radar";
import DataTable from "../datatable";
import { DashboardDomainTabProps } from "../props";
import SummaryCardList from "./lists/SummaryCardList";
const DomainTab = ({ data, filter, domain }: DashboardDomainTabProps) => {
	// FUNCTIONS
	const generateChart = (data: ResponseDashboard[] | undefined, filter: string) => {
		if (!data || data.length === 0) {
			return {
				summary: [],
				maturitySummary: 0,
				radar: [],
				gap: [],
				level: {
					summary: [],
					process: [],
				},
			};
		}

		let filteredData = data.filter((d) => d.domain === domain);
		if (filter !== "all") {
			filteredData = filteredData.filter((d) => d.process_group === filter);
		}
		const radar = filteredData.map((d) => ({
			subject: d.process_id,
			maturity: d.summary,
			agreed: d.agreed_target,
		}));
		const setGap = (d: ResponseDashboard): number[] => {
			return [
				parsePrecision(d.agreed_target), parsePrecision(d.summary)
			]
		}

		const gap = filteredData.map((d) => ({
			subject: d.process_id,
			maturity: parsePrecision(d.summary),
			agreed: parsePrecision(d.agreed_target),
			gap: setGap(d),
		}));

		const level = generateLevelData(filteredData);
		const summary = generateSummaryData(
			filteredData,
			filteredData.map((d) => d.process_id),
			false
		);
		const maturity = filteredData.reduce((prev, current) => prev + current.summary, 0.0) / filteredData.length;
		return {
			summary,
			maturitySummary: maturity,
			radar,
			gap,
			level,
		};
	};

	// VARIABLES
	const { summary, maturitySummary, radar, gap, level } = generateChart(data, filter);
	const radarKey = [
		{ name: "Maturity", dataKey: "maturity", stroke: "#8884d8", fill: "#8884d8", fillOpacity: 0.2 },
		{ name: "Target", dataKey: "agreed", stroke: "#2476D2", fill: "#2476D2", fillOpacity: 0.2 },
	];
	const gapKey = [
		{ name: "Maturity", dataKey: "maturity", stackId: "a", fill: "#8884d8" },
		{ name: "Gap", dataKey: "gap", fill: "#5DB65D" },
		{ name: "Target", dataKey: "agreed", stackId: "c", fill: "#2476D2" },
	];
	const stackAndColors = [
		{ stackId: "a", fill: "#82bcd0" },
		{ stackId: "b", fill: "#75b0c5" },
		{ stackId: "c", fill: "#67a5b9" },
		{ stackId: "d", fill: "#5a99ae" },
		{ stackId: "e", fill: "#4c8ea3" },
		{ stackId: "f", fill: "#3e8398" },
		{ stackId: "g", fill: "#2f788d" },
		{ stackId: "h", fill: "#1d6d83" },
		{ stackId: "i", fill: "#2f788d" },
		{ stackId: "j", fill: "#3e8398" },
		{ stackId: "k", fill: "#4c8ea3" },
		{ stackId: "l", fill: "#5a99ae" },
		{ stackId: "m", fill: "#67a5b9" },
		{ stackId: "n", fill: "#75b0c5" },
		{ stackId: "z", fill: "#8A8D90" },
	];
	const levelSummaryKey = summary
		.map((level, id) => ({ name: level.subject, dataKey: level.subject.toLowerCase(), stackId: stackAndColors[id].stackId, fill: stackAndColors[id].fill, radius: 8 }))
	const levelProcessKey = Array(6)
		.fill(0)
		.map((_, id) => ({
			name: `Level ${id}`,
			dataKey: `level_${id}`,
			stackId: stackAndColors[id].stackId,
			fill: stackAndColors[id].fill,
			radius: 8,
		}))
	// .concat([{ name: "Average", dataKey: "average", stackId: "z", fill: stackAndColors[stackAndColors.length - 1].fill, radius: 8 }]);

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<Paper elevation={1} sx={{ height: "100%" }}>
					<Box sx={{ minHeight: "120px" }}>
						<H2>
							{domain} {getDomainDescription(domain)}
						</H2>
						<p>
							Target Maturity Level vs. Current Maturity Level{filter !== "all" && ` berdasarkan ${filter}`}.
						</p>
					</Box>
					<Grid container spacing={2}>
						<Grid container spacing={2}>
							<SummaryCardList summary={summary} maturitySummary={maturitySummary} />
						</Grid>
						<Grid item md={6}>
							<RadarChart data={radar} radarKey={radarKey} />
						</Grid>
						<Grid item md={6}>
							<GapChart data={gap} gapKey={gapKey} xAxis="subject" />
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper elevation={1} sx={{ height: "100%" }}>
					<H2>Maturity Breakdown</H2>
					<H3>Breakdown by Level</H3>
					<BreakdownChart data={level.summary} levelKey={levelSummaryKey} xAxis="subject" />
					<H3>Breakdown Level by Process</H3>
					<BreakdownChart data={level.process} levelKey={levelProcessKey} xAxis="subject" />
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper elevation={1} sx={{ height: "100%" }}>
					<h3>{domain} Data</h3>
					<DataTable data={data} domain={domain} filter={filter} />
				</Paper>
			</Grid>
		</Grid>
	);
};

export default DomainTab;
