import { styled } from "@mui/material/styles";
import TabMUI from "@mui/material/Tab";
import TabsMUI from "@mui/material/Tabs";
import { grey } from "@mui/material/colors";

export const Tab = styled(TabMUI)(({ theme }) => ({
	background: `rgba(31, 101, 191, 0.2)`,
	transition: "ease-in .3s",

	"&:first-of-type": {
		borderRadius: "1rem 0 0 1rem",
	},
	"&:last-of-type": {
		borderRadius: "0 1rem 1rem 0",
	},
	"&:hover": {
		background: `rgba(31, 101, 191, 0.4)`,
	},
	"&.Mui-selected": {
		background: theme.palette.primary.main,
		color: "#fff",
	},
}));

export const TabVertical = styled(TabMUI)(({ theme }) => ({
	background: `rgba(31, 101, 191, 0.2)`,
	transition: "ease-in .3s",
	justifyContent: "start",
	textAlign: "left",
	textTransform: "none",
	fontWeight: 400,
	color: grey[800],

	"&.filled .MuiAvatar-root": {
			border: `1px solid ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
	},
	"& .MuiAvatar-root": {
		marginRight: "1rem",
		background: "none",
		border: `1px solid ${grey[500]}`,
		color: grey[500],
	},
	"&:first-of-type": {
		borderRadius: "1rem 1rem 0 0",
	},
	"&:last-of-type": {
		borderRadius: "0 0 1rem 1rem",
	},
	"&:only-child": {
		borderRadius: "1rem",
	},
	"&:hover": {
		background: `rgba(31, 101, 191, 0.3)`,
	},
	"&.Mui-selected": {
		background: `rgba(31, 101, 191, 0.4)`,
		color: grey[800],
		"& .MuiAvatar-root": {
			color: "#fff",
			border: `none`,
			background: grey[500],
		},
		"&.filled .MuiAvatar-root": {
			background: theme.palette.primary.main,
		}
	},
}));

export const Tabs = styled(TabsMUI)(() => ({
	borderBottom: "none",
	"& .MuiTabs-indicator": {
		backgroundColor: "transparent",
	},
}));

export const TabsVertical = styled(TabsMUI)(() => ({
	alignItems: "flex-start",
	borderBottom: "none",
	"& .MuiTabs-indicator": {
		backgroundColor: "transparent",
	},
}));
