import api from "../../../../lib/axios/axios";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const ExportButton = ({ client_id }: { client_id: string }) => {
    const [isDownloading, setIsDownloading] = useState(false)
    const handleExport = async () => {
        setIsDownloading(true)
        const blob = await api.get(`/responses/download/${client_id}`, { responseType: 'blob' })
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.zip');
        document.body.appendChild(link);
        link.click();
        link.remove()
        setIsDownloading(false)
        // link.parentNode.removeChild(link);
    };
    return (
        <LoadingButton size="small" color="primary" startIcon={<FileUploadOutlinedIcon />} onClick={handleExport} loading={isDownloading}>
            Export Data
        </LoadingButton>
    )
}

export default ExportButton;