import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { ReactElement } from "react";
import { UserResponse } from "../../../../../services/types/user";

export const renderClientData = (params: GridRenderCellParams): ReactElement => {
	const {
		value,
		row: { description },
	} = params;
	return (
		<List sx={{ p: 0 }}>
			<ListItem sx={{ p: 0 }}>
				<ListItemAvatar>
					<Avatar>{value[0]}</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={value}
					secondary={description}
				/>
			</ListItem>
		</List>
	);
};

export const renderClientAssignedUsers = (params: GridRenderCellParams): ReactElement => {
	const { value } = params;
	return (
		<List sx={{ p: 0 }}>
			{value.map((user: UserResponse, id: number) => (
				<Box key={id}>
					<ListItem sx={{ p: 0, pb: 2 }}>
						<ListItemAvatar>
							<Avatar>{user.name[0]}</Avatar>
						</ListItemAvatar>
						<ListItemText primary={user.name} secondary={
							<>
								<strong>{user.description}</strong> / {user.email}
							</>
						} />
					</ListItem>
				</Box>
			))}
		</List>
	);
};

export const dateRangeLLLd = (start: string, end: string): string => {
	return `${DateTime.fromISO(start).toFormat("LLL d")} - ${DateTime.fromISO(end).toFormat("LLL d")}`;
};

export const renderProjectDateRange = (params: GridRenderCellParams): ReactElement => {
	const { row: { project_start_date, project_end_date } } = params;
	return <>{dateRangeLLLd(project_start_date.toString(), project_end_date.toString())}</>;
};