import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchProcess } from "../../../services/process";
import { Process } from "../../../services/types/process";
export const useFetchProcess = (process_id: string, clientID: string) => {
	return useSuspenseQuery({ queryKey: ["process", process_id, clientID], queryFn: () => clientID ? fetchProcess(process_id, clientID):Promise.resolve({
		id: "",
		order: NaN,
		process_id: "",
		process: "",
		purpose: "",
		responses: [],
		reviewed_assessments: [],
		agreed_capabilities: [],
		open: false,
		levels: [],
	} as Process) });
};
