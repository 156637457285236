import { Suspense } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation, useParams } from "react-router-dom";
import Unauthenticated from "../../../../components/common/Redirect/Unauthenticated";
import Header from "../../../../components/navigations/header/Header";
import QuestionnaireAssessmentPage from "../../route-element/assessor/domain-process/questionnaire-assessment";
import Loading from "./loading";

const QuestionnaireAssessment = () => {
	const authUser = useAuthUser();
	const location = useLocation();
	const { domain = "", processId = "", processLevel = "", assessmentId = "" } = useParams<{ domain: string, processId: string, processLevel: string, assessmentId: string }>();
	const DOMAIN = domain.toUpperCase();
	const PROCESS_ID = processId.padStart(2, "0");
	if (!authUser) return <Unauthenticated />;

	const Page = () => {
		if (authUser.role === "assessor") return <QuestionnaireAssessmentPage domain={DOMAIN} processId={PROCESS_ID} level={processLevel} assessmentId={assessmentId} />;
		if (authUser.role === "admin") return <QuestionnaireAssessmentPage domain={DOMAIN} processId={PROCESS_ID} level={processLevel} assessmentId={assessmentId} />;
		else return <>Forbidden</>;
	}

	return <>
		<Header
			title="Kuisioner"
			breadcrumbs={[
				{ text: "Kuisioner", path: "#" },
				{ text: DOMAIN, path: "#" },
				{ text: `${DOMAIN}${PROCESS_ID}`, path: location.pathname.split("/").slice(0, 4).join("/") },
				{ text: "Answer", path: "#" },
				{ text: "Kuisioner", path: "#" }
			]}
		/>
		<Suspense fallback={<Loading />}>
			<Page />
		</Suspense>
	</>;
};

export default QuestionnaireAssessment;
