import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { editClient } from "../../../services/client";
import { EditClient } from "../../../services/types/client";
export const useEditClient = (id: string) => {
	return useMutation({
		mutationFn: (data: EditClient) => {
			return editClient(id, data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["clients"],
			});
			queryClient.resetQueries({
				queryKey: ["client", id],
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
