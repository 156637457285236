import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Default settings for queries
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false, // Prevent refetching on window focus
      retry: 1, // Retry failed requests once
    },
  },
});

export default queryClient;
