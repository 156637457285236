import ListItemButton from "@mui/material/ListItemButton";
import ListItemMUI from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
const ListItem = styled(ListItemButton)(({ theme }) => ({
	borderRadius: "1rem",
	padding: ".5rem",
	"& .MuiListItemAvatar-root": {
		minWidth: 0,
		marginRight: theme.spacing(2),
	},
}));
export const ListItemQuestion = styled(ListItemMUI)(({ theme }) => ({
	padding: "0",
	"& .MuiListItemAvatar-root": {
		minWidth: 0,
		marginRight: theme.spacing(2),
	},
}));

export default ListItem;
