import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ReactElement } from "react";
import { MockClientData } from "../../../../../lib/mock/types/clients";

export const renderUserRole = (params: GridRenderCellParams): ReactElement => {
	const { value } = params;
	switch (value) {
		case "admin":
			return <Chip size="small" label="Admin" color="error" />;
		case "pm":
			return <Chip size="small" label="Project Manager" color="primary" />;
		case "assessor":
			return <Chip size="small" label="Assessor" />;
		default:
			return <Chip size="small" label="UNDEFINED" />;
	}
};

export const renderUserData = (params: GridRenderCellParams): ReactElement => {
	const {
		value,
		row: { description, email },
	} = params;
	return (
		<List sx={{ p: 0 }}>
			<ListItem sx={{ p: 0 }}>
				<ListItemAvatar>
					<Avatar>{value[0]}</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={value}
					secondary={
						<>
							<strong>{description}</strong> / {email}
						</>
					}
				/>
			</ListItem>
		</List>
	);
};

export const renderAssignedProjects = (params: GridRenderCellParams): ReactElement => {
	const { value } = params;
	if (!value) return <Chip size="small" label="Tidak ada project" />;
	return (
		<List sx={{ p: 0 }}>
			{value.map((project: MockClientData, id: number) => (
				<Box key={id}>
					<ListItem sx={{ p: 0, pb: 2 }}>
						<ListItemAvatar>
							<Avatar>{project.client[0]}</Avatar>
						</ListItemAvatar>
						<ListItemText primary={project.client} secondary={project.description} />
					</ListItem>
				</Box>
			))}
		</List>
	);
};