import MockAdapter from "axios-mock-adapter";
import { data } from "./data";

const assessments = (mock: MockAdapter) => {
	mock.onGet("/api/assessments").reply((config) => {
		if(config && config.url){
			// cache
			if (localStorage.getItem(config.url)) {
				const localData = localStorage.getItem(config.url);
				return [200, localData];
			}

			// fetch
			localStorage.setItem(
				config.url,
				JSON.stringify(data)
			);
			return [200, data]
		}
		return [404, "Data not found"]
	});
	return mock;
};

export default assessments;
