import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { createClient } from "../../../services/client";
export const useCreateClient = () => {
	return useMutation({
		mutationFn: createClient,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["clients"],
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
