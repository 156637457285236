import { createTheme, alpha, getContrastRatio } from "@mui/material";

declare module "@mui/material/styles" {
	interface Palette {
		sv: Palette["primary"];
	}

	interface PaletteOptions {
		sv?: PaletteOptions["primary"];
	}
}

const svColorBase = "#CF2A22";
const svColorMain = alpha(svColorBase, 0.9);

const theme = createTheme({
	palette: {
		sv: {
			main: svColorMain,
			light: alpha(svColorBase, 0.5),
			dark: svColorBase,
			contrastText: getContrastRatio(svColorMain, '#fff') > 4.5 ? '#fff' : '#3f3f3f',
		},
		background: {
			default: "#EEEEEE"
		}
	},
});

export default theme;
