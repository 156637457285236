import { Navigate, Outlet, RouteObject } from "react-router-dom";
import DomainProcess from "../../pages/questionnaire/route/page";
import QuestionLevel from "../../pages/questionnaire/route/question-level/page";
import QuestionPracticeDiscussion from "../../pages/questionnaire/route/question-level/question-practice-discussion/page";
import QuestionnaireAssessment from "../../pages/questionnaire/route/questionnaire-assessment/page";
import ReviewAssessment from "../../pages/questionnaire/route/review-assessment/page";
import QuestionnaireUploadTemplate from "../../pages/questionnaire/route/questionnaire-upload-template/page";
import QuestionnaireError from "./errors/questionnaire.error-route";

const QuestionnaireRoutes: RouteObject[] = [
	{
		path: "/kuisioner",
		element: <Outlet />,
		errorElement: <QuestionnaireError />,
		children: [
			{ path: "/kuisioner/", element: <Navigate to="/kuisioner/edm/1" replace /> }, // Empty, redirect to EDM
			{ path: "/kuisioner/:domain", element: <Navigate to="/kuisioner/edm/1" replace /> }, // Empty, redirect to EDM
			{
				path: "/kuisioner/:domain/:processId",
				element: <Outlet />,
				children: [
					{
						path: "/kuisioner/:domain/:processId",
						element: <DomainProcess />,
					},
					{
						path: "/kuisioner/:domain/:processId/question",
						element: <Outlet />,
						children: [
							{ path: "/kuisioner/:domain/:processId/question", element: <Navigate to="/kuisioner/edm/1" replace /> }, // Empty, redirect to EDM
							{
								path: "/kuisioner/:domain/:processId/question/:processLevel",
								element: <QuestionLevel />,
							},
							{
								path: "/kuisioner/:domain/:processId/question/:processLevel/:assessmentId/:criteriaId",
								element: <QuestionPracticeDiscussion />,
							},
						]
					},
					{
						path: "/kuisioner/:domain/:processId/review",
						element: <Outlet />,
						children: [
							{ path: "/kuisioner/:domain/:processId/review", element: <Navigate to="/kuisioner/edm/1" replace /> }, // Empty, redirect to EDM
							{
								path: "/kuisioner/:domain/:processId/review/:processLevel/:assessmentId",
								element: <ReviewAssessment />,
							},
						]
					},
					{
						path: "/kuisioner/:domain/:processId/questionnaire",
						element: <Outlet />,
						children: [
							{ path: "/kuisioner/:domain/:processId/questionnaire", element: <Navigate to="/kuisioner/edm/1" replace /> }, // Empty, redirect to EDM
							{
								path: "/kuisioner/:domain/:processId/questionnaire/:processLevel/:assessmentId",
								element: <QuestionnaireAssessment />,
							},
						]
					},
					{
						path: "/kuisioner/:domain/:processId/upload",
						element: <QuestionnaireUploadTemplate />,
					},
				]
			},
		]
	}
];

export default QuestionnaireRoutes;
