import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { FieldArray } from "formik";
import { memo } from "react";
import ToggleButtonGroupField from "../../../../../../../components/common/Form/ToggleButtonGroupField";
import { Small } from "../../../../../../../components/display/typography";
import { ReviewedAssessmentProps } from "../../../../../components/props";
const ReviewedAssessment = ({ id, reviewed_assessment, client_id, process_id, activity_id }: ReviewedAssessmentProps) => {
	return (
		<FieldArray name={`activities.${id}.reviewed_assessment`}>
			{({ push, remove }) => (
				<>
					{reviewed_assessment.length > 0 ?
						reviewed_assessment.map((ra, ra_id) => (
							<Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2} key={`reviewed_assessment-${ra.id}-${id}-${ra_id}`}>
								<Box>
									<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Hasil Penilaian</Small>
									<ToggleButtonGroupField
										name={`activities.${id}.reviewed_assessment.${0}.response`}
										options={[
											{ value: 0, label: 'Not Achieved' },
											{ value: 1, label: 'Partialy Achieved' },
											{ value: 2, label: 'Largely Achieved' },
											{ value: 3, label: 'Fully Achieved' },
										]}
										size="small"
										exclusive
									/>
								</Box>
								<Box>
									<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Criteria Are Met?</Small>
									<ToggleButtonGroupField
										name={`activities.${id}.reviewed_assessment.${0}.criteria_are_met`}
										options={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' }
										]}
										exclusive
									/>
								</Box>
								<Box display="flex" alignItems={"center"} sx={{ mt: 1 }}>
									<Box flexGrow={1} />
									<Button
										sx={{ mr: 1 }}
										size="small"
										color="error"
										onClick={() => {
											remove(ra_id);
										}}
									>
										Hapus
									</Button>
								</Box>
							</Box>
						)) :
						(
							<>
								<Chip sx={{ mx: 0 }} size="small" label="Belum ada Reviewed Assessment dari PM" />
								<Box display="flex" alignItems={"center"} sx={{ mt: 1 }}>
									<Button
										sx={{ mr: 1 }}
										size="small"
										onClick={() => {
											push({
												id: crypto.randomUUID(),
												criteria_are_met: false,
												response: 0,
												client_id,
												process_id,
												activity_id

											});
										}}
									>
										Tambah
									</Button>
									<Box flexGrow={1} />
								</Box>
							</>
						)}
				</>
			)}
		</FieldArray>
	);
};

export default memo(ReviewedAssessment);
