import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { generateSummaryData, parsePrecision } from "../../../../../lib/utils/helper";
import { ResponseDashboard } from "../../../../../services/types/response";
import { Paper } from "../../../../components/display/paper/Paper";
import { H2 } from "../../../../components/display/typography";
import { GapKey } from "../../../../utils/types/dashboard";
import GapChart from "../charts/Gap";
import RadarChart from "../charts/Radar";
import DataTable from "../datatable";
import { DashboardTabProps } from "../props";
import SummaryCardList from "./lists/SummaryCardList";

const SummaryTab = ({ data, filter }: DashboardTabProps) => {
	// FUNCTIONS
	const generateChart = (data: ResponseDashboard[] | undefined, filter: string) => {
		if (!data || data.length === 0) {
			return {
				summary: [],
				maturitySummary: 0,
				gap: [],
			};
		}

		let filteredData = [...data];
		if (filter !== "all") {
			filteredData = filteredData.filter((d) => d.process_group === filter);
		}

		const summary = generateSummaryData(filteredData, ["EDM", "APO", "BAI", "DSS", "MEA"]);
		const maturitySummary = summary.reduce((prev, curr) => {
			if (!isNaN(curr.maturity)) {
				return prev + curr.maturity;
			}
			return prev;
		}, 0.0) / summary.length;
		const setGap = (d: {
			maturity: number;
			agreed: number;
			subject: string;
		}): number[] => {
			return [
				parsePrecision(d.agreed), parsePrecision(d.maturity)
			]
		}
		const gap = summary
			.filter((d) => !isNaN(d.maturity))
			.map((d) => ({
				subject: d.subject,
				maturity: parsePrecision(d.maturity),
				agreed: parsePrecision(d.agreed),
				gap: setGap(d),
			}));
		return {
			summary,
			maturitySummary,
			gap,
		};
	};

	// VARIABLES
	const { summary, maturitySummary, gap } = generateChart(data, filter);
	const radarKey = [
		{ name: "Maturity", dataKey: "maturity", stroke: "#8884d8", fill: "#8884d8", fillOpacity: 0.2 },
		{ name: "Target", dataKey: "agreed", stroke: "#2476D2", fill: "#2476D2", fillOpacity: 0.2 },
	];
	const gapKey: GapKey[] = [
		{ name: "Maturity", dataKey: "maturity", stackId: "a", fill: "#8884d8" },
		{ name: "Gap", dataKey: "gap", fill: "#5DB65D" },
		{ name: "Target", dataKey: "agreed", stackId: "c", fill: "#2476D2" },
	];

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<Paper elevation={1}>
					<Box sx={{ minHeight: "120px" }}>
						<H2>Summary</H2>
						<p>Target Maturity Level vs. Current Maturity Level{filter !== "all" && ` berdasarkan ${filter}`}.</p>
					</Box>
					<Grid container spacing={2}>
						<SummaryCardList summary={summary} maturitySummary={maturitySummary} />
						<Grid item md={6}>
							<RadarChart data={summary} radarKey={radarKey} />
						</Grid>
						<Grid item md={6}>
							<GapChart data={gap} gapKey={gapKey} xAxis="subject" />
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item md={12}>
				<Paper>
					<H2>Summary Data</H2>
					<DataTable data={data} domain="ALL" filter={filter} />
				</Paper>
			</Grid>
		</Grid>
	);
};

export default SummaryTab;
