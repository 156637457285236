import api from "../lib/axios/axios";
import { Response } from "../types/response";
import { Process, Level, Assessment, Criteria } from "./types/process";

export const fetchProcess = async (process_id: string, clientID: string): Promise<Process> => {
	const res = await api.get<Response<Process>>(`/processes/${process_id}/client/${clientID}`);
	return res.data.data;
};

export const fetchProcessLevel = async (process_id: string, levelID:string, clientID:string): Promise<Level> => {
	const res = await api.get<Response<Level>>(`/processes/${process_id}/${levelID}/client/${clientID}`);
	return res.data.data;
};

export const fetchProcessLevelAssessment = async (process_id: string, levelID:string, assessmentID:string, clientID:string): Promise<Assessment> => {
	const res = await api.get<Response<Assessment>>(`/processes/${process_id}/${levelID}/${assessmentID}/client/${clientID}`);
	localStorage.setItem("context", JSON.stringify(res.data.context));
	localStorage.setItem("open", JSON.stringify({
		assessment: assessmentID,
		open: res.data.open
	}));
	return res.data.data;
};

export const fetchProcessLevelAssessmentCriteria = async (process_id: string, levelID:string, assessmentID:string, criteriaID:string, clientID:string): Promise<Criteria> => {
	const res = await api.get<Response<Criteria>>(`/processes/${process_id}/${levelID}/${assessmentID}/criteria/${criteriaID}/client/${clientID}`);
	localStorage.setItem("context", JSON.stringify(res.data.context));
	return res.data.data;
};