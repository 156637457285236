import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
const NavMenuLoading = () => {
  return(
    <List sx={{ py: 0 }}>
        <Skeleton variant="text" sx={{ height: "37px", width: "100%" }} />
        <Skeleton variant="text" sx={{ height: "37px", width: "100%" }} />
        <Skeleton variant="text" sx={{ height: "37px", width: "100%" }} />
    </List>
  )
}

export default NavMenuLoading