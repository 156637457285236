import { RouteObject } from "react-router-dom";
import Evaluation from "../../pages/evaluation/route/page";
import EvaluationError from "./errors/evaluation.error-route";
const EvaluationRoutes: RouteObject[] = [
	{
		path: "penilaian",
		errorElement: <EvaluationError />,
		element: <Evaluation />,
	},
];

export default EvaluationRoutes;
