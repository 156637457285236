import { MockEvaluationData } from "../types/evaluations"
export const data:MockEvaluationData[] = [
    {
        id: "03decea6-f5b4-446f-ac5a-ea35773cf4cd",
        rating_scale: "Not Achieved",
        notation: "N",
        ordinal_rating: 0,
        description: "Hanya ada sedikit atau tidak ada bukti pencapaian atribut proses yang ditetapkan dalam proses yang dinilai."
    },
    {
        id: "35f45702-3922-4562-af48-575b527ed85c",
        rating_scale: "Partially Achieved",
        notation: "P",
        ordinal_rating: 1,
        description: "Ada beberapa bukti pendekatan, dan beberapa pencapaian dari atribut proses yang ditetapkan dalam proses yang dinilai. Beberapa aspek pencapaian atribut proses mungkin tidak dapat diprediksi."
    },
    {
        id: "c4447a54-b70a-4b11-b8e8-f751762acbb4",
        rating_scale: "Largely Achieved",
        notation: "L",
        ordinal_rating: 2,
        description: "Terdapat bukti pendekatan sistematis dan pencapaian yang signifikan terhadap atribut proses yang ditetapkan dalam proses yang dinilai. Beberapa kelemahan yang terkait dengan atribut proses ini mungkin ada dalam proses yang dinilai."
    },
    {
        id: "17fbd063-9003-4712-a79f-8e19ecce35d7",
        rating_scale: "Fully Achieved",
        notation: "F",
        ordinal_rating: 3,
        description: "Terdapat bukti pendekatan yang lengkap dan sistematis terhadap, dan pencapaian penuh, atribut proses yang ditetapkan dalam proses yang dinilai. Tidak ada kelemahan yang signifikan terkait dengan atribut proses ini dalam proses yang dinilai."
    }
]

export const getEvaluationById = (evaluation_id:string):MockEvaluationData | undefined => {
    const byId = data.find((d) => d.id === evaluation_id)
    if(byId) return byId
    return undefined
}
