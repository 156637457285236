import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams, gridClasses } from "@mui/x-data-grid";
import { ActionButton } from "../../../../components/buttons/ActionButton";
import { Paper } from "../../../../components/display/paper/Paper";
import { DataTableProps } from "../props";
import { renderClientAssignedUsers, renderClientData, renderProjectDateRange } from "./utils";
import { useNavigate } from "react-router-dom";

const DataTable = ({ data }: DataTableProps) => {
	const navigate = useNavigate();
	const columns: GridColDef[] = [
		{ field: "client", headerName: "Client", flex: 2, renderCell: renderClientData },
		{ field: "assigned_user", headerName: "Ditugaskan untuk", flex: 2, renderCell: renderClientAssignedUsers },
		{ field: "project_start_date", headerName: "Project Date", flex: 1, renderCell: renderProjectDateRange },
		{
			field: "actions",
			type: "actions",
			width: 150,
			getActions: (params: GridRowParams) => [
				<ActionButton size="small" variant="outlined" startIcon={<EditIcon />} onClick={() => navigate(`/clients/edit/${params.row.id}`)}>
					Edit
				</ActionButton>,
			],
		},
	];

	return (
		<Paper>
			<Box width={"100%"} height={600}>
				<DataGrid
					columns={columns}
					rows={data}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 40 },
						},
						density: "comfortable",
						columns: {
							columnVisibilityModel: { type: false },
						},
					}}
					getRowHeight={() => "auto"}
					sx={{
						border: "none",
						[`& .${gridClasses.cell}`]: {
							py: 3,
						},
					}}
					disableRowSelectionOnClick
					disableColumnSelector
				/>
			</Box>
		</Paper>
	);
};

export default DataTable;
