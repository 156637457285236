import { MockUserData, MockUser } from "../types/users";
import { MockClient } from "../types/clients";
// import { clientData } from "../clients/data";
export const userData: MockUser[] = [
	{
		id: "cb8002d3-0519-4e1a-bff4-22d9b4ff00b0",
		username: "budidubidu",
		name: "Budi Dubidu",
		email: "budidubidu@gmail.com",
		role: "pm",
		description: "Lead",
		assigned_project: ["46cb7e0e-da63-4c51-a62c-5f71cac3ae5a", "e4254381-5fb1-405b-a535-8cfbbd2da8aa"],
	},
	{
		id: "1e064674-8b47-4d28-b635-2312f0ef7ad1",
		username: "toninito",
		name: "Toni Nito",
		email: "toninito@gmail.com",
		role: "user",
		description: "Staff",
		assigned_project: ["46cb7e0e-da63-4c51-a62c-5f71cac3ae5a", "e4254381-5fb1-405b-a535-8cfbbd2da8aa"],
	},
	{
		id: "062004b8-6dcf-4a3e-a101-033c8bb04641",
		username: "sharingvision",
		name: "Sharing Vision",
		email: "sharingvision@gmail.com",
		role: "admin",
		description: "Admin",
		assigned_project: [],
	},
];

export const generateUsers = (): MockUserData[] => {
	const { clientData } = require("../clients/data");
	const users:MockUserData[] = userData.map((user) => ({
		...user,
		assigned_project: clientData.filter((client:MockClient) => client.assigned_user.find((c) => c === user.id)),
	}));
	return users;
};

export const users = generateUsers();

export const getUser = (id: string): MockUserData => {
	const user = users.find((user) => user.id === id);
	if (user) return user;
	return {
		id: "",
		username: "",
		name: "",
		email: "",
		role: "",
		description: "",
		assigned_project: [],
	};
};
