import { forwardRef } from "react";
import { NavLink as NavLinkBase, NavLinkProps } from "react-router-dom";
import { useLocation } from "react-router-dom";
const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((navLinkProps, ref) => {
	const { className: previousClasses, ...rest } = navLinkProps;
	const elementClasses = previousClasses?.toString() ?? "";
	const location = useLocation();
	const rootPath = navLinkProps.to.toString().split("/").splice(0, 4).join('/')
	const currentPath = location.pathname.split("/").splice(0, 4).join('/')
	return <NavLinkBase {...rest} ref={ref} to={navLinkProps.to} end className={({ isActive }) => {
		if(isActive){
			const path = navLinkProps.to.toString().split("/").splice(0, 3).join('/')
			localStorage.setItem("selectedMenu", JSON.stringify(path))
		}
		if(rootPath === currentPath) return elementClasses + " Mui-selected"
		return (isActive ? elementClasses + " Mui-selected" : elementClasses)
	}} />;
});

export default NavLink;