import Box from "@mui/material/Box";
import { ReactNode } from "react";
type TabPanelProps = {
	children?: ReactNode;
	index: number | string;
	value: number | string;
	label: string;
	ariaLabel: string;
};

const TabPanel = ({ children, index, value, label, ariaLabel, ...other }: TabPanelProps) => {
	return (
		<div role="tabpanel" hidden={value !== index} id={`${label}-${index}`} aria-labelledby={`${ariaLabel}-${index}`} {...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

export default TabPanel;
