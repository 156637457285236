import Box from "@mui/material/Box";
import { IconBullet } from "../../../pages/questionnaire/components/List/Bullet";
import { Small } from "../../display/typography";
import { grey } from "@mui/material/colors";
const Legend = () => {
    return (
        <Box display={"flex"} alignItems={"center"} gap={2} sx={{ bgcolor: grey[100], borderRadius: [0, 2, 2, 0], borderLeft: "5px solid #41AB5D" }} py={2} px={2}>
            <Box>Questionnaire:</Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
                <IconBullet completed={true} size="small" text=" " />
                <Small style={{ color: "#333" }}>Sudah diisi</Small>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
                <IconBullet completed={false} size="small" />
                <Small style={{ color: "#333" }}>Belum diisi</Small>
            </Box>
        </Box>
    )
}

export default Legend
