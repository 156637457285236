import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { editActivityQuestions } from "../../../services/activity";
import { EditActivityWithoutResponse } from "../../../services/types/process";
export const useEditActivityQuestions = (process_id: string, levelID: string, assessmentID: string, criteriaID: string, clientID: string) => {
	return useMutation({
		mutationFn: (data: EditActivityWithoutResponse[]) => {
			return editActivityQuestions(data, clientID);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["process/level/assessment/criteria", process_id, levelID, assessmentID, criteriaID, clientID]
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
