import { object, Schema, string, number } from "yup";
import { ProcessMaturity } from "../../services/types/process-maturity";

const editInitialValues: ProcessMaturity = {
    id: "",
    rating_scale: "",
    notation: "",
    ordinal_rating: 0,
    description: ""
}

const editValidationSchema: Schema<ProcessMaturity> = object().shape({
    id: string().required("ID harus diisi"),
    rating_scale: string().required("Rating Scale harus diisi"),
    notation: string().required("Notation harus diisi"),
    ordinal_rating: number().required("Ordinal Rating harus diisi"),
    description: string().required("Description harus diisi")
});

const ProcessMaturityFormik = {
    edit: {
        initialValues: editInitialValues,
        validationSchema: editValidationSchema
    }
};

export default ProcessMaturityFormik;