import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams } from "@mui/x-data-grid";
import { memo, useEffect, useState } from "react";
import { DataTableProps } from "../props";
import DashboardToolbar from "./DashboardToolbar";
import { renderLevelPercentage, renderProcessId, renderSummary } from "./utils";

const DataTable = ({ data, domain, filter }: DataTableProps) => {
	const [filteredRows, setFilteredRows] = useState(data);
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	});
	const max = filteredRows && filteredRows.length ? filteredRows.reduce((max, obj) => (obj.summary > max ? obj.summary : max), filteredRows[0].summary) : 5;

	useEffect(() => {
		if (data !== undefined) {
			const filtered = domain === "ALL" ? data : data.filter((row) => row.domain === domain);
			setFilteredRows(filtered);
		}
	}, [data, domain]);

	useEffect(() => {
		const updatedFilterModel: GridFilterModel = {
			items: filter !== "all" ? [{ field: "process_group", operator: "equals", value: filter }] : [],
		};
		setFilterModel(updatedFilterModel);
	}, [filter]);

	const columns: GridColDef[] = [
		{ field: "domain", headerName: "Domain", width: 0 },
		{ field: "process_id", headerName: "Process ID", width: 0, renderCell: renderProcessId },
		{ field: "process_group", headerName: "Process Group", width: 200 },
		{ field: "process", headerName: "Process", width: 400 },
		{ field: "agreed_target", headerName: "Agreed Target Capability Level", width: 0 },
		{ field: "level_0", headerName: "Level 0", width: 0, renderCell: renderLevelPercentage },
		{ field: "level_1", headerName: "Level 1", width: 0, renderCell: renderLevelPercentage },
		{ field: "level_2", headerName: "Level 2", width: 0, renderCell: renderLevelPercentage },
		{ field: "level_3", headerName: "Level 3", width: 0, renderCell: renderLevelPercentage },
		{ field: "level_4", headerName: "Level 4", width: 0, renderCell: renderLevelPercentage },
		{ field: "level_5", headerName: "Level 5", width: 0, renderCell: renderLevelPercentage },
		{ field: "summary", headerName: "Summary", width: 0, renderCell: (params: GridRenderCellParams) => renderSummary(params, max) },
	];

	return (
		<Box width={"100%"} height={600}>
			<DataGrid
				disableRowSelectionOnClick
				sx={{ border: "none" }}
				initialState={{
					pagination: {
						paginationModel: { pageSize: 40 },
					},
					density: "comfortable",
					columns: {
						columnVisibilityModel: { type: false },
					},
				}}
				rows={filteredRows}
				columns={columns}
				disableColumnSelector
				slots={{ toolbar: DashboardToolbar }}
				filterModel={filterModel}
			/>
		</Box>
	);
};

export default memo(DataTable);
