import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SyntheticEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { a11yProps } from "../../../../../../../lib/utils/helper";
import { Context } from "../../../../../../../types/response";
import { H2 } from "../../../../../../components/display/typography";
import TabPanel from "../../../../../../components/navigations/tabs/TabPanel";
import { ReviewPracticeTabProps } from "../../../../components/props";
import DiscussionTab from "./DiscussionTab";
const PracticeTab = ({ id, data }: ReviewPracticeTabProps) => {
	// STATES
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedPractice, setSelectedPractice] = useState(parseInt(searchParams.get("practice") as string) || 0);

	// EFFECTS
	useEffect(() => {
		const currentParams = searchParams.get("practice");
		if (currentParams) {
			const isParamsExist = parseInt(currentParams) <= data.practices.length
			if (!isParamsExist) {
				const currentCriteriaParams = searchParams.get("criteria");
				if (currentCriteriaParams === id.toString()) {
					setSearchParams({ criteria: currentCriteriaParams, practice: "0" });
				} else {
					setSearchParams({ criteria: id.toString(), practice: "0" });
				}
			}
		}
	}, []);

	// FUNCTIONS
	const handleCriteriaTab = (event: SyntheticEvent, newValue: number) => {
		const currentCriteriaParams = searchParams.get("criteria");
		if (currentCriteriaParams && currentCriteriaParams === id.toString()) {
			setSearchParams({ criteria: currentCriteriaParams, practice: newValue.toString() });
		} else {
			setSearchParams({ criteria: id.toString(), practice: newValue.toString() });
		}
		setSelectedPractice(newValue);
		scrollToTop();
	};

	const scrollToTop = () => {
		const firstElement = document.querySelector('#header');
		if (firstElement) {
			firstElement.scrollIntoView({ behavior: 'smooth'});
		}
	};

	const pageContext = JSON.parse(localStorage.getItem("context") || "{}") as Context;

	// VARIABLES
	const tabLabel = "questionnaire-criteria-review-project-manager-tabpanel";
	const tabAriaLabel = "questionnaire-criteria-review-project-manager-tab";
	return (
		<>
			<Box position={"sticky"} top={0} bgcolor={"white"} zIndex={3} p={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Box display={"flex"} alignItems={"center"} gap={2}>
					<H2>{data.criteria ? data.criteria : `${pageContext.process}`}</H2>
				</Box>
				<Tabs value={selectedPractice} onChange={handleCriteriaTab} aria-label="review criteria tabs">
					{data.practices.map((practice, id) => (
						<Tab key={`practice-tab-${id}`} label={practice.practice_id ? practice.practice_id : "Discussions"} {...a11yProps(id, `${tabLabel}-criteria-${id}`, `${tabAriaLabel}-criteria-${id}`)} />
					))}
				</Tabs>
			</Box>
			{data.practices.map((practice, id) => (
				<TabPanel key={`practice-tabpanel-${id}`} value={selectedPractice} index={id} label={`${tabLabel}-criteria-${id}`} ariaLabel={`${tabAriaLabel}-criteria-${id}`}>
					<DiscussionTab data={practice} criteria_id={data.id} />
				</TabPanel>
			))}
		</>
	);
};

export default PracticeTab;
