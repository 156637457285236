import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { MuiFileInput } from 'mui-file-input';
import Papa from 'papaparse';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validate } from "uuid";
import { ProjectContext } from "../../../../../../../context/ProjectContext";
import { useEditActivityAlternatives } from "../../../../../../../hooks/query/activities/useEditActivityAlternatives";
import { EditActivityAlternative } from "../../../../../../../services/types/process";
import { Response } from "../../../../../../../types/response";
import DownloadTemplateButton from "../../../../../../components/common/Button/DownloadTemplateButton";
import { Paper } from "../../../../../../components/display/paper/Paper";
import { H2, H3, P, Small } from "../../../../../../components/display/typography";
import { QuestionnaireUploadTemplatePageProps } from "../../../../components/props";

type CSVData = {
    level: string;
    assessment: string;
    criteria: string;
    practice: string;
    discussion: string;
    activity: string;
    activity_id: string;
    activity_alternative: string
}

const QuestionnaireUploadTemplatePage = ({ processId }: QuestionnaireUploadTemplatePageProps) => {
    // CONTEXT
    const { state: { selectedProject } } = useContext(ProjectContext);
    // STATES
    const [file, setFile] = useState<File | null>(null)
    const [csvData, setCsvData] = useState<EditActivityAlternative[]>([])
    const [message, setMessage] = useState<Response | null>(null)

    const navigate = useNavigate();
    // QUERIES
    const { mutate: editActivityAlternatives, isPending, isSuccess, isError } = useEditActivityAlternatives(processId, selectedProject)

    // EFFECTS
    useEffect(() => {
        if (file) readCsvFile(file)
    }, [file])

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                const domain = processId.slice(0, 3).toLowerCase()
                const process_Id = parseInt(processId.slice(3))
                const returnPath = `/kuisioner/${domain}/${process_Id}`
                console.log(returnPath)
                navigate(returnPath)
            }, 2000)
        }
    }, [isSuccess])

    // FUNCTIONS
    const handleFile = (newValue: File | null) => {
        setFile(newValue)
    }

    // function to read csv file from file state
    const readCsvFile = (file: File) => {
        if (file) {
            Papa.parse<CSVData>(file, {
                header: true,
                delimiter: ",",
                complete: (results) => {
                    const data: EditActivityAlternative[] = results.data.map((row) => {
                        return {
                            activity_id: row.activity_id,
                            activity_alternative: row.activity_alternative,
                        }
                    })
                    const processedData = data.filter((row) => validate(row.activity_id) && row.activity_alternative !== '')
                    setCsvData(processedData)
                },
            });
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (csvData.length > 0) {
            editActivityAlternatives(csvData)
        } else {
            setMessage({ status: NaN, message: 'No data to submit', success: false, data: [] })
        }
    };



    // Local component
    const SubHeader = () => {
        // const tabs = ["Assessment", "Performance Goal"];
        return (
            <Paper sx={{ py: "1rem" }}>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <Box display={"flex"} alignItems="center">
                            <Small style={{ marginRight: "1rem" }}>Upload Template CSV</Small>
                            <H2 style={{ margin: 0 }}>{processId}</H2>
                        </Box>
                    </Grid>
                    <Grid item md></Grid>
                    <Grid item>
                        <DownloadTemplateButton process_id={processId} />
                    </Grid>
                </Grid>
            </Paper>
        );
    };
    return (
        <Grid container spacing={2}>
            {selectedProject ? (
                <>
                    <Grid item md={12}>
                        <SubHeader />
                    </Grid>
                    <Grid item md={12}>
                        <Paper>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <H3 style={{ marginTop: 0 }}>Import data CSV untuk daftar Activity (Pertanyaan) {processId}.</H3>
                                    <P>Isi bagian <code>activity_alternative</code> pada file <code>{`template_${processId}.csv`}</code> untuk menambahkan pertanyaan.</P>
                                </Grid>
                                <Grid item md={6}>
                                    <Box display={"flex"} flexDirection={"column"} gap={2} component={"form"} onSubmit={handleSubmit} >
                                        <Box display={"flex"} justifyContent={"flex-end"}>
                                            <Chip label={`${csvData.length} activity`} size="small" />
                                        </Box>
                                        <MuiFileInput label="Pilih File .csv" value={file} onChange={handleFile} inputProps={{ accept: '.csv' }} fullWidth />
                                        <Small>Pastikan menggunakan delimiter <code>,</code> (<code>comma</code>) dan tidak menggunakan karakter <code>"</code> (<code>double quote</code>) pada isian.</Small>
                                        <Alert severity={isSuccess ? "success" : isError ? "error" : "info"} sx={{ mt: 2 }} >{isSuccess ? "Data berhasil diupload" : isError ? "Data gagal diupload" : message?.success === false ? message.message : "Silahkan pilih file .csv untuk diupload"}</Alert>
                                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={!file || isPending}>Upload</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </>
            ) : (
                <Grid item md={12}>
                    <Paper>
                        Tidak ada project.
                    </Paper>
                </Grid>
            )}

        </Grid>
    );
};

export default QuestionnaireUploadTemplatePage;
