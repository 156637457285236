import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ReactElement } from "react";

export const renderNotation = (params: GridRenderCellParams): ReactElement => {
	const { value } = params;
	switch(value){
        case "N":
            return <Chip size="small" label="N" />
        case "P":
            return <Chip size="small" label="P" sx={{bgcolor: "#C4BD97"}} />
        case "L":
            return <Chip size="small" label="L" sx={{bgcolor: "#C6D9F0"}} />
        case "F":
            return <Chip size="small" label="F" sx={{bgcolor: "#6FA0DB"}} />
        default:
            return <Chip size="small" label="UNDEFINED" />
    }
};

export const renderDescription = (params: GridRenderCellParams): ReactElement => {
	const { value } = params;
	return <Box>
        <Chip size="small" sx={{
        // my: 1,
        height: 'auto',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
          padding: 2
        },
      }} label={value} />
    </Box>
};