import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { FastField, FastFieldProps } from "formik";
import { memo, useMemo } from "react";
import { renderLevelChip } from "../../../../../../../../lib/utils/helper";
import ToggleButtonGroupField from "../../../../../../../components/common/Form/ToggleButtonGroupField";
import { P, Small } from "../../../../../../../components/display/typography";
import ListBullet from "../../../../../components/List/Bullet";
import { ListItemQuestion } from "../../../../../components/List/Item";
import { ListLineSmallRepeater } from "../../../../../components/List/Line";
import { ReviewActivityItemProps } from "../../../../../components/props";
import QuestionnaireCommentList from "./QuestionnaireCommentList";
import QuestionnaireEvidenceList from "./QuestionnaireEvidenceList";
const QuestionnaireActivityItem = ({ id, data }: ReviewActivityItemProps) => {
	const current_activity = useMemo(() => data, [data])
	const activity = useMemo(() => {
		const alternatives = current_activity.activity_alternatives;
		if (alternatives.length && alternatives[0]) {
			return alternatives[0].use_default ? current_activity.activity : alternatives[0].activity;
		} else {
			return current_activity.activity;
		}
	}, [current_activity])
	return (
		<ListLineSmallRepeater className="question-line" flexWrap={"wrap"}>
			<ListItemQuestion
				alignItems="flex-start"
				sx={{
					"& .MuiListItemAvatar-root": { mt: 0 },
					width: "100%"
				}}
			>
				<ListBullet size="small" completed={current_activity.filled} text={(id + 1).toString()} />
				<ListItemText sx={{ m: 0 }} primary={
					<P style={{ margin: "0" }}>
						{activity}
					</P>
				} />
			</ListItemQuestion>
			<Box flexGrow={1} ml={"32px"}>
				<Grid container spacing={2} py={2}>
					<Grid item md={12}>
						<Stack spacing={2}>
							<Box>
								<Box display={"flex"} alignItems={"center"} gap={2} mb={1}>
									<Box>
										<Small style={{ display: "block", color: "#000" }}>Penilaian</Small>
									</Box>
									<Box>
										{renderLevelChip(current_activity.response[0] ? current_activity.response[0].response : undefined, 0)}
									</Box>
								</Box>
								<ToggleButtonGroupField
									name={`activities.${id}.response.${0}.response`}
									options={[
										{ value: 0, label: 'Not Achieved' },
										{ value: 1, label: 'Partialy Achieved' },
										{ value: 2, label: 'Largely Achieved' },
										{ value: 3, label: 'Fully Achieved' },
									]}
									exclusive
								/>
							</Box>
							<Box>
								<Grid container spacing={2}>
									<Grid item md={6}>
										<FastField name={`activities.${id}.previous_capability_level_reference`}>
											{({ field }: FastFieldProps<number>) => (
												<>
													<TextField
														{...field}
														type="number"
														label="Previous Capability Level Reference"
														value={field.value ? field.value : ""}
														fullWidth
														variant="filled"
														disabled
													/>
												</>
											)}
										</FastField>
									</Grid>
									<Grid item md={6}>
										<FastField name={`activities.${id}.target_capability_level_reference`}>
											{({ field }: FastFieldProps<number>) => (
												<>
													<TextField
														{...field}
														type="number"
														label="Target Capability Level References"
														value={field.value ? field.value : ""}
														fullWidth
														variant="filled"
														disabled
													/>
												</>
											)}
										</FastField>
									</Grid>
								</Grid>
							</Box>
							<Box>
								<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Criteria are met?</Small>
								<ToggleButtonGroupField
									name={`activities.${id}.response.${0}.criteria_are_met`}
									options={[
										{ value: false, label: 'No' },
										{ value: true, label: 'Yes' },
									]}
									exclusive
								/>
							</Box>
						</Stack>
					</Grid>
					<Grid item md={12}>
						<Grid container spacing={4}>
							<Grid item md={6}>
								<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Evidence</Small>
								<QuestionnaireEvidenceList id={id} response={current_activity.response} />
							</Grid>
							<Grid item md={6}>
								<Small style={{ display: "block", color: "#000", marginBottom: "0.5rem" }}>Komentar dari PM</Small>
								<QuestionnaireCommentList id={id} response={current_activity.response} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</ListLineSmallRepeater>
	);
};

export default memo(QuestionnaireActivityItem);

