import { useMutation } from "@tanstack/react-query";
import { editAgreedCapabilitiesStatus } from "../../../services/agreed-capability";
import queryClient from "../../../lib/react-query/client";
export const useEditAgreedCapabilitiesStatus = (process_id: string, agreedCapabilityID: string, clientID: string) => {
	return useMutation({
		mutationFn: (data: { open: boolean}) => {
			return editAgreedCapabilitiesStatus(data, agreedCapabilityID, clientID);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["process", process_id, clientID],
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
