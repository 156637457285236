import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Paper } from "../../../components/display/paper/Paper";
import { H2 } from "../../../components/display/typography";
import { SubHeaderProps } from "./props";
const SubHeader = ({ title, children }: SubHeaderProps) => {
    return (
        <Paper sx={{ py: "1rem" }}>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item>
                    <Box display={"flex"} alignItems="center">
                        <H2 style={{ margin: 0 }}>{title}</H2>
                    </Box>
                </Grid>
                <Grid item md></Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                    {children}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SubHeader;