import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Legend from "../../../../../components/common/Legend";
import { Paper } from "../../../../../components/display/paper/Paper";
import { H2, P } from "../../../../../components/display/typography";
import ListLevel from "../../../components/List/ListLevel";
import { DomainProcessTabProps } from "../../../components/props";
const DomainAndProcessTab = ({ data, type }: DomainProcessTabProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<Paper>
					<H2>{data.process}</H2>
					<P>{data.purpose}</P>
					{type === "responses" && !data.open && <Alert sx={{ mb: 2 }} severity="error">
						Lembar kerja ditutup, user tidak dapat mengisi kuisioner pada proses ini.
					</Alert>}
					<Legend />
					<ListLevel levels={data.levels} type={type} />
				</Paper>
			</Grid>
		</Grid>
	);
};

export default DomainAndProcessTab;
