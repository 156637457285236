import { createContext, ReactNode, useReducer } from "react";

// TYPE
type ProjectState = {
    selectedProject: string;
};

type Action = { type: "set_project"; payload: string };

// REDUCER
const initialState: ProjectState = {
    selectedProject: localStorage.getItem("selectedProject") ?? "",
};

function projectReducer(state: ProjectState, action: Action): ProjectState {
    switch (action.type) {
        case "set_project":
            localStorage.setItem("selectedProject", action.payload);
            return { ...state, selectedProject: action.payload };
        default:
            return state;
    }
}

// CONTEXT
export const ProjectContext = createContext<{
    state: ProjectState;
    dispatch: React.Dispatch<Action>;
}>(null!);

export function ProjectProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(projectReducer, initialState);
    return <ProjectContext.Provider value={{ state, dispatch }}>{children}</ProjectContext.Provider>;
}