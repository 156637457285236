import api from "../lib/axios/axios";
import { Client as ClientResponse } from "./types/client";
import { Response } from "../types/response";
import { CreateClient, EditClient } from "./types/client";
export const fetchClients = async (): Promise<ClientResponse[]> => {
	const res = await api.get<Response<ClientResponse[]>>(`/clients`);
	return res.data.data;
};

export const fetchClient = async (id: string): Promise<ClientResponse> => {
	const res = await api.get<Response<ClientResponse>>(`/clients/${id}`);
	return res.data.data;
};

export const createClient = async (data: CreateClient): Promise<ClientResponse> => {
	const res = await api.post<Response<ClientResponse>>(`/clients`, data);
	return res.data.data;
};

export const editClient = async (id: string, data: EditClient): Promise<ClientResponse> => {
	const res = await api.put<Response<ClientResponse>>(`/clients/${id}`, data);
	return res.data.data;
};
