type Colors = {
    sv: string,
    secondary: {
        0: string
        1: string
        2: string
        3: string
    }
}

const colors:Colors = {
    sv: "#E40202",
    secondary: {
        0: "#838383",
        1: "#AEAEAE",
        2: "#D9D9D9",
        3: "#666666",
    }

}

export default colors