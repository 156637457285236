import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { useState } from "react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate } from "react-router-dom";
import { authenticate } from "../../../../lib/actions/auth";
import authFormik from "../../../../lib/formik/auth";
import { ReactAuthKitData } from "../../../../services/types/auth";
import { Response } from "../../../../types/response";
import { Paper } from "../../../components/display/paper/Paper";
import { H1, P } from "../../../components/display/typography";
import Brand from "../../../components/navigations/brand/Brand";
import LoginForm from "../components/form/login.form";

const Login = () => {
	const [formState, setFormState] = useState<Response<ReactAuthKitData> | undefined>(undefined);
	const navigate = useNavigate();
	const signIn = useSignIn();
	return (
		<Formik
			initialValues={authFormik.initialValues}
			validationSchema={authFormik.validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
			validateOnMount={false}
			onSubmit={async (values, { resetForm }) => {
				const formData = new FormData();
				for (const key in values) {
					if (authFormik.isKeyOfAuthFormikValues(key, values)) {
						formData.append(key, values[key] as string);
					}
				}

				try {
					const res = await authenticate(formState, formData);
					if (signIn(res.data)) navigate("/dashboard", { replace: true });
					resetForm();
				} catch (err: any) {
					if (err.message) {
						setFormState(JSON.parse(err.message));
					}
				}
			}}
		>
			{({ isSubmitting, handleSubmit }) => (
				<Paper>
					<Brand sx={{ justifyContent: "center" }} />
					<Box textAlign={"center"}>
						<H1>Selamat datang,</H1>
						<P>Masuk untuk menggunakan aplikasi!</P>
					</Box>
					<Box display={"flex"} justifyContent={"center"} flexDirection={"column"} gap={1}>
						{formState ? formState?.status === 200 ? <Alert severity="success">{formState?.message}</Alert> : <Alert severity="error">{formState?.message}</Alert> : null}
						<LoginForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
					</Box>
				</Paper>
			)}
		</Formik>
	);
};

export default Login;
