import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import NavLink from "../../common/Nav/NavLink";
import { MenuItem } from "../../../../lib/navigations/menu";
import { ExpandMoreIcon, ExpandLessIcon, ListItemText, ListItemButton } from "./styled";

type NavChildrensProps = {
	item: MenuItem;
	openState: any;
	onOpen: (path: string) => void;
	level: number; // Menu level. 0 = root
};
const NavChildren = ({ item, openState, onOpen, level }: NavChildrensProps) => {
	return (
		<Collapse in={openState[item.path]} timeout="auto" unmountOnExit>
			<List id={`nav-${item.title.toLowerCase()}`} component="div" disablePadding>
				{item.child &&
					item.child.map((childItem, _) => (
						<Box key={`nav-${childItem.title.toLowerCase()}`}>
							<ListItemButton
								title={childItem.hovertext}
								sx={{ pl: level * 3 }}
								{...(!childItem.child ? { component: NavLink, to: childItem.path } : { onClick: () => onOpen(childItem.path) })}
								dense
							>
								<ListItemIcon>{childItem.icon}</ListItemIcon>
								<ListItemText primary={childItem.title} disableTypography />
								{childItem.child ? openState[childItem.path] ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
							</ListItemButton>
							{childItem.child && <NavChildren item={childItem} openState={openState} onOpen={onOpen} level={level + 0.5} />}
						</Box>
					))}
			</List>
		</Collapse>
	);
};

export default NavChildren;
