import { array, boolean, number, object, Schema, string } from "yup";
import { EditActivities, EditActivitiesWithoutResponse } from "../../services/types/process";

const editInitialValues: EditActivitiesWithoutResponse = {
    activities: []
};

const editValidationSchema: Schema<EditActivitiesWithoutResponse> = object().shape({
    activities: array()
        .of(
            object().shape({
                id: string().required(),
                order: number().required(),
                activity: string().required(),
                target_capability_level_reference: number().nullable().default(null).optional().min(1, "Nilai Target Capability Level Reference minimal adalah 1").max(5, "Nilai Target Capability Level Reference maksimal adalah 1"),
                previous_capability_level_reference: number().nullable().default(null).optional().min(1, "Nilai Previous Capability Level Reference minimal adalah 1").max(5, "Nilai Previous Capability Level Reference maksimal adalah 1"),
                filled: boolean().default(false).required(),
                activity_alternatives: array()
                    .of(
                        object().shape({
                            id: string().required(),
                            client_id: string().required(),
                            activity_id: string().required(),
                            activity: string().required("Activity alternatif harus diisi"),
                            use_default: boolean().default(false).required(),
                        })
                    )
                    .required(),
            })
        ).required(),
});

const fullEditInitialValues: EditActivities = {
    activities: []
}

const fullEditValidationSchema: Schema<EditActivities> = object().shape({
    activities: array()
    .of(
        object().shape({
            id: string().required(),
            order: number().required(),
            activity: string().required(),
            target_capability_level_reference: number().nullable().default(null).optional().min(1, "Nilai Target Capability Level Reference minimal adalah 1").max(5, "Nilai Target Capability Level Reference maksimal adalah 1"),
            previous_capability_level_reference: number().nullable().default(null).optional().min(1, "Nilai Previous Capability Level Reference minimal adalah 1").max(5, "Nilai Previous Capability Level Reference maksimal adalah 1"),
            filled: boolean().default(false).required(),
            activity_alternatives: array()
            .of(
                object().shape({
                    id: string().required(),
                    client_id: string().required(),
                    activity_id: string().required(),
                    activity: string().required("Activity alternatif harus diisi"),
                    use_default: boolean().default(false).required(),
                })
            ).default([]).required(),
            response: array()
            .of(
                object().shape({
                    id: string().required(),
                    client_id: string().required(),
                    activity_id: string().required(),
                    process_id: string().required(),
                    response: number().required("Nilai harus diisi"),
                    criteria_are_met: boolean().default(false).required("Kriteria harus diisi"),
                    evidences: array()
                    .of(
                        object().shape({
                            id: string().required(),
                            response_id: string().required(),
                            reference: string().required("Referensi Evidence harus diisi"),
                            description: string().default("").optional(),
                        })
                    ).default([]).required(),
                    comments: array()
                    .of(
                        object().shape({
                            id: string().required(),
                            response_id: string().required(),
                            comment: string().required("Comment harus diisi"),
                        })
                    ).default([]).required()
                })
            ).default([]).required(),
            reviewed_assessment: array()
            .of(
                object().shape({
                    id: string().required(),
                    client_id: string().required(),
                    activity_id: string().required(),
                    process_id: string().required(),
                    response: number().required("Nilai harus diisi"),
                    criteria_are_met: boolean().default(false).required("Kriteria harus diisi"),
                })
            ).default([]).required()
        })
    ).required(),
});

const activityFormik = {
    edit: {
        initialValues: editInitialValues,
        validationSchema: editValidationSchema
    },
    fullEdit: {
        initialValues: fullEditInitialValues,
        validationSchema: fullEditValidationSchema
    }
}

export default activityFormik;