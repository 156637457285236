import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../lib/react-query/client";
import { editActivityComments } from "../../../services/activity";
import { EditActivityWithoutResponse } from "../../../services/types/process";
export const useEditActivityComments = (clientID: string, queryContext: { process_id: string; levelID: string; assessmentID: string }) => {
	return useMutation({
		mutationFn: (data: EditActivityWithoutResponse[]) => {
			return editActivityComments(data, clientID);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["process/level/assessment", queryContext.process_id, queryContext.levelID, queryContext.assessmentID, clientID],
			});
		},
		onError: (error) => {
			console.error(error);
		},
	});
};
