import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectContext } from "../../../../../../../../context/ProjectContext";
import { useFetchProcessLevelAssessmentCriteria } from "../../../../../../../../hooks/query/processes/useFetchProcessLevelAssessmentCriteria";
import { a11yProps } from "../../../../../../../../lib/utils/helper";
import { Context } from "../../../../../../../../types/response";
import { Paper } from "../../../../../../../components/display/paper/Paper";
import { H2, Small } from "../../../../../../../components/display/typography";
import TabPanel from "../../../../../../../components/navigations/tabs/TabPanel";
import { QuestionPracticeDiscussionPageProps } from "../../../../../components/props";
import PracticeTab from "../../../tabs/discussions/PracticeTab";
const QuestionPracticeDiscussionPage = ({ domain, processId, level, assessmentId, criteriaId }: QuestionPracticeDiscussionPageProps) => {
	// STATE
	const { state: { selectedProject } } = useContext(ProjectContext);
	const { data } = useFetchProcessLevelAssessmentCriteria(
		`${domain}${processId}`,
		level,
		assessmentId,
		criteriaId,
		selectedProject
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedTab, setSelectedTab] = useState(parseInt(searchParams.get("practice") as string) || 0);

	// EFFECTS
	useEffect(() => {
		const currentParams = searchParams.get("practice");
		if (currentParams) {
			const isParamsExist = parseInt(currentParams) <= data.practices.length
			if (!isParamsExist) {
				setSearchParams({ practice: "0" });
			}
		}
	}, []);

	// VARIABLES
	const tabLabel = "questionnaire-discussion-project-manager-tabpanel";
	const tabAriaLabel = "questionnaire-discussion-project-manager-tab";

	// FUNCTIONS
	const handleTabChange = (event: SyntheticEvent, newValue: number) => {
		setSearchParams({ practice: newValue.toString() });
		setSelectedTab(newValue);
		scrollToTop();
	};

	const scrollToTop = () => {
		const firstElement = document.querySelector('#header');
		if (firstElement) {
			firstElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};


	// Local component
	const SubHeader = () => {
		const pageContext = JSON.parse(localStorage.getItem("context") || "{}") as Context;
		// const tabs = ["Assessment", "Performance Goal"];
		return (
			<Box position={"sticky"} top={0} mb={2} zIndex={3}>
				<Paper sx={{ pt: "2rem", pb: 0, textAlign: "center" }}>
					{data.criteria ? (
						<>
							<Small>
								Criteria
							</Small>
							<H2 style={{ marginTop: 0 }}>{data.criteria}</H2>
						</>
					) : (
						<>
							<Small>
								{pageContext.process}
							</Small>
							<H2 style={{ marginTop: 0 }}>{`Level ${pageContext.level} - ${pageContext.assessment}`}</H2>
						</>
					)}

					<Box display={"flex"} justifyContent={"center"}>
						<Tabs value={selectedTab} onChange={handleTabChange} aria-label="question discussion tabs">
							{data.practices.map((practice, id) => (
								<Tab key={id} label={practice.practice_id ? practice.practice_id : "Discussions"} {...a11yProps(id, `${tabLabel}-practice-${id}`, `${tabAriaLabel}-practice-${id}`)} />
							))}
						</Tabs>
					</Box>
				</Paper>
			</Box>
		);
	};
	return (
		<Grid container spacing={2}>
			{selectedProject && data ? (
				<Grid item md={12}>
					<SubHeader />
					{data.practices.map((practice, id) => (
						<TabPanel key={id} value={selectedTab} index={id} label={`${tabLabel}-practice-${id}`} ariaLabel={`${tabAriaLabel}-practice-${id}`}>
							<PracticeTab data={practice}
								process_id={`${domain}${processId}`}
								levelId={level}
								assessmentId={assessmentId}
								criteriaId={criteriaId}
							/>
						</TabPanel>
					))}
				</Grid>
			) : (
				<Grid item md={12}>
					<Paper>
						Tidak ada project.
					</Paper>
				</Grid>
			)}

		</Grid>
	);
};

export default QuestionPracticeDiscussionPage;
