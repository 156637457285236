import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchProcessLevelAssessmentCriteria } from "../../../services/process";
import { Criteria } from "../../../services/types/process";
export const useFetchProcessLevelAssessmentCriteria = (process_id: string, levelID:string, assessmentID:string, criteriaID:string, clientID:string) => {
	return useSuspenseQuery({ 
		queryKey: ["process/level/assessment/criteria", process_id, levelID, assessmentID, criteriaID, clientID], 
		queryFn: () => clientID ? fetchProcessLevelAssessmentCriteria(process_id, levelID ,assessmentID, criteriaID, clientID):Promise.resolve({
			id: "",
			order: NaN,
			criteria: "",
			description: "",
			filled: false,
			practices: [],
		} as Criteria),
	});
};
