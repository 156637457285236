import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation, useParams } from "react-router-dom";
import Unauthenticated from "../../../components/common/Redirect/Unauthenticated";
import Header from "../../../components/navigations/header/Header";
import AssessorDomainProcessPage from "../route-element/assessor/domain-process";
import DomainProcessPage from "../route-element/pm/domain-process";
import { Suspense } from "react";
import Loading from "./loading";

const DomainProcess = () => {
    const authUser = useAuthUser();
    const location = useLocation();
    const { domain = "", processId = "" } = useParams<{ domain: string, processId: string }>();
    const DOMAIN = domain.toUpperCase();
    const PROCESS_ID = processId.padStart(2, "0");
    if (!authUser) return <Unauthenticated />;

    const Page = () => {
        if (authUser.role === "assessor") return <AssessorDomainProcessPage domain={DOMAIN} processId={PROCESS_ID} />;
        if (authUser.role === "pm") return <DomainProcessPage domain={DOMAIN} processId={PROCESS_ID} />;
        if (authUser.role === "admin") return <DomainProcessPage domain={DOMAIN} processId={PROCESS_ID} />;
        else return <>Forbidden</>;
    }

    return <>
        <Header
            title="Kuisioner"
            breadcrumbs={[
                { text: "Kuisioner", path: "#" },
                { text: DOMAIN, path: "#" },
                { text: `${DOMAIN}${PROCESS_ID}`, path: location.pathname },
            ]}
        />
        <Suspense fallback={<Loading/>}>
            <Page />
        </Suspense>
    </>;
};

export default DomainProcess;
