import { QueryClientProvider } from "@tanstack/react-query";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import queryClient from "../../lib/react-query/client";
import Unauthenticated from "../components/common/Redirect/Unauthenticated";
import AppLayout from "../components/layouts/AppLayout";

const ProtectedRoute = () => {
	const auth = useAuthUser();

	if (!auth) {
		return <Unauthenticated />;
	}
	return <QueryClientProvider client={queryClient}><AppLayout />
		{/* <ReactQueryDevtools /> */}
	</QueryClientProvider>;
};

export default ProtectedRoute;
