import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
	return (
		<Box
			sx={{
				background: "#1F2128",
				height: "100vh",
				width: "100vw",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Container maxWidth="sm">
				<Outlet />
			</Container>
		</Box>
	);
};

export default AuthLayout;
