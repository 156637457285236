import { MockClient, MockClientData } from "../types/clients";
import { MockUser } from "../types/users";

export const clientData: MockClient[] = [
	{
		id: "46cb7e0e-da63-4c51-a62c-5f71cac3ae5a",
		client: "BRI Insurance",
		email: 'hello@bri.co.id',
		description: "Assessment Tata Kelola",
		project_start_date: new Date("2024-06-10"),
		project_end_date: new Date("2024-08-10"),
		person_in_charge: [
			{
				name: "Andri",
				description: "Admin",
				contact: "+628211233211",
			}
		],
		assigned_user: ["cb8002d3-0519-4e1a-bff4-22d9b4ff00b0", "1e064674-8b47-4d28-b635-2312f0ef7ad1"],
	},
	{
		id: "e4254381-5fb1-405b-a535-8cfbbd2da8aa",
		client: "Sharing Vision",
		email: 'hello@sharingvision.com',
		description: "Assessment Tata Kelola",
		project_start_date: new Date("2024-05-03"),
		project_end_date: new Date("2024-05-03"),
		person_in_charge: [
			{
				name: "Nendri",
				description: "HR",
				contact: "+628244233244",
			}
		],
		assigned_user: ["cb8002d3-0519-4e1a-bff4-22d9b4ff00b0", "1e064674-8b47-4d28-b635-2312f0ef7ad1"],
	},
];

export const generateMockClients = (): MockClientData[] => {
	const { userData } = require("../users/data");
	const clients: MockClientData[] = clientData.map((client) => ({
		...client,
		assigned_user: userData.filter((user:MockUser) => user.assigned_project.find((c) => c === client.id)),
	}));
	return clients;
};

export const clients = generateMockClients();

export const getMockClient = (id: string): MockClientData => {
	const client = clients.find((client) => client.id === id);
	if (client) return client;
	return {
		id: "",
		client: "",
		email: "",
		description: "",
		project_start_date: new Date("2024-05-03"),
		project_end_date: new Date("2024-05-03"),
		person_in_charge: [],
		assigned_user: [],
	};
};
