import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import { FastField, FieldProps, useFormikContext } from "formik";
import { memo } from "react";
import { EditActivitiesWithoutResponse, EditActivityWithoutResponse } from "../../../../../../../../../services/types/process";
import ToggleButtonGroupField from "../../../../../../../../components/common/Form/ToggleButtonGroupField";
import { P } from "../../../../../../../../components/display/typography";
import ListBullet from "../../../../../../components/List/Bullet";
import ListLine from "../../../../../../components/List/Line";
const ActivityItem = ({ activity, id }: { activity: EditActivityWithoutResponse, id: number }) => {

    const { values: { activities } } = useFormikContext<EditActivitiesWithoutResponse>();
    const isDefault = activities[id].activity_alternatives[0].use_default;

    return <Box mb={2}>
        <ListItem
            sx={{
                borderRadius: "1rem",
                padding: "1rem .5rem",
                "& .MuiListItemAvatar-root": {
                    minWidth: 0,
                    marginRight: "1rem",
                },
            }}
        >
            <ListBullet size="normal" completed={true} text={(id + 1).toString()} />
            <Box display={"flex"} gap={1} alignItems={"center"}>
                <ToggleButtonGroupField
                    name={`activities.${id}.activity_alternatives.${0}.use_default`}
                    options={[
                        { value: true, label: 'Default' },
                        { value: false, label: 'Alternative' }
                    ]}
                    exclusive
                />
            </Box>
        </ListItem>
        <Box display={"flex"}>
            <ListLine sx={{ mr: 2 }} />
            <Grid container spacing={2}>
                <Grid item md={6}>
                    {isDefault ? <P>{activity.activity}</P> : (
                        <FastField name={`activities.${id}.activity_alternatives.${0}.activity`} key={`activity_alternative-${activity.id}-${id}`}>
                            {({ field, meta }: FieldProps<string>) => (
                                <>
                                    <TextField
                                        {...field}
                                        label="Activity Alternative"
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                    />
                                </>
                            )}
                        </FastField>
                    )}
                </Grid>
                <Grid item md={6}>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <FastField name={`activities.${id}.previous_capability_level_reference`}>
                            {({ field, meta }: FieldProps<number>) => (
                                <>
                                    <TextField
                                        {...field}
                                        value={field.value ? field.value : NaN}
                                        type="number"
                                        label="Previous Capability Level Reference"
                                        fullWidth
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                    />
                                </>
                            )}
                        </FastField>
                        <FastField name={`activities.${id}.target_capability_level_reference`}>
                            {({ field, meta }: FieldProps<number>) => (
                                <>
                                    <TextField
                                        {...field}
                                        value={field.value ? field.value : NaN}
                                        type="number"
                                        label="Target Capability Level Reference"
                                        fullWidth
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                    />
                                </>
                            )}
                        </FastField>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>
}

export default memo(ActivityItem)