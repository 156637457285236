import Box from "@mui/material/Box";
import { useContext } from "react";
import { ProjectContext } from "../../../../../../../context/ProjectContext";
import { H3 } from "../../../../../../components/display/typography";
import { ReviewDiscussionTabProps } from "../../../../components/props";
import ActivityList from "./lists/ActivityList";
const DiscussionTab = ({ data, criteria_id }: ReviewDiscussionTabProps) => {
	const { state: { selectedProject } } = useContext(ProjectContext);
	return (
		<>
			<H3>{data.description}</H3>
			{data.discussions.map((discussion, id) => (
				<Box key={`discussion-${id}`}>
					<ActivityList data={discussion} discussion_order={discussion.order} discussion={discussion.discussion} criteria_id={criteria_id} client_id={selectedProject} />
				</Box>
			))}
		</>
	);
};

export default DiscussionTab;
