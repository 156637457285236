import { createContext, ReactNode, useEffect, useReducer } from "react";
import { mockApi } from "../lib/axios/axios";
import { MockUserData } from "../lib/mock/types/users";
// TYPE
type UserState = {
	user: MockUserData | undefined;
	isLoading: boolean
};

type Action = { type: "SET_DATA"; payload: MockUserData } | { type: "SET_IS_LOADING"; payload: boolean };

// REDUCER
const initialState: UserState = {
	user: undefined,
	isLoading: true
};

function userReducer(state: UserState, action: Action): UserState {
	switch (action.type) {
		case "SET_DATA":
			return { ...state, user: action.payload };
		case "SET_IS_LOADING":
			return { ...state, isLoading: action.payload };
		default:
			return state;
	}
}

// CONTEXT
export const UserContext = createContext<{
	state: UserState;
	dispatch: React.Dispatch<Action>;
}>(null!);

export function UserProvider({ children }: { children: ReactNode }) {
	const [state, dispatch] = useReducer(userReducer, initialState);

	useEffect(() => {
		const fetchMockUserData = async () => {
			try {
				if (!state.user) {
					const response = await mockApi.get<MockUserData>("/api/users/cb8002d3-0519-4e1a-bff4-22d9b4ff00b0");
					dispatch({ type: "SET_DATA", payload: response.data });
					dispatch({ type: "SET_IS_LOADING", payload: false });
				}
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		};

		fetchMockUserData();
	}, [state.user]);

	return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
}