import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { BrandSubTitle, BrandTitle } from "./styled";
import Logo from "./sv-50x50.png";

const Brand = ({ sx }: { sx?: SxProps }) => {
	return (
		<Link to={"/"} style={{ textDecoration: "none" }}>
			<Box id="app-brand" display={"flex"} alignItems={"center"} sx={{py: "2rem", ...sx}}>
				<img
					id="app-brand-logo"
					src={Logo}
					alt="sharing-vision-logo-small"
					title="Sharing Vision Logo"
					width={"50px"}
					height={"50px"}
					style={{ marginRight: ".5rem" }}
				></img>
				<Box>
					<BrandTitle id="app-brand-title" sx={{ m: 0, mt: 1 }}>
						IT Maturity
					</BrandTitle>
					<BrandSubTitle id="app-brand-sub-title" variant="caption">
						Based on COBIT 5
					</BrandSubTitle>
				</Box>
			</Box>
		</Link>
	);
};

export default Brand;
