import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MouseEvent, SyntheticEvent, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ProjectContext } from "../../../../../../context/ProjectContext";
import { useEditAgreedCapabilitiesStatus } from "../../../../../../hooks/query/agreed-capabilities/useEditAgreedCapabilitiesStatus";
import { useFetchProcess } from "../../../../../../hooks/query/processes/useFetchProcess";
import { a11yProps, getDomainDescription } from "../../../../../../lib/utils/helper";
import { Paper } from "../../../../../components/display/paper/Paper";
import { H2, Small } from "../../../../../components/display/typography";
import TabPanel from "../../../../../components/navigations/tabs/TabPanel";
import { Tab, Tabs } from "../../../../../components/navigations/tabs/Tabs";
import { DomainProcessPageProps } from "../../../components/props";
import DomainAndProcessTab from "../tabs/DomainAndProcessTab";

type TabParams = "tanggapan" | "pertanyaan";
const paramsToTab = (tab_params: TabParams) => {
	switch (tab_params) {
		case "tanggapan":
			return 0;
		case "pertanyaan":
			return 1;
		default:
			return 0;
	}
}

const tabToParams = (tab_index: number) => {
	switch (tab_index) {
		case 0:
			return "tanggapan";
		case 1:
			return "pertanyaan";
		default:
			return undefined;
	}
}

const DomainProcessPage = ({ domain, processId }: DomainProcessPageProps) => {
	// CONTEXT
	const { state: { selectedProject } } = useContext(ProjectContext);
	// STATES
	const { data } = useFetchProcess(`${domain}${processId}`, selectedProject);
	const { mutate: editStatus } = useEditAgreedCapabilitiesStatus(`${domain}${processId}`, data.agreed_capabilities[0].id, selectedProject);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedTab, setSelectedTab] = useState(paramsToTab(searchParams.get("tab") as TabParams));
	const [status, setStatus] = useState<boolean>(data.agreed_capabilities[0].open);

	useEffect(() => {
		setStatus(data.agreed_capabilities[0].open)
	}, [data])

	// FUNCTIONS
	const handleTabChange = (event: SyntheticEvent, newValue: number) => {
		const params = tabToParams(newValue);
		if (params) {
			setSearchParams({ tab: params });
			setSelectedTab(newValue);
		}
	};

	const handleStatusChange = (event: MouseEvent<HTMLElement>, newSelection: boolean) => {
		setStatus(newSelection);
		editStatus({ open: newSelection });
	};

	// VARIABLES
	const tabLabel = `questionnaire-${domain}-${processId}-project-manager-tabpanel`;
	const tabAriaLabel = `questionnaire-${domain}-${processId}-project-manager-tab`;

	// Sub-Header Component
	const SubHeader = () => {
		const tabs = ["tanggapan", "pertanyaan"];
		return (
			<Paper sx={{ py: "1rem" }}>
				<Grid container spacing={2} alignItems={"center"}>
					<Grid item>
						<Box display={"flex"} alignItems="center">
							<Small style={{ marginRight: "1rem" }}>{`${domain} ${getDomainDescription(domain)}`}</Small>
							<H2 style={{ margin: 0 }}>{`${domain}${processId}`}</H2>
						</Box>
					</Grid>
					<Grid item md>
						<Tabs value={selectedTab} onChange={handleTabChange} aria-label="domain-and-process tabs">
							{tabs.map((tab, id) => (
								<Tab key={id} label={tab} {...a11yProps(id, `${tabLabel}-${id}`, `${tabAriaLabel}-${id}`)} />
							))}
						</Tabs>
					</Grid>
					<Grid item display={"flex"} alignItems={"center"}>
						{selectedTab === 0 ? (
							<Box display={"flex"} alignItems="center">
								<Small style={{ marginRight: "1rem" }}>Status</Small>
								<ToggleButtonGroup size="small" value={status} exclusive onChange={handleStatusChange} aria-label="Selection">
									<ToggleButton value={true} sx={{ textTransform: "none" }}>
										Open
									</ToggleButton>
									<ToggleButton value={false} sx={{ textTransform: "none" }}>
										Closed
									</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						) : (
							<Box display={"flex"} alignItems="center">
								<Button
									startIcon={<DatasetOutlinedIcon />}
									variant="contained"
									color="success"
									sx={{ textTransform: "none" }}
									{...({ LinkComponent: Link, to: `upload` })}
								>
									Upload CSV Template
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>
			</Paper>
		);
	};

	// Main Component
	return (
		<Grid container spacing={2}>
			{selectedProject && data ? (
				<>
					<Grid item md={12}>
						<SubHeader />
					</Grid>
					<Grid item md={12}>
						<TabPanel value={selectedTab} index={0} label={tabLabel} ariaLabel={tabAriaLabel}>
							<DomainAndProcessTab data={data} type="responses" />
						</TabPanel>
						<TabPanel value={selectedTab} index={1} label={tabLabel} ariaLabel={tabAriaLabel}>
							<DomainAndProcessTab data={data} type="questions" />
						</TabPanel>
					</Grid>
				</>
			) : (
				<Grid item md={12}>
					<Paper>
						Tidak ada project.
					</Paper>
				</Grid>
			)}
		</Grid>
	);
};

export default DomainProcessPage;
