import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { grey } from "@mui/material/colors";

export const ActionButton = styled(Button)(() => ({
	borderColor: grey[300],
    color: grey[900],
    borderRadius: "1rem",
    textTransform: "none",

    "&:hover": {
        backgroundColor: grey[300],
        borderColor: grey[300],
        color: grey[700],
    }
}));