import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButtonMUI from "@mui/material/ListItemButton";
import ListItemTextMUI from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import colors from "../../../../../lib/themes/colors";

export const ExpandLessIcon = styled(ExpandLess)(() => ({
	fontSize: "1rem",
	color: colors.secondary[3],
}));

export const ExpandMoreIcon = styled(ExpandMore)(() => ({
	fontSize: "1rem",
	color: colors.secondary[3],
}));

export const ListItemText = styled(ListItemTextMUI)(() => ({
	fontSize: "0.875rem",
	color: colors.secondary[3],
}));

export const ListItemButton = styled(ListItemButtonMUI)(({ theme }) => ({
	// Default styles
	backgroundColor: "transparent",
	color: "inherit",
	borderRadius: 10,
	marginTop: 3,
	marginBottom: 3,

	"& .MuiListItemIcon-root": {
		minWidth: 0, // Remove default minimum width
		marginRight: theme.spacing(2), // Adjust right margin as needed
	},

	"&.Mui-selected": {
		backgroundColor: theme.palette.sv.main, // Active background
		"& .MuiListItemText-root": {
			color: theme.palette.primary.contrastText,
			fontWeight: 600,
		},
		"& .MuiSvgIcon-root": {
			color: theme.palette.primary.contrastText,
		},
	},

	"&.Mui-selected:hover": {
		backgroundColor: theme.palette.sv.dark, // Darker active background on hover
		"& .MuiListItemText-root": {
			color: theme.palette.primary.contrastText,
		},
		"& .MuiSvgIcon-root": {
			color: theme.palette.primary.contrastText,
		},
	},
}));
