import api from "../lib/axios/axios";
import { Response } from "../types/response";
import { ProcessMaturity as ProcessMaturityResponse } from "./types/process-maturity";
export const fetchProcessMaturities = async (): Promise<ProcessMaturityResponse[]> => {
	const res = await api.get<Response<ProcessMaturityResponse[]>>(`/process-maturity`);
	return res.data.data;
};

export const fetchProcessMaturity = async (id: string): Promise<ProcessMaturityResponse> => {
	const res = await api.get<Response<ProcessMaturityResponse>>(`/process-maturity/${id}`);
	return res.data.data;
};

export const editProcessMaturity = async (id: string, data: ProcessMaturityResponse): Promise<ProcessMaturityResponse> => {
	const res = await api.put<Response<ProcessMaturityResponse>>(`/process-maturity/${id}`, data);
	return res.data.data;
};
