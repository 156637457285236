import { array, date, number, object, Schema, string, boolean } from "yup";
import { CreateClient, EditClient } from "../../services/types/client";


const generateCreateInitialValues = ():CreateClient => {
	const initialClientId = crypto.randomUUID();
	const initialProcessGroupId1 = crypto.randomUUID();
	const initialProcessGroupId2 = crypto.randomUUID();
	const initialProcessGroupId3 = crypto.randomUUID();
	const createInitialValues: CreateClient = {
		id: initialClientId,
		client: "",
		description: "",
		project_start_date: new Date(),
		project_end_date: new Date(),
		person_in_charges: [
			{
				id: crypto.randomUUID(),
				client_id: initialClientId,
				name: "",
				description: "",
				contact: "",
			},
		],
		process_groups: [
			{
				id: initialProcessGroupId1,
				client_id: initialClientId,
				order: 0,
				group: "Design Factor",
				note: "-",
			},
			{
				id: initialProcessGroupId2,
				client_id: initialClientId,
				order: 1,
				group: "Berdasarkan Regulasi Terkait",
				note: "-",
			},
			{
				id: initialProcessGroupId3,
				client_id: initialClientId,
				order: 2,
				group: "Berdasarkan Permen BUMN",
				note: "-",
			},
		],
		agreed_capabilities: [
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "777fe42e-3e09-4c18-b1d6-b0bda8f248ae", target: 3, previous_capability: null, process: "edm1", domain: "edm", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "102e29ca-59ec-4b5c-945a-9923f14049ad", target: 3, previous_capability: null, process: "edm2", domain: "edm", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "5453b972-07fd-47ce-aede-ec5da722fcd5", target: 3, previous_capability: null, process: "edm3", domain: "edm", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "4f16cab0-b658-4557-a9f9-f3d0c881d1f4", target: 3, previous_capability: null, process: "edm4", domain: "edm", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "86c4bf41-8fe3-4213-8f03-01fae48cfaf9", target: 3, previous_capability: null, process: "edm5", domain: "edm", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "7e7c4a5e-fd46-4e92-9af6-1bab5017f445", target: 3, previous_capability: null, process: "apo1", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "bce08c4b-51b0-40df-9e69-60ed0fb2f9e8", target: 3, previous_capability: null, process: "apo2", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "8bc02613-ca56-41ea-87b4-489e2ef07586", target: 3, previous_capability: null, process: "apo3", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "9b62247f-2511-4bb1-ac68-7a4898486192", target: 3, previous_capability: null, process: "apo4", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "c0fd7ec1-91fd-4d55-b6a6-b2061c0cd5cb", target: 3, previous_capability: null, process: "apo5", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "ddb7f550-d53b-4b76-a725-c62648a2f68f", target: 3, previous_capability: null, process: "apo6", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "c9861efb-68ab-4155-8fce-3aa9ee979547", target: 3, previous_capability: null, process: "apo7", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "e706d478-521d-4edb-9077-75e29af712aa", target: 3, previous_capability: null, process: "apo8", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "8ae06de8-6c99-45a4-aac6-f8d44e9995a9", target: 3, previous_capability: null, process: "apo9", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "392b79bf-25f6-46fa-8326-877e3c2ff5da", target: 3, previous_capability: null, process: "apo10", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "a37b8c14-6699-481a-a004-fbe8cc08c4d9", target: 3, previous_capability: null, process: "apo11", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "ee0bd239-5ac6-4d9c-8c56-c24752dd0767", target: 3, previous_capability: null, process: "apo12", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "ab7e0970-8f7c-43c5-8b10-3708d409d7a4", target: 3, previous_capability: null, process: "apo13", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "eb09cbb0-1797-47a3-a7bd-8a0bf0d7b8f5", target: 3, previous_capability: null, process: "apo14", domain: "apo", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "bb8fbc2f-c514-43f0-963e-6b19a8745c36", target: 3, previous_capability: null, process: "bai1", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "9d7c1e1d-9cc7-40d4-9e6e-33b5bf884377", target: 3, previous_capability: null, process: "bai2", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "3fa3e59c-64d5-4e9d-984b-0066bee9ac9a", target: 3, previous_capability: null, process: "bai3", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "2f32bc6e-6d45-4456-ad82-8d5bbe852486", target: 3, previous_capability: null, process: "bai4", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "74e658e9-3429-487a-b540-e454a6ab39ec", target: 3, previous_capability: null, process: "bai5", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "ad3753c7-31eb-4df0-b19f-b71ff46eed15", target: 3, previous_capability: null, process: "bai6", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "38e47258-ce10-4bdd-b3e1-5c334a9e18ff", target: 3, previous_capability: null, process: "bai7", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "d404b724-724a-4480-ac67-44be0a8f5550", target: 3, previous_capability: null, process: "bai8", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "65b0104c-fece-4f08-bd20-e2ccbea4a237", target: 3, previous_capability: null, process: "bai9", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "140b57af-5b50-4eaa-938f-471124774924", target: 3, previous_capability: null, process: "bai10", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "2a390795-3d24-4626-8eb5-16c3fcd18ab4", target: 3, previous_capability: null, process: "bai11", domain: "bai", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "436b1d59-1835-4b5a-92f8-071c9edb5d23", target: 3, previous_capability: null, process: "dss1", domain: "dss", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "5dc87238-221a-4fa1-a554-14f478cd2ebf", target: 3, previous_capability: null, process: "dss2", domain: "dss", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "47f6dd19-d955-4df4-8afe-984d98c700ae", target: 3, previous_capability: null, process: "dss3", domain: "dss", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "4cd58b6f-bc16-4d84-88eb-8e3447f18383", target: 3, previous_capability: null, process: "dss4", domain: "dss", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "772ca3c0-8cce-47b1-89b4-0eae7b7bb49d", target: 3, previous_capability: null, process: "dss5", domain: "dss", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "0676ddb8-0b8f-49da-ad6c-3a1adc75bb2a", target: 3, previous_capability: null, process: "dss6", domain: "dss", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "6a7ce6d3-a967-4b7c-954c-1ec1311e412b", target: 3, previous_capability: null, process: "mea1", domain: "mea", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "f2ba8255-f4db-4654-ad4a-d880424510f6", target: 3, previous_capability: null, process: "mea2", domain: "mea", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "8f4f8f00-377f-4ce5-97aa-35202849d5e2", target: 3, previous_capability: null, process: "mea3", domain: "mea", process_group_id: initialProcessGroupId1 },
			{ id: crypto.randomUUID(), client_id: initialClientId, open:true, process_id: "01a06fe8-6f1e-4cd6-b31d-ce66e0d51d0b", target: 3, previous_capability: null, process: "mea4", domain: "mea", process_group_id: initialProcessGroupId1 },
		],
	};

	return createInitialValues;
}

const generateEditInitialValues = ():EditClient => {
	const editInitialValues: CreateClient = {
		id: "",
		client: "",
		description: "",
		project_start_date: new Date(),
		project_end_date: new Date(),
		person_in_charges: [],
		process_groups: [],
		agreed_capabilities: [],
	};

	return editInitialValues;
}

const createValidationSchema: Schema<CreateClient> = object().shape({
	id: string().required(),
	client: string().required("Client harus diisi"),
	description: string().required("Deskripsi harus diisi"),
	project_start_date: date().required("Tanggal mulai harus diisi"),
	project_end_date: date().required("Tanggal selesai harus diisi"),
	person_in_charges: array()
		.of(
			object().shape({
				id: string().required(),
				client_id: string().required(),
				name: string().required("Nama harus diisi"),
				description: string().required("Deskripsi harus diisi"),
				contact: string().required("Kontak harus diisi"),
			})
		)
		.min(1)
		.required(),
	process_groups: array()
		.of(
			object().shape({
				id: string().required(),
				client_id: string().required(),
				order: number().required(),
				group: string().required("Grup harus diisi"),
				note: string().required("Catatan harus diisi"),
			})
		)
		.min(1)
		.required(),
	agreed_capabilities: array()
		.of(
			object().shape({
				id: string().required(),
				client_id: string().required(),
				process_id: string().required("ID harus diisi"),
				target: number().min(1, "Minimal 1").max(5, "Maksimal 5").required("Target harus diisi"),
				previous_capability: number().min(1, "Minimal 1").max(5, "Maksimal 5").nullable().default(null).optional(),
				process: string().required(),
				domain: string().required(),
				open: boolean().required(),
				process_group_id: string()
				.required("Group harus dipilih")
				.test(
					'is-valid-process-group-id',
					'Group harus dipilih dari daftar Process Group',
					(value, context) => {
						const { process_groups } = context.options.context as CreateClient;
						const processGroupIds = process_groups.map(group => group.id);
						return processGroupIds.includes(value);
					}
				),
			})
		)
		.required(),
});

const editValidationSchema: Schema<EditClient> = object().shape({
	id: string().required(),
	client: string().required("Client harus diisi"),
	description: string().required("Deskripsi harus diisi"),
	project_start_date: date().required("Tanggal mulai harus diisi"),
	project_end_date: date().required("Tanggal selesai harus diisi"),
	person_in_charges: array()
		.of(
			object().shape({
				id: string().required(),
				client_id: string().required(),
				name: string().required("Nama harus diisi"),
				description: string().required("Deskripsi harus diisi"),
				contact: string().required("Kontak harus diisi"),
			})
		)
		.min(1)
		.required(),
	process_groups: array()
		.of(
			object().shape({
				id: string().required(),
				client_id: string().required(),
				order: number().required(),
				group: string().required("Grup harus diisi"),
				note: string().required("Catatan harus diisi"),
			})
		)
		.min(1)
		.required(),
	agreed_capabilities: array()
		.of(
			object().shape({
				id: string().required(),
				client_id: string().required(),
				process_id: string().required("ID harus diisi"),
				target: number().min(1, "Minimal 1").max(5, "Maksimal 5").required("Target harus diisi"),
				previous_capability: number().min(1, "Minimal 1").max(5, "Maksimal 5").nullable().default(null).optional(),
				process: string().required(),
				domain: string().required(),
				open: boolean().required(),
				process_group_id: string()
				.required("Group harus dipilih")
				.test(
					'is-valid-process-group-id',
					'Group harus dipilih dari daftar Process Group',
					(value, context) => {
						const { process_groups } = context.options.context as CreateClient;
						const processGroupIds = process_groups.map(group => group.id);
						return processGroupIds.includes(value);
					}
				),
			})
		)
		.required(),
});

export const isKeyOfCreateClient = (key: string, obj: CreateClient | undefined): key is keyof CreateClient => {
	if (!obj) return false;
	return key in obj;
};

export const isKeyOfEditClient = (key: string, obj: EditClient | undefined): key is keyof EditClient => {
	if (!obj) return false;
	return key in obj;
};

const clientFormik = {
	create: {
		initialValues: generateCreateInitialValues,
		validationSchema: createValidationSchema,
		isKeyOf: isKeyOfCreateClient,
	},
	edit: {
		initialValues: generateEditInitialValues,
		validationSchema: editValidationSchema,
		isKeyOf: isKeyOfEditClient,
	},
};

export default clientFormik;
