import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { Paper } from "../../../components/display/paper/Paper";
const Loading = () => {
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Paper sx={{ py: "1rem" }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item>
                            <Skeleton variant="rounded" sx={{ height: 33, width: 120 }} />
                        </Grid>
                        <Grid item md></Grid>
                        <Grid item display={"flex"} alignItems={"center"}>
                            <Skeleton variant="rounded" sx={{ height: 37, width: 152 }} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={12}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Paper sx={{ height: 600 }}>
                            <Box sx={{ minHeight: "120px" }}>
                                <Skeleton variant="rounded" height={72} width={"100%"} />
                                <Box display={"flex"} gap={4} mt={2}>
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ width: 100 }} />
                                </Box>
                                <Box display={"flex"} gap={4} mt={2}>
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ width: 100 }} />
                                </Box>
                                <Box display={"flex"} gap={4} mt={2}>
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ flex: 1 }} />
                                    <Skeleton variant="rounded" height={64} sx={{ width: 100 }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Loading;