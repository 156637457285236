import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
const UserLoading = () => {
	return (
		<Box display={"flex"} sx={{ my: "1.875rem" }} alignItems={"center"}>
			<Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
			<Box mt={0.5}>
				<Skeleton variant="text" sx={{ fontSize: "1rem", lineHeight: 0.9, width: "10ch" }} />
				<Skeleton variant="text" sx={{ fontSize: "0.75rem", lineHeight: 0.9, width: "10ch" }} />
			</Box>
		</Box>
	);
};

export default UserLoading;
