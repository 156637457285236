import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useFetchClients } from "../../../../../hooks/query/clients/useFetchClients";
import DataTable from "../../components/datatable";
import SubHeader from "../../components/subheader";
const Client = () => {
	// STATES
	const { data } = useFetchClients();
	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<SubHeader title="Client" >
					<Grid item display={"flex"} alignItems={"center"}>
						<Button startIcon={<AddBoxOutlinedIcon />} variant="contained" sx={{ textTransform: "none" }} onClick={() => navigate('/clients/create')}>
							Tambah Client
						</Button>
					</Grid>
				</SubHeader>
			</Grid>
			<Grid item md={12}>
				<DataTable data={data} />
			</Grid>
		</Grid>
	);
};

export default Client;
