import { object, Schema, string } from "yup";
export type AuthFormikValues = {
	email: string;
	password: string;
};

const initialValues: AuthFormikValues = {
	email: "",
	password: "",
};

const validationSchema: Schema<AuthFormikValues> = object().shape({
	email: string().email("Email tidak valid").required("Email harus diisi"),
	password: string().min(8, "Password minimal 8 karakter").required("Password harus diisi"),
});

const isKeyOfAuthFormikValues = (key: string, obj: AuthFormikValues): key is keyof AuthFormikValues => {
	if (!obj) return false;
	return key in obj;
};

const authFormik = { initialValues, validationSchema, isKeyOfAuthFormikValues };
export default authFormik;
