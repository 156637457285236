import { Suspense } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useLocation } from "react-router-dom";
import Unauthenticated from "../../components/common/Redirect/Unauthenticated";
import Header from "../../components/navigations/header/Header";
import Loading from "./loading";
import DashboardPage from "./route-element/base-dashboard";
const Dashboard = () => {
    const authUser = useAuthUser();
    const location = useLocation();
    if (!authUser) return <Unauthenticated />;
    const Page = () => {
        if (authUser.role === "assessor") return <DashboardPage />;
        if (authUser.role === "pm") return <DashboardPage />;
        if (authUser.role === "admin") return <DashboardPage />;
        else return <>Forbidden</>;
    }

    return <>
        <Header
            title="Dashboard"
            breadcrumbs={[
                { text: "Dashboard", path: `${location.pathname}` },
            ]}
        />
        <Suspense fallback={<Loading />}>
            <Page />
        </Suspense>
    </>;
};

export default Dashboard;
