import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DashboardIcon from "@mui/icons-material/Dashboard";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QuizIcon from "@mui/icons-material/Quiz";
import ScoreboardOutlinedIcon from '@mui/icons-material/ScoreboardOutlined';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactElement } from "react";
import colors from "../themes/colors";

export type MenuItem = {
	title: string;
	path: string;
	icon: ReactElement;
	hovertext?: string;
	roles?: string[];
	child?: MenuItem[];
};

interface StyledIconProps {
	icon: OverridableComponent<any>;
}

const StyledIcon: React.FC<StyledIconProps> = ({ icon: IconComponent }): ReactElement => <IconComponent sx={{ fontSize: "0.9375rem", color: colors.secondary[3] }} />;

const menu: MenuItem[] = [
	{
		title: "Dashboard",
		path: "/dashboard",
		roles: ["pm", "admin", "assessor"],
		icon: <StyledIcon icon={DashboardIcon} />,
	},
	{
		title: "Penilaian",
		path: "/penilaian",
		roles: ["pm", "admin", "assessor"],
		icon: <StyledIcon icon={ScoreboardOutlinedIcon} />,
	},
	{
		title: "Kuisioner",
		path: "/kuisioner",
		roles: ["pm", "admin", "assessor"],
		icon: <StyledIcon icon={QuizIcon} />,
		child: [
			{
				title: "EDM",
				path: "/edm",
				icon: <StyledIcon icon={LibraryBooksIcon} />,
				hovertext: "Evaluate, Direct and Monitor",
				child: [
					{
						title: "EDM01",
						path: "/kuisioner/edm/1",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Ensured Governance Framework Setting and Maintenance",
					},
					{
						title: "EDM02",
						path: "/kuisioner/edm/2",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Ensured Benefits Delivery",
					},
					{
						title: "EDM03",
						path: "/kuisioner/edm/3",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Ensured Risk Optimisation",
					},
					{
						title: "EDM04",
						path: "/kuisioner/edm/4",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Ensured Resource Optimisation",
					},
					{
						title: "EDM05",
						path: "/kuisioner/edm/5",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Ensured Stakeholder Engagement",
					},
				],
			},
			{
				title: "APO",
				path: "/apo",
				icon: <StyledIcon icon={LibraryBooksIcon} />,
				hovertext: "Align, Plan and Organise",
				child: [
					{
						title: "APO01",
						path: "/kuisioner/apo/1",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed IT management framework",
					},
					{
						title: "APO02",
						path: "/kuisioner/apo/2",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Strategy",
					},
					{
						title: "APO03",
						path: "/kuisioner/apo/3",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Enterprise Architecture",
					},
					{
						title: "APO04",
						path: "/kuisioner/apo/4",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Innovation",
					},
					{
						title: "APO05",
						path: "/kuisioner/apo/5",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Portfolio",
					},
					{
						title: "APO06",
						path: "/kuisioner/apo/6",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Budget and Costs",
					},
					{
						title: "APO07",
						path: "/kuisioner/apo/7",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Human Resources",
					},
					{
						title: "APO08",
						path: "/kuisioner/apo/8",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Relationships",
					},
					{
						title: "APO09",
						path: "/kuisioner/apo/9",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Service Agreements",
					},
					{
						title: "APO10",
						path: "/kuisioner/apo/10",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Vendors",
					},
					{
						title: "APO11",
						path: "/kuisioner/apo/11",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Quality",
					},
					{
						title: "APO12",
						path: "/kuisioner/apo/12",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Risk",
					},
					{
						title: "APO13",
						path: "/kuisioner/apo/13",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Security",
					},
					{
						title: "APO14",
						path: "/kuisioner/apo/14",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Data",
					},
				],
			},
			{
				title: "BAI",
				path: "/bai",
				icon: <StyledIcon icon={LibraryBooksIcon} />,
				hovertext: "Build, Acquire and Implement",
				child: [
					{
						title: "BAI01",
						path: "/kuisioner/bai/1",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Programs",
					},
					{
						title: "BAI02",
						path: "/kuisioner/bai/2",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Requirements Definition",
					},
					{
						title: "BAI03",
						path: "/kuisioner/bai/3",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Solutions Identification and Build",
					},
					{
						title: "BAI04",
						path: "/kuisioner/bai/4",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Availability and Capacity",
					},
					{
						title: "BAI05",
						path: "/kuisioner/bai/5",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Organisational Change",
					},
					{
						title: "BAI06",
						path: "/kuisioner/bai/6",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed IT Changes",
					},
					{
						title: "BAI07",
						path: "/kuisioner/bai/7",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Change Acceptance and Transitioning",
					},
					{
						title: "BAI08",
						path: "/kuisioner/bai/8",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Knowledge",
					},
					{
						title: "BAI09",
						path: "/kuisioner/bai/9",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Assets",
					},
					{
						title: "BAI10",
						path: "/kuisioner/bai/10",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Configuration",
					},
					{
						title: "BAI11",
						path: "/kuisioner/bai/11",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Projects",
					},
				],
			},
			{
				title: "DSS",
				path: "/dss",
				icon: <StyledIcon icon={LibraryBooksIcon} />,
				hovertext: "Deliver, Service and Support",
				child: [
					{
						title: "DSS01",
						path: "/kuisioner/dss/1",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Operations",
					},
					{
						title: "DSS02",
						path: "/kuisioner/dss/2",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Service Requests and Incidents",
					},
					{
						title: "DSS03",
						path: "/kuisioner/dss/3",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Problems",
					},
					{
						title: "DSS04",
						path: "/kuisioner/dss/4",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Continuity",
					},
					{
						title: "DSS05",
						path: "/kuisioner/dss/5",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Security Services",
					},
					{
						title: "DSS06",
						path: "/kuisioner/dss/6",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Business Process Controls",
					},
				],
			},
			{
				title: "MEA",
				path: "/mea",
				icon: <StyledIcon icon={LibraryBooksIcon} />,
				hovertext: "Monitor, Evaluate and Assess",
				child: [
					{
						title: "MEA01",
						path: "/kuisioner/mea/1",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Performance and Conformance Monitoring",
					},
					{
						title: "MEA02",
						path: "/kuisioner/mea/2",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed System of Internal Control",
					},
					{
						title: "MEA03",
						path: "/kuisioner/mea/3",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Compliance With External Requirements",
					},
					{
						title: "MEA04",
						path: "/kuisioner/mea/4",
						icon: <StyledIcon icon={NoteAltIcon} />,
						hovertext: "Managed Assurance",
					},
				],
			},
		],
	},
	{
		title: "Users",
		path: "/users",
		roles: ["admin"],
		icon: <StyledIcon icon={PersonOutlineOutlinedIcon} />,
		hovertext: "",
	},
	{
		title: "Clients",
		path: "/clients",
		roles: ["admin"],
		icon: <StyledIcon icon={BusinessOutlinedIcon} />,
		hovertext: "",
	},
];

export default menu;
