import Box from "@mui/material/Box";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
const DashboardToolbar = () => {
	return (
		<GridToolbarContainer sx={{ mb: 2 }}>
			<GridToolbarQuickFilter variant="outlined" size="small" />
			<Box sx={{ flexGrow: 1 }} />
		</GridToolbarContainer>
	);
};

export default DashboardToolbar;
