import { MockQuestionnaireData, MockProcessId } from "../types/questionnaires";
import edmData from './dummy/edm.json';
import apoData from './dummy/apo.json';
import baiData from './dummy/bai.json';
import dssData from './dummy/dss.json';
import meaData from './dummy/mea.json';

export const edm:MockQuestionnaireData = JSON.parse(JSON.stringify(edmData))
export const apo:MockQuestionnaireData = JSON.parse(JSON.stringify(apoData))
export const bai:MockQuestionnaireData = JSON.parse(JSON.stringify(baiData))
export const dss:MockQuestionnaireData = JSON.parse(JSON.stringify(dssData))
export const mea:MockQuestionnaireData = JSON.parse(JSON.stringify(meaData))

export const getQuestionnaireByMockProcessId = (data: MockQuestionnaireData,processId: string): MockProcessId | undefined => {
	const byMockProcessId = data.process_ids.find((p) => p.id === processId)
	if (byMockProcessId) return byMockProcessId;
	return undefined;
};
