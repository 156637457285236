import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Card from "../../cards";
type SummaryCardListProps = {
	maturitySummary: number;
	summary: {
		maturity: number;
		agreed: number;
		subject: string;
	}[];
};
const SummaryCardList = ({ maturitySummary, summary }: SummaryCardListProps) => {
	return summary.length > 0 ? (
		<>
			<Grow in={Boolean(maturitySummary)}>
				<Grid item md={2}>
					<Card type="summary" text={"Summary"} value={maturitySummary} elevation={0} />
				</Grid>
			</Grow>
			{summary.map((sum, id) => {
				if(sum.maturity){
					return (
						<Grow in={Boolean(maturitySummary)} key={id}>
							<Grid item md={2}>
								<Card type="data" text={sum.subject} value={sum.maturity} targetValue={sum.agreed} elevation={0} />
							</Grid>
						</Grow>
					)
				}else{
					return (
						<Grow in={Boolean(maturitySummary)} key={id}>
							<Grid item md={2}>
								<Card type="data" text={sum.subject} value={NaN} targetValue={-1} elevation={0} />
							</Grid>
						</Grow>
					)
				}
			})}
		</>
	):(
		<Grid item md={12}>
		<Card type="summary" text={"Data tidak cukup"} value={NaN} elevation={0} />
	</Grid>
	);
};

export default SummaryCardList;
