import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Suspense, useState } from "react";
import { useFetchUsers } from "../../../../../hooks/query/users/useFetchUsers";
import { UserResponse as User } from "../../../../../services/types/user";
import UserDataTable from "../../components/datatable";
import SubHeader from "../../components/subheader";
import CreateUser from "./create";
import EditUser from "./edit";
const UserPage = () => {
	// STATES
	const [openEdit, setOpenEdit] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);
	const [selected, setSelected] = useState<User | undefined>(undefined);
	const { data } = useFetchUsers();

	// FUNCTIONS
	const toggleEdit = (newOpen: boolean) => () => {
		setOpenEdit(newOpen);
	};

	const toggleAdd = (newOpen: boolean) => () => {
		setOpenAdd(newOpen);
	};

	const onEdit = (id: string) => {
		const user = data.find((d) => d.id === id);
		setSelected(user);
		setTimeout(() => {
			setOpenEdit(true);
		}, 100);
	};

	return (
		<Suspense fallback={<>Loading user data...</>}>
			<Grid container spacing={2}>
				<Grid item md={12}>
					<SubHeader title="User">
						<Button startIcon={<AddBoxOutlinedIcon />} variant="contained" sx={{ textTransform: "none" }} onClick={toggleAdd(true)}>
							Tambah User
						</Button>
					</SubHeader>
				</Grid>
				<Grid item md={12}>
					<UserDataTable data={data} onEdit={onEdit} />
				</Grid>
			</Grid>
			<CreateUser open={openAdd} toggle={toggleAdd} />
			<EditUser open={openEdit} toggle={toggleEdit} selected={selected} />
		</Suspense>
	);
};

export default UserPage;
